//@flow
import * as React from 'react';
import { IconButton } from '@material-ui/core';
import { type Column } from 'react-table';
import DeleteIcon from '@material-ui/icons/Delete';

import { TextFilter } from 'components/core/table/TableFilters';
import { BodyModal } from './BodyModal';
import { type Message } from './Messages.type';

type onDeleteClickFn = (messageId: string) => void;
export const allQueueMessagesColumns: (onDeleteClickFn) => Array<
    Column<Message>,
> = (onDeleteClick) => [
    {
        Header: 'Delete',
        id: 'delete',
        Cell: ({ row }) => (
            <IconButton
                title="Delete message"
                onClick={() => onDeleteClick(row.values.messageId)}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        ),
    },
    {
        Header: 'Message Id',
        accessor: 'messageId',
        filter: 'fuzzyText',
        Filter: TextFilter,
    },
    {
        Header: 'Message Body',
        filter: 'fuzzyText',
        Filter: TextFilter,
        Cell: ({ row: { original } }) => <BodyModal original={original} />,
    },
];
