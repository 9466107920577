//@flow

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { ToastReducer } from 'containers/toast/duck';

const reducers: () => any = (history) =>
    combineReducers({
        router: connectRouter(history),
        toast: ToastReducer,
    });

export default reducers;
