// @flow
import { useMutation, type MutationResult } from 'react-query';
import { queryClient } from 'query';

import { apiInstance } from 'middleware/axios';

type Variables = {
    clientId: string,
    reason: string,
};

type Result = Array<string>;

export default function useUpdateClientExpiry(): MutationResult<
    Variables,
    Result,
> {
    return useMutation<Variables, Result>(
        ({ clientId, reason }) => {
            return apiInstance.post(`/client/${clientId}/renew-expiry`, {
                reason,
            });
        },
        {
            onSuccess: (_, { clientId }) => {
                queryClient.invalidateQueries(['clients', clientId]);
            },
        },
    );
}
