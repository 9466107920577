//@flow
import * as React from 'react';
import { Box } from '@material-ui/core';

import Select from 'components/core/select';
import { ApiButton } from 'components/core/button';
import { Body1 } from 'components/core/typography';
import { useClientId } from 'hooks/client';
import useUpdateClientProvider from './useUpdateClientProvider';
import { type Provider } from 'models/apps/client';

type Props = {
    providers: Array<Provider>,
};

const LinkProviderForm = (props: Props): React.Node => {
    const { providers } = props;
    const clientId = useClientId();
    const useUpdateClientProviderMutation = useUpdateClientProvider();
    const providerOptions = React.useMemo(
        () =>
            providers.map((provider) => ({
                id: provider.id,
                label: provider.name,
                value: provider.id,
            })),
        [providers],
    );
    const [{ id: firstProviderId }] = providers;
    const [value, onChange] = React.useState(firstProviderId);
    const triggerClick = () => {
        useUpdateClientProviderMutation.mutate({
            clientId,
            providerId: value,
            enabled: true, //enable provider along with linking it
            reason: 'Linking provider from internal dashboard v2',
        });
    };
    return (
        <Box display="flex" flexDirection="column" width="100%" py={1}>
            <Body1> Provider Name </Body1>
            <Select items={providerOptions} value={value} onChange={onChange} />
            <ApiButton
                apiStatus={useUpdateClientProviderMutation.status}
                successMessage={'Provider linked to the client successfully'}
                errorMessage={useUpdateClientProviderMutation.error?.message}
                onClick={triggerClick}
            />
        </Box>
    );
};

export default LinkProviderForm;
