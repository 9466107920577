// @flow
import { useMutation, type MutationResult } from 'react-query';
import { queryClient } from 'query';

import { apiInstance } from 'middleware/axios';
import { type StatusTypeEnumType } from 'models/apps/client/Client';

type Variables = {
    clientId: string,
    status: StatusTypeEnumType,
    reason: string,
};

type Result = Array<string>;

export default function useUpdateClientStatus(): MutationResult<
    Variables,
    Result,
> {
    return useMutation<Variables, Result>(
        ({ clientId, status, reason }) => {
            return apiInstance.post(`/client/${clientId}/status`, {
                status,
                reason,
            });
        },
        {
            onSuccess: (_, { clientId }) => {
                queryClient.invalidateQueries(['clients', clientId]);
            },
        },
    );
}
