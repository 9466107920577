// @flow
import EnvConfig from 'env-config';

const getConfig = (): any => {
    if (EnvConfig.env !== 'devo') {
        return EnvConfig;
    }

    const hostname = window.location.host;
    // Basically here we are changing the EndPoint to correct devo based on subdomain
    // example: for devo-3.locus-dev.com, replacing devo with devo-3 in api endpoint
    // Assumption here: Only dashboardEnpoint config is different for each devo rest are same
    const [subdomain] = hostname.split('.');
    const devo: string = /^devo(-\d+|-coverage)?$/.test(subdomain)
        ? subdomain
        : EnvConfig.devo;

    // NOTE: in devo.json endPoint is template with {devo} as placeholder
    const dashboardEndPoint = EnvConfig.dashboardEndPoint.replace(
        '{devo}',
        devo,
    );
    const internalApiEndPoint = EnvConfig.internalApiEndPoint.replace(
        '{devo}',
        devo,
    );

    return {
        ...EnvConfig,
        devo,
        dashboardEndPoint,
        internalApiEndPoint,
    };
};

export default (getConfig(): typeof EnvConfig);
