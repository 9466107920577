// @flow
type ImageDimensionType = {
    height: number,
    width: number,
};

export const BrandingImageSize: { [key: string]: ImageDimensionType } = {
    logo: {
        height: 80,
        width: 160,
    },
    favicon: {
        height: 80,
        width: 160,
    },
    vehicleIcon: {
        height: 60,
        width: 60,
    },
};
