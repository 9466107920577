//@flow

import * as React from 'react';
import { ApiStatusEnum } from 'enums/API';
import { useMergedTeamSettings } from 'hooks/team';
import ErrorContainer from 'containers/ErrorContainer';
import Loader from 'components/core/loader';
import { VaeConfigBase } from './VaeConfigBase';

type Props = {
    teamId: string,
    clientId: string,
};
export const Form = (props: Props): React.Node => {
    const { teamId, clientId } = props;
    const mergeTeamSettingsQuery = useMergedTeamSettings({
        clientId,
        teamId,
        mergeAttributeList: ['vaeConfiguration'],
    });

    if (mergeTeamSettingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <>
            {mergeTeamSettingsQuery.status === ApiStatusEnum.Error ? (
                <ErrorContainer
                    message={mergeTeamSettingsQuery.error.message}
                />
            ) : (
                <VaeConfigBase
                    clientId={clientId}
                    teamId={teamId}
                    configuration={mergeTeamSettingsQuery.data}
                />
            )}
        </>
    );
};
