// @flow
import { useQuery, type QueryResult } from 'react-query';

import { instance } from 'middleware/axios';

import { type SessionType } from 'models/Session';

type Params = { enabled: boolean };

export default function useSession({
    enabled,
}: Params): QueryResult<SessionType> {
    return useQuery({
        queryKey: 'me',
        queryFn: () => {
            return instance.get(`/session/me`).then((response) => {
                return response.data;
            });
        },
        enabled,
    });
}
