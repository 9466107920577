//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/core/button';
import { type BusinessUnitType } from 'models/apps/businessUnit';
import BUSelect from './BUSelect';
import BUSettingsForm from './BUSettingsForm';
import invariant from 'utils/invariant';

type Props = {
    businessUnits: Array<BusinessUnitType>,
    clientId: string,
};

const useStyles = makeStyles({
    buSelectorForm: {
        gap: '16px',
    },
});

const BUSelectorForm = (props: Props): React.Node => {
    const { clientId, businessUnits } = props;

    invariant(
        businessUnits.length > 0,
        'Expected atleast one businessUnit to be present here',
    );

    const [{ businessUnitId: initialValue }] = businessUnits;
    const classes = useStyles();
    const [value, onChange] = React.useState(initialValue);
    const [showSettingsForm, setShowSettingsForm] = React.useState(false);

    const onBUChange = (updatedBuId) => {
        setShowSettingsForm(false);
        onChange(updatedBuId);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            className={classes.buSelectorForm}
        >
            <BUSelect
                businessUnits={businessUnits}
                value={value}
                onChange={onBUChange}
            />
            <Box width="100%" textAlign="center">
                <Button
                    type="submit"
                    size="small"
                    color="primary"
                    onClick={() => setShowSettingsForm(true)}
                >
                    SUBMIT
                </Button>
            </Box>
            {showSettingsForm && (
                <BUSettingsForm businessUnitId={value} clientId={clientId} />
            )}
        </Box>
    );
};

export default BUSelectorForm;
