// @flow
import { useMutation, type MutationResult } from 'react-query';

import { platformInstance } from 'middleware/axios';
import { type Provider } from 'models/apps/client';

type Variables = { provider: Provider, overwrite?: boolean };

export default function useAddProvider(): MutationResult<Variables, null> {
    return useMutation<Variables, null>(({ provider, overwrite = true }) => {
        const overwriteStr = overwrite.toString();
        return platformInstance.put(
            `provider/${provider.id}?overwrite=${overwriteStr}`,
            provider,
        );
    });
}
