//@flow

import * as React from 'react';
import HelpIcon from '@material-ui/icons/Help';

import {
    default as Tooltip,
    type TooltipPlacement,
} from 'components/core/tooltip';

type Props = {
    title: string,
    open?: boolean,
    placement?: TooltipPlacement,
    fontSize?: 'inherit' | 'default' | 'small' | 'large',
    customFontSize?: string,
};

const Infotip = (props: Props): React.Node => {
    const {
        open,
        title,
        fontSize = 'small',
        customFontSize,
        placement = 'right',
    } = props;
    return (
        <Tooltip title={title} placement={placement} open={open} arrow>
            <HelpIcon
                // Apply font size different from allowed font size variables i.e default, small etc.
                style={{ fontSize: customFontSize }}
                fontSize={fontSize}
            />
        </Tooltip>
    );
};

export default Infotip;
