// @flow

export const ToastType = { DEFAULT: 'default' };

export type ToastTypeEnumType = $Values<typeof ToastType>;

export type Toast = {
    type: ToastTypeEnumType,
    message: string,
    toastDuration?: number,
};
