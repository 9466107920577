//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';

import { ApiButton } from 'components/core/button';
import Form from 'components/core/form';
import { useClientId } from 'hooks/client';

import { ApiStatusEnum } from 'enums/API';

import clientBrandingFormObjectBuilder from './ClientBrandingFormObject';
import { useFileUpload, useBrandingFormValidation } from 'hooks/common';
import { getStatusAndErrorFields } from 'utils/BrandingUtils';

const ClientBranding = (): React.Node => {
    const clientId = useClientId();
    const [status, setStatus] = React.useState(ApiStatusEnum.Idle);

    const fieldsWithError = React.useRef([]);
    const fileUploadMutation = useFileUpload();

    const onSubmit = (data) => {
        const { formData } = data;
        const { logo, favicon, vehicleIcon } = formData;
        fieldsWithError.current = [];

        if (logo || favicon || vehicleIcon) {
            setStatus(ApiStatusEnum.Loading);
        }

        const payload = {
            logo,
            favicon,
            'vehicle-icon': vehicleIcon,
        };

        fileUploadMutation.mutateAsync(
            { clientId, payload },
            {
                onSuccess: (resp: Array<any>) => {
                    const { status, errors } = getStatusAndErrorFields(resp);
                    fieldsWithError.current = errors;
                    setStatus(status);
                },
            },
        );
    };

    const [
        extraErrors,
        onFaviconLoad,
        onVehicleIconLoad,
    ] = useBrandingFormValidation();
    const clientBrandingFormObject = React.useMemo(
        () =>
            clientBrandingFormObjectBuilder({
                onFaviconLoad,
                onVehicleIconLoad,
            }),
        [onFaviconLoad, onVehicleIconLoad],
    );

    const { current: fieldsFaileToUpdate } = fieldsWithError;

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="65%"
        >
            <Form
                section={clientBrandingFormObject}
                onSubmit={onSubmit}
                extraErrors={extraErrors}
            >
                <ApiButton
                    apiStatus={status}
                    successMessage="Client branding changed successfully"
                    errorMessage={`Error: Failed to update ${fieldsFaileToUpdate.join(
                        ', ',
                    )}`}
                />
            </Form>
        </Box>
    );
};

export default ClientBranding;
