//@flow

export const VolumeUnitEnum = Object.freeze({
    ITEM_COUNT: 'ITEM_COUNT',
    TASK_COUNT: 'TASK_COUNT',
    METERS_CUBIC: 'METERS_CUBIC',
    LBH_METERS: 'LBH_METERS',
});

export type VolumeUnitEnumType = $Values<typeof VolumeUnitEnum>;
