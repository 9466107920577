//@flow
import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';

export const dateTimeSubSectionFormObject: FormSectionType = {
    id: 'DATE_TIME',
    type: 'SECTION',
    subSections: [
        {
            id: 'separator',
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.SELECT,
            title: 'Separator',
            required: true,
            options: {
                itemsType: FormPropertyTypeEnum.STRING,
                items: [
                    {
                        id: 'hyphen',
                        label: '-',
                        value: '-',
                    },
                    {
                        id: 'none',
                        label: 'None',
                        value: '',
                    },
                ],
            },
        },
        {
            title: 'Date/Time',
            id: 'sequence',
            type: 'ARRAY',
            minItems: 1,
            items: {
                id: 'dateTimeSub',
                type: 'FIELD',
                fieldType: FormFieldTypeEnum.SELECT,
                title: 'Type',
                required: true,
                options: {
                    itemsType: FormPropertyTypeEnum.STRING,
                    items: [
                        {
                            id: 'YYYY',
                            label: 'Year(YYYY)',
                            value: 'YYYY',
                        },
                        {
                            id: 'YY',
                            label: 'Year(YY)',
                            value: 'YY',
                        },
                        {
                            id: 'MM',
                            label: 'Month(MM)',
                            value: 'MM',
                        },
                        {
                            id: 'dd',
                            label: 'Date(dd)',
                            value: 'dd',
                        },
                        {
                            id: 'hh',
                            label: 'Hour(hh)',
                            value: 'hh',
                        },
                        {
                            id: 'mm',
                            label: 'Minute(mm)',
                            value: 'mm',
                        },
                        {
                            id: 'ss',
                            label: 'Second(ss)',
                            value: 'ss',
                        },
                    ],
                },
            },
        },
    ],
};
