// @flow
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ClientTabsContainer from 'containers/client/ClientTabsContainer';
import { tabs } from 'containers/client/Tabs';

type Props = {};

const ClientTabRoutes = (props: Props): React.Node => {
    const {
        match: { path },
    } = props;
    const [{ id: defaultAppId }] = tabs;
    return (
        <Switch>
            <Route
                path={`${path}/app/:appId`}
                component={ClientTabsContainer}
            />
            <Redirect to={`${path}/app/${defaultAppId}`} />
        </Switch>
    );
};

export default ClientTabRoutes;
