//@flow

import * as React from 'react';

import RadioButtons from 'components/core/radio-buttons';

type Props = {
    value: any,
    label: string,
    options: {
        inline?: boolean,
        enumOptions: Array<{
            label: string | number,
            value: string | number,
        }>,
    },
    required?: boolean,
    onChange: (value: any) => void,
};

const RadioWidget = (props: Props): React.Node => {
    const {
        value,
        options: { enumOptions, inline },
        label,
        onChange,
        required,
    } = props;

    const options = React.useMemo(
        () =>
            enumOptions.map((option) => {
                const { label } = option;
                return { ...option, id: label };
            }),
        [enumOptions],
    );

    return (
        <RadioButtons
            value={value}
            required={required}
            options={options}
            title={label}
            onChange={onChange}
            inline={inline}
        />
    );
};

export default RadioWidget;
