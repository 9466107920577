//@flow
import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { H3, Body1 } from 'components/core/typography';
import StageContainer from './StageContainer';
import StatusContainer from './StatusContainer';
import DomainsContainer from './DomainsContainer';
import ClientExpiryContainer from './ClientExpiryContainer';
import { type ClientType } from 'models/apps/client/Client';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        clientRightSideBar: {
            width: '100%',
        },
        clientMainContainerWrap: {
            width: '100%',
            height: '100%',
            borderTop: `1px solid ${grey[200]}`,
        },
        sideBar: {
            borderRight: `1px solid ${grey[200]}`,
        },
    };
});

type Props = {
    client: ClientType,
};

const SideBar = (props: Props): React.Node => {
    const { client } = props;

    const classes = useStyles();
    return (
        <Box
            className={classes.sideBar}
            display="flex"
            flexDirection="column"
            p={2}
            width="340px"
        >
            <Box pb={2}>
                <H3> {client.name} </H3>
                <Body1 color="textSecondary">
                    Client ID: {client.clientId}
                </Body1>
            </Box>
            <Box pb={2}>
                <StageContainer client={client} />
            </Box>
            <Box pb={2}>
                <StatusContainer client={client} />
            </Box>
            <Box pb={2}>
                <ClientExpiryContainer client={client} />
            </Box>
            <DomainsContainer client={client} />
        </Box>
    );
};

export default SideBar;
