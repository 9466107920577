//@flow

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import Loader from 'components/core/loader';
import { ApiButton } from 'components/core/button';
import dashSettingsFormObject from 'containers/configure-client/dash-settings/DashSettingsFormObject';
import { useDashSettings, useUpdateDashSettings } from 'hooks/client';
import ErrorContainer from 'containers/ErrorContainer';
import Form from 'components/core/form';
import { ApiStatusEnum } from 'enums/API';

const DashSettings = (): React.Node => {
    const formRef = React.useRef();
    const { clientId } = useParams();
    const settingsQuery = useDashSettings(clientId);
    const updateDashSettingsMutation = useUpdateDashSettings();
    if (settingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const onSubmit = (data) => {
        const { formData } = data;
        updateDashSettingsMutation.mutate({ clientId, settings: formData });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
        >
            {settingsQuery.status === ApiStatusEnum.Success && (
                <>
                    <Form
                        section={dashSettingsFormObject}
                        ref={formRef}
                        onSubmit={onSubmit}
                        initialValue={settingsQuery.data}
                    />
                    <ApiButton
                        apiStatus={updateDashSettingsMutation.status}
                        successMessage="Dash Settings updated successfully"
                        errorMessage={updateDashSettingsMutation.error?.message}
                        onClick={triggerClick}
                    />
                </>
            )}
            {settingsQuery.status === ApiStatusEnum.Error && (
                <ErrorContainer message={settingsQuery.error.message} />
            )}
        </Box>
    );
};

export default DashSettings;
