//@flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiChip, type ChipProps } from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        chip: {
            borderRadius: '2px',
            backgroundColor: grey[200],
            padding: '2px',
            margin: '2px',
        },
    };
});

const Chip = (props: ChipProps) => {
    const { size = 'small', label, onDelete } = props;
    const classes = useStyles();
    return (
        <MuiChip
            className={classes.chip}
            size={size}
            label={label}
            onDelete={onDelete}
        />
    );
};

export default Chip;
export { Chip };
