//@flow
import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';

import { statusOptions } from 'constants/apps/client';
import { SelectWithBoxedLabel } from 'components/core/select';
import Modal from 'components/core/modal';
import TextField from 'components/core/input';
import { ApiButton } from 'components/core/button';
import { Body2 } from 'components/core/typography';

import { type ClientType } from 'models/apps/client/Client';
import useUpdateClientStatus from 'hooks/client/useUpdateClientStatus';

type Props = {
    client: ClientType,
};

const StatusContainer = (props: Props): React.Node => {
    const { client } = props;
    const { status } = client;
    const [open, setOpen] = React.useState(false);
    const [reason, setReason] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState(status);
    const updateStatusMutation = useUpdateClientStatus();

    const openUpdateStatusModal = (selectedStatus) => {
        setOpen(true);
        setSelectedStatus(selectedStatus);
        setReason('');
    };

    const onUpdateStatusClick = () => {
        updateStatusMutation.mutate({
            clientId: client.clientId,
            status: selectedStatus,
            reason,
        });
    };

    const onModalClose = () => {
        updateStatusMutation.reset();
        setOpen(false);
    };

    return (
        <>
            <SelectWithBoxedLabel
                labelBoxProps={{ label: 'Status' }}
                selectProps={{
                    items: statusOptions,
                    value: status,
                    fullWidth: true,
                    onChange: openUpdateStatusModal,
                }}
            />
            <Modal open={open} title="Reason For Change" onClose={onModalClose}>
                <Box width="500px">
                    <Box pb={0.5}>
                        <Body2> Description </Body2>
                    </Box>
                    <Box pb={1}>
                        <TextField
                            multiline
                            fullWidth
                            rows="4"
                            placeholder="Please provide the exact reason for this change.
                            This will be used for dashboard audit purposes"
                            onChange={setReason}
                        />
                    </Box>
                    <ApiButton
                        apiStatus={updateStatusMutation.status}
                        errorMessage={updateStatusMutation.error?.message}
                        successMessage="Status updated successfully"
                        disabled={isEmpty(reason)}
                        onClick={onUpdateStatusClick}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default StatusContainer;
