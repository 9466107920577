//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import { ApiStatusEnum } from 'enums/API';
import { useTeams } from 'hooks/team';
import { H3, Body1 } from 'components/core/typography';
import Loader from 'components/core/loader';
import { default as TeamSelect } from 'components/common/TeamSelect';
import ErrorContainer from 'containers/ErrorContainer';
import { Form } from './Form';
import { useClientId } from 'hooks/client';

const TeamVaeConfig = (): React.Node => {
    const clientId = useClientId();
    const teamsQuery = useTeams(clientId);
    const [selectedTeamId, setSelectedTeamId] = React.useState(null);

    if (teamsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box width="100%" p={1} m="auto">
            <H3 align="center">Team VAE Config</H3>
            {teamsQuery.status === ApiStatusEnum.Error ? (
                <ErrorContainer message={teamsQuery.error.message} />
            ) : teamsQuery.data.length === 0 ? (
                <Body1> No Teams Found</Body1>
            ) : (
                <Box width="25%" m="auto">
                    <TeamSelect
                        teams={teamsQuery.data}
                        onChange={setSelectedTeamId}
                        value={selectedTeamId}
                    />
                </Box>
            )}
            {selectedTeamId && (
                <Form clientId={clientId} teamId={selectedTeamId} />
            )}
        </Box>
    );
};
export default TeamVaeConfig;
