//@flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';

import TextField from 'components/core/input';
import JSONEditor from 'components/core/json-editor';
import { ApiButton } from 'components/core/button';
import { Body1 } from 'components/core/typography';
import { useUpdateBUSettings } from 'hooks/businessUnit';
import { useToast } from 'containers/toast/Toast';
import { JsonEditorModeEnum } from 'enums/JsonEditorMode';
import JSONUtils from 'utils/JSONUtils';

type Props = {
    clientId: string,
    businessUnitId: string,
    settings: {
        payload: string,
    },
};

const UpdateBUSettingsForm = (props: Props): React.Node => {
    const { clientId, businessUnitId, settings } = props;
    const [value, setValue] = React.useState(() =>
        JSON.parse(settings.payload),
    );
    const [reason, setReason] = React.useState('');
    const { showToast } = useToast();

    const updateBUSettingsMutation = useUpdateBUSettings();

    const onClick = () => {
        updateBUSettingsMutation.mutate({
            clientId,
            businessUnitId,
            settings: value,
            reason,
        });
    };
    const onChangeText = (textValue) => {
        const isValid = JSONUtils.isValidJSON(textValue);
        if (isValid) {
            const jsonValue = JSON.parse(textValue);
            setValue(jsonValue);
        } else {
            showToast('Invalid Config set in Text editor');
        }
    };

    return (
        <>
            <Box py={1} width="100%" maxHeight="500px">
                <JSONEditor
                    mode={JsonEditorModeEnum.TREE}
                    modes={[JsonEditorModeEnum.TREE, JsonEditorModeEnum.TEXT]}
                    value={value}
                    onChangeJSON={(config) => setValue(config)}
                    onChangeText={onChangeText}
                />
            </Box>
            <Box py={1} width="100%">
                <Body1>Reason</Body1>
                <TextField
                    multiline
                    fullWidth
                    value={reason}
                    rows="4"
                    placeholder="Please provide the exact reason for
                            this change. This will be used for dashboard audit purposes"
                    onChange={setReason}
                />
            </Box>
            <ApiButton
                apiStatus={updateBUSettingsMutation.status}
                successMessage="BU Settings updated successfully"
                errorMessage={updateBUSettingsMutation.error?.message}
                onClick={onClick}
                disabled={isEmpty(reason)}
            />
        </>
    );
};

export default UpdateBUSettingsForm;
