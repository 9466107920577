//@flow
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';

export const customFieldsFormObject = {
    id: 'customFields',
    title: 'Custom Fields',
    type: 'SECTION',
    subSections: [
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'key',
            title: 'Key',
            required: true,
            filterable: true,
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'name',
            title: 'Name',
            required: true,
            filterable: true,
        },
        {
            type: 'SECTION',
            id: 'metadata',
            title: 'Metadata',
            subSections: [
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.SELECT,
                    title: 'Data Type',
                    id: 'dataType',
                    accessor: 'metadata.dataType',
                    options: {
                        items: [
                            {
                                id: 'BOOLEAN',
                                label: 'Boolean',
                                value: 'BOOLEAN',
                            },
                            {
                                id: 'DOUBLE',
                                label: 'Double',
                                value: 'DOUBLE',
                            },
                            {
                                id: 'INTEGER',
                                label: 'Integer',
                                value: 'INTEGER',
                            },
                            {
                                id: 'POSITIVE_DOUBLE',
                                label: 'Positive Double',
                                value: 'POSITIVE_DOUBLE',
                            },
                            {
                                id: 'POSITIVE_INTEGER',
                                label: 'Positive Integer',
                                value: 'POSITIVE_INTEGER',
                            },
                            {
                                id: 'STRING',
                                label: 'String',
                                value: 'STRING',
                            },
                            {
                                id: 'WHOLE_NUMBER',
                                label: 'Whole Number',
                                value: 'WHOLE_NUMBER',
                            },
                        ],
                        itemsType: FormPropertyTypeEnum.STRING,
                    },
                    filterable: true,
                    required: true,
                },
                {
                    type: 'ARRAY',
                    id: 'possibleValues',
                    accessor: 'metadata.possibleValues',
                    title: 'Possible Values',
                    items: {
                        type: 'FIELD',
                        id: 'possibleValue',
                        fieldType: FormFieldTypeEnum.TEXT_FIELD,
                        title: 'Possible Value',
                    },
                    filterable: true,
                },
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.SELECT,
                    title: 'Format',
                    accessor: 'metadata.format',
                    id: 'format',
                    filterable: true,
                    options: {
                        items: [
                            {
                                id: 'SINGLE_CHOICE',
                                label: 'Single Choice',
                                value: 'SINGLE_CHOICE',
                            },
                            {
                                id: 'TEXT_FIELD',
                                label: 'Text Field',
                                value: 'TEXT_FIELD',
                            },
                        ],
                        itemsType: FormPropertyTypeEnum.STRING,
                    },
                },
                {
                    type: 'FIELD',
                    id: 'filterable',
                    accessor: 'metadata.filterable',
                    title: 'Filterable',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    initialValue: false,
                    filterable: true,
                },
                {
                    type: 'FIELD',
                    id: 'sortable',
                    accessor: 'metadata.sortable',
                    title: 'Sortable',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    initialValue: false,
                    filterable: true,
                },
                {
                    type: 'FIELD',
                    id: 'clusterable',
                    accessor: 'metadata.clusterable',
                    title: 'Clusterable',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    initialValue: false,
                    filterable: true,
                },
                {
                    type: 'FIELD',
                    id: 'visible',
                    title: 'Visible',
                    accessor: 'metadata.visible',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    initialValue: false,
                    filterable: true,
                },
                {
                    type: 'FIELD',
                    id: 'isModelProperty',
                    accessor: 'metadata.isModelProperty',
                    title: 'Is Model Property',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    initialValue: false,
                    filterable: true,
                },
                {
                    type: 'FIELD',
                    id: 'isResource',
                    accessor: 'metadata.isResource',
                    title: 'Is Resource',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    initialValue: false,
                    filterable: true,
                },
                {
                    type: 'FIELD',
                    id: 'aggregatable',
                    accessor: 'metadata.aggregatable',
                    title: 'Aggregatable',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    initialValue: false,
                    filterable: true,
                },
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.SELECT,
                    title: 'Aggregation',
                    accessor: 'metadata.aggregation',
                    id: 'aggregation',
                    options: {
                        items: [
                            {
                                id: 'AVERAGE',
                                label: 'Average',
                                value: 'AVERAGE',
                            },
                            {
                                id: 'SUM',
                                label: 'Sum',
                                value: 'SUM',
                            },
                        ],
                        itemsType: FormPropertyTypeEnum.STRING,
                    },
                    filterable: true,
                },
            ],
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_AREA,
            id: 'reason',
            title: 'Reason',
            required: true,
        },
    ],
};
