//@flow
import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { Modal } from 'components/core/modal';
import { Body1, Body2 } from 'components/core/typography';
import TextField from 'components/core/input';
import { ApiButton, Button } from 'components/core/button';

import { type ClientType } from 'models/apps/client/Client';
import useUpdateClientExpiry from 'hooks/client/useUpdateClientExpiry';
import LabelBox from 'components/core/label';
import TimeUtils from 'utils/TimeUtils';

type Props = {
    client: ClientType,
};

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        accountExpiry: {
            border: `1px solid ${grey[400]}`,
            padding: 8,
        },
    };
});
const STAGE_EXPIRY_DAYS_MAPPING = {
    DEMO: 14,
    PILOT: 30,
};
const ClientExpiryContainer = (props: Props): React.Node => {
    const classes = useStyles();
    const { client } = props;
    const {
        expiresOn,
        stage,
        clientPreferences: {
            localeSettings: { timezone },
        },
    } = client;

    const [reason, setReason] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const updateClientExpiry = useUpdateClientExpiry();

    if (!expiresOn || !(stage in STAGE_EXPIRY_DAYS_MAPPING)) {
        return null;
    }

    const expiresOnClientTime = TimeUtils.convertToTimezoneString({
        dateTime: expiresOn,
        timezone,
    });

    const updatedExpiryDate = TimeUtils.add({
        date: TimeUtils.now(),
        period: STAGE_EXPIRY_DAYS_MAPPING[stage],
    });
    const updatedExpiryDateClientTime = TimeUtils.convertToTimezoneString({
        dateTime: updatedExpiryDate,
        timezone,
    });
    const openUpdateClientExpiryModal = () => {
        setOpen(true);
        setReason('');
    };

    const onUpdateClientExpiryClick = () => {
        updateClientExpiry.mutate({
            clientId: client.clientId,
            reason,
        });
    };

    const onModalClose = () => {
        updateClientExpiry.reset();
        setOpen(false);
    };

    return (
        <>
            <Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    height="100%"
                    justifyContent="center"
                >
                    <LabelBox label={'Account Expiry'} />
                    <Box
                        width="100%"
                        height="100%"
                        className={classes.accountExpiry}
                        textAlign="center"
                    >
                        <Body1>{expiresOnClientTime}</Body1>
                    </Box>
                </Box>
                <Box display="flex" py={1} justifyContent="flex-end">
                    <Button
                        size="small"
                        customFontSize="12px"
                        color="primary"
                        variant="text"
                        onClick={openUpdateClientExpiryModal}
                    >
                        RENEW
                    </Button>
                </Box>
            </Box>
            <Modal open={open} title="Reason For Change" onClose={onModalClose}>
                <Box width="500px">
                    <Box pb={1}>
                        <Body2>Updated Expiry Date:</Body2>
                        <Body1>{updatedExpiryDateClientTime}</Body1>
                    </Box>
                    <Box pb={0.5}>
                        <Body2> Description </Body2>
                    </Box>
                    <Box pb={1}>
                        <TextField
                            multiline
                            fullWidth
                            rows="4"
                            placeholder="Please provide the exact reason for this change.
                            This will be used for dashboard audit purposes"
                            onChange={setReason}
                        />
                    </Box>
                    <Box pb={1}>
                        <Body1>
                            <Body2 display="inline">NOTE: </Body2>
                            Demo clients expire in 2 weeks. Pilot clients expire
                            in a month. Test, Devo, Pre Prod, Prod, Prod Plus
                            clients never expire.
                        </Body1>
                    </Box>

                    <ApiButton
                        apiStatus={updateClientExpiry.status}
                        errorMessage={updateClientExpiry.error?.message}
                        successMessage="Client expiry date updated successfully"
                        disabled={isEmpty(reason)}
                        onClick={onUpdateClientExpiryClick}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default ClientExpiryContainer;
