// @flow
import { useMutation, type MutationResult } from 'react-query';

import TeamSettingsUtils from 'utils/TeamSettingsUtils';
import { type TeamSettingsType } from 'models/apps/team';

type Variables = {
    clientId: string,
    teamId: string,
    settings: TeamSettingsType,
    reason: string,
};

type Result = TeamSettingsType;

export default function useUpdateTeamSettings(): MutationResult<
    Variables,
    Result,
> {
    return useMutation<Variables, void>(
        ({ clientId, teamId, reason, settings }) => {
            return TeamSettingsUtils.updateSettings(
                clientId,
                teamId,
                settings,
                reason,
            );
        },
    );
}
