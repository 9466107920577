//@flow

export const FormPropertyTypeEnum = Object.freeze({
    INTEGER: 'integer',
    STRING: 'string',
    BOOLEAN: 'boolean',
    NUMBER: 'number',
});

export type FormPropertyTypeEnumType = $Values<typeof FormPropertyTypeEnum>;
