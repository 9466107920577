// @flow

import { useQuery, type QueryResult } from 'react-query';

import TeamSettingsUtils from 'utils/TeamSettingsUtils';
import ClientSettingsUtils from 'utils/ClientSettingsUtils';
import { type TeamSettingsType } from 'models/apps/team';

type Params = {
    clientId: string,
    teamId: string,
    mergeAttributeList: Array<string>,
};
export default function useMergedTeamSettings(
    params: Params,
): QueryResult<TeamSettingsType> {
    const { clientId, teamId, mergeAttributeList } = params;
    return useQuery({
        queryKey: ['clients', clientId, 'team', teamId, 'merged-settings'],
        queryFn: async () => {
            const [clientSettings, teamSettings] = await Promise.all([
                ClientSettingsUtils.fetchSettings(clientId),
                TeamSettingsUtils.fetchSettings(clientId, teamId),
            ]);
            return TeamSettingsUtils.getMergedSettings(
                clientSettings,
                teamSettings,
                mergeAttributeList,
            );
        },
    });
}
