//@flow

import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import { type FormSectionType } from 'models/FormSection';

import { type FormRowFieldType } from 'models/FormRowField';
import { type FormColumnFieldType } from 'models/FormColumnField';

const useStyles = makeStyles({
    formGroupField: {
        gap: '8px 16px',
    },
});

type FormGroupProps = {
    fieldIdElementMapping: { [string]: React.Node },
    flexDirection: 'ROW' | 'COLUMN',
    section: FormRowFieldType | FormColumnFieldType,
};

const FormGroup = (props: FormGroupProps) => {
    const {
        section: { fields },
        flexDirection,
        fieldIdElementMapping,
    } = props;
    const classes = useStyles();
    return (
        <Box
            className={classes.formGroupField}
            display="flex"
            flexDirection={flexDirection}
            alignItems={flexDirection === 'ROW' ? 'flex-start' : 'flex-end'}
            width="100%"
        >
            {fields.map((field) => {
                const { id } = field;
                return (
                    <FieldTemplate
                        key={id}
                        field={field}
                        fieldIdElementMapping={fieldIdElementMapping}
                    />
                );
            })}
        </Box>
    );
};

type Props = {
    field: FormSectionType,
    fieldIdElementMapping: { [string]: React.Node },
};

const FieldTemplate = (props: Props): React.Node => {
    const {
        field,
        field: { id, type },
        fieldIdElementMapping,
    } = props;
    if (type === 'ROW' || type === 'COLUMN') {
        return (
            <FormGroup
                width="100%"
                key={id}
                flexDirection={type}
                section={field}
                fieldIdElementMapping={fieldIdElementMapping}
            />
        );
    }
    const content = fieldIdElementMapping[id];
    return (
        <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
            width="100%"
            py={0.5}
        >
            {content}
        </Box>
    );
};

export default FieldTemplate;
