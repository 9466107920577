// @flow
import * as React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/core/button';
import { Box, Paper, makeStyles } from '@material-ui/core';
import TextField from 'components/core/input';
import { FormFieldTemplate } from 'components/core/form-templates';
import { SelectWithAutoComplete } from 'components/core/select';
import ErrorMessage from 'components/core/error-message';
import { ApiStatusEnum } from 'enums/API';
import localeValues from 'resources/localeValues.json';
import { type LocaleSettingsType } from 'models/apps/client/LocaleSettings';

import useUpdateClientSettings from './useUpdateClientSettings';

const useStyles = makeStyles({
    fixedBottomBar: {
        position: 'fixed',
        top: 'auto',
        bottom: '0px',
        left: '0px',
    },
    actionButtons: {
        gap: '8px',
    },
    localeSettingsFormWrapper: {
        gap: '8px',
    },
});

const countries = Object.keys(localeValues).reduce((countries, countryName) => {
    const { code } = localeValues[countryName];
    return countries.concat({
        id: code,
        label: countryName,
        value: countryName,
    });
}, []);

const yupRequiredObject = Yup.string().required('is a required field');
const validationSchema = Yup.object().shape({
    country: yupRequiredObject,
    timezone: yupRequiredObject,
    countryDialCode: yupRequiredObject,
    currency: yupRequiredObject,
    reason: yupRequiredObject,
});

const formSelectOptions = (options: Array<string>) => {
    return options.reduce((selectOptions, selectOption) => {
        return selectOptions.concat({
            id: selectOption,
            label: selectOption,
            value: selectOption,
        });
    }, []);
};

type Props = {
    clientId: string,
    onDone: () => void,
    localeSettings: LocaleSettingsType,
};

const ClientSettingsForm = (props: Props): React.Node => {
    const { clientId, onDone, localeSettings } = props;
    const classes = useStyles();

    const country = React.useMemo(() => {
        return countries.find(
            (country) => country.id === localeSettings.countryCode,
        )?.value;
    }, [localeSettings.countryCode]);
    const {
        currencies: initialCurrencies,
        countryDialCodes: initialCountryDialCodes,
        timezones: initialTimezones,
    } = localeValues[country];

    const [currencies, setCurrencies] = React.useState(initialCurrencies);
    const [countryDialCodes, setCountryDialCodes] = React.useState(
        initialCountryDialCodes,
    );
    const [timezones, setTimezones] = React.useState(initialTimezones);

    const values = {
        country,
        currency: currencies[0],
        timezone: timezones[0],
        countryDialCode: countryDialCodes[0],
    };

    const currencyOptions = formSelectOptions(currencies);
    const timezoneOptions = formSelectOptions(timezones);
    const countryDialCodesOptions = formSelectOptions(countryDialCodes);

    const updateClientSettingsMutation = useUpdateClientSettings();

    const onSubmit = (data) => {
        const { reason, timezone: timezoneWithOffset, ...formData } = data;
        const [finalTimezone] = timezoneWithOffset.split(' ');
        updateClientSettingsMutation.mutate(
            {
                clientId,
                settings: {
                    ...localeSettings,
                    ...formData,
                    timezone: finalTimezone,
                },
                reason,
            },
            {
                onSuccess: onDone,
            },
        );
    };

    return (
        <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(props) => {
                const {
                    values: {
                        country,
                        currency,
                        countryDialCode,
                        timezone,
                        reason,
                    },
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    isValid,
                    dirty,
                } = props;

                const onCountryChange = (value) => {
                    const {
                        currencies: updatedCurrencies,
                        countryDialCodes: updatedCountryDialCodes,
                        timezones: updatedTimezones,
                    } = localeValues[value];
                    setCurrencies(updatedCurrencies);
                    setTimezones(updatedTimezones);
                    setCountryDialCodes(updatedCountryDialCodes);
                    setValues(
                        {
                            currency: updatedCurrencies[0],
                            country: value,
                            timezone: updatedTimezones[0],
                            countryDialCode: updatedCountryDialCodes[0],
                            reason,
                        },
                        true,
                    );
                };

                return (
                    <form onSubmit={handleSubmit}>
                        {updateClientSettingsMutation.status ===
                            ApiStatusEnum.Error && (
                            <ErrorMessage
                                message={
                                    updateClientSettingsMutation.error.message
                                }
                            />
                        )}

                        <FormFieldTemplate id="country" label="Country">
                            Country *
                            <SelectWithAutoComplete
                                value={country}
                                items={countries}
                                onChange={onCountryChange}
                            />
                        </FormFieldTemplate>
                        <FormFieldTemplate id="currency" label="Currency">
                            Currency *
                            <SelectWithAutoComplete
                                value={currency}
                                items={currencyOptions}
                                onChange={(value) =>
                                    setFieldValue('currency', value, true)
                                }
                            />
                        </FormFieldTemplate>
                        <FormFieldTemplate
                            id="countryDialCode"
                            label="Country Dial Code"
                        >
                            Country Dial Code *
                            <SelectWithAutoComplete
                                value={countryDialCode}
                                items={countryDialCodesOptions}
                                onChange={(value) =>
                                    setFieldValue(
                                        'countryDialCode',
                                        value,
                                        true,
                                    )
                                }
                            />
                        </FormFieldTemplate>
                        <FormFieldTemplate id="timezone" label="Timezone">
                            Timezone *
                            <SelectWithAutoComplete
                                value={timezone}
                                items={timezoneOptions}
                                onChange={(value) =>
                                    setFieldValue('timezone', value, true)
                                }
                            />
                        </FormFieldTemplate>
                        <FormFieldTemplate id="reason" label="Reason">
                            Reason *
                            <TextField
                                multiline
                                value={reason}
                                fullWidth
                                rows="4"
                                placeholder="Please provide the exact reason for this change.
                            This will be used for dashboard audit purposes"
                                onChange={(value) =>
                                    setFieldValue('reason', value, true)
                                }
                            />
                        </FormFieldTemplate>
                        <Box className={classes.fixedBottomBar} width="100%">
                            <Paper variant="outlined">
                                <Box
                                    mx={10}
                                    display="flex"
                                    flexDirection="row-reverse"
                                    className={classes.actionButtons}
                                    py={1}
                                >
                                    <Button
                                        type="submit"
                                        size="small"
                                        color="primary"
                                        disabled={!(dirty && isValid)}
                                        onClick={handleSubmit}
                                    >
                                        APPLY & FINISH
                                    </Button>
                                </Box>
                            </Paper>
                        </Box>
                    </form>
                );
            }}
        </Formik>
    );
};

export default ClientSettingsForm;
