// @flow
import { useQuery, type QueryResult } from 'react-query';

import { apiInstance } from 'middleware/axios';
import { type PasswordAuthSettingsType } from 'models/apps/client';

export default function usePasswordAuthSettings(
    clientId: string,
): QueryResult<PasswordAuthSettingsType> {
    return useQuery({
        queryKey: ['clients', clientId, 'password-auth-settings'],
        queryFn: () => {
            return apiInstance
                .get(`/client/${clientId}/password-auth-settings`)
                .then((response) => {
                    return response.data;
                });
        },
    });
}
