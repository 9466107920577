// @flow
import * as React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import AppLoginView from 'components/login/AppLoginView';
import AppContainer from 'containers/AppContainer';
import PrivateRoute from 'containers/routes/PrivateRoute';

const Routes = (props) => {
    const { history } = props;
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/login" component={AppLoginView} />
                <PrivateRoute path="/" component={AppContainer} />
            </Switch>
        </Router>
    );
};

export default Routes;
