//@flow

import * as React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Body1, Body2 } from 'components/core/typography';
import { type BaseTableProps } from './types';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    const cellBorder = `1px solid ${grey[200]}`;
    return {
        tableWrap: {
            width: '100%',
            height: '100%',
            overflow: 'auto',
        },
        table: {
            border: cellBorder,
            borderTop: '0px',
            width: '100%',
            borderCollapse: 'inherit',
            borderSpacing: '0',
        },
        tableHeaderRow: {
            height: '40px',
        },
        rowGroup: {
            '&:hover': {
                backgroundColor: grey[100],
                cursor: (props) => {
                    return props.isRowClickable ? 'pointer' : 'default';
                },
            },
        },
        tableCell: {
            borderLeft: cellBorder,
            borderTop: cellBorder,
            height: 35,
            padding: '0px 8px 0px 8px',
            textOverflow: 'ellipsis',
            overflow: 'visible',
            whiteSpace: 'nowrap',
            '&:nth-child(1)': {
                borderLeft: '0px',
            },
        },
        headerCell: {
            textAlign: 'left',
            background: grey[100],
            position: 'sticky',
            top: '0px',
        },
        filterCell: {
            padding: '4px',
            top: '40px',
        },
    };
});

const BaseTable = <T>({
    idField,
    filters,
    onRowClick,
    tableInstance,
}: BaseTableProps<T>): React.Node => {
    const {
        getTableProps,
        getTableBodyProps,
        visibleColumns,
        page,
        prepareRow,
        setAllFilters,
    } = tableInstance;
    React.useEffect(() => {
        filters && setAllFilters(filters);
    }, [setAllFilters, filters]);

    const classes = useStyles({ isRowClickable: !!onRowClick });

    return (
        <Box className={classes.tableWrap}>
            <table className={classes.table} {...getTableProps()}>
                <thead className={classes.tableHeader}>
                    <tr className={classes.tableHeaderRow}>
                        {visibleColumns.map((column) => {
                            const { id } = column;
                            return (
                                <th
                                    key={id}
                                    className={classnames(
                                        classes.tableCell,
                                        classes.headerCell,
                                    )}
                                    {...column.getHeaderProps()}
                                >
                                    <Body2 color="secondary" component="span">
                                        {column.render('Header')}
                                    </Body2>
                                </th>
                            );
                        })}
                    </tr>
                    <tr className={classes.tableHeaderRow}>
                        {visibleColumns.map((column) => {
                            const { id } = column;
                            return (
                                <th
                                    key={id}
                                    className={classnames(
                                        classes.tableCell,
                                        classes.headerCell,
                                        classes.filterCell,
                                    )}
                                    {...column.getHeaderProps()}
                                >
                                    <div>
                                        {column.canFilter &&
                                            column.render('Filter')}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const { id, values } = row;
                        const rowIdentifier = values[idField];
                        const additionalProps = onRowClick
                            ? { onClick: () => onRowClick(rowIdentifier) }
                            : {};
                        return (
                            <tr
                                className={classes.rowGroup}
                                key={id}
                                {...additionalProps}
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell) => {
                                    const { id } = cell;
                                    return (
                                        <td
                                            className={classes.tableCell}
                                            key={id}
                                            {...cell.getCellProps()}
                                        >
                                            <Body1>{cell.render('Cell')}</Body1>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Box>
    );
};

export { BaseTable };
export default BaseTable;
