// @flow
import { BrandingImageSize } from 'constants/common/branding';
import { ApiStatusEnum } from 'enums/API';
import { PromiseStatus } from 'utils/PromiseUtils';

export const validateIconSize = (
    key: string,
    width: number,
    height: number,
): ?string => {
    // Check the image resolution
    if (
        width > BrandingImageSize[key].width ||
        height > BrandingImageSize[key].height
    ) {
        return `Selected image size is ${width} X ${height} px. Recommended size is ${BrandingImageSize[key].width} X ${BrandingImageSize[key].height} px.`;
    }
};

type ReturnType = {
    errors: Array<string>,
    status: string,
};

export const getStatusAndErrorFields = (resp: Array<any>): ReturnType => {
    const errors = resp.filter((r) => r.status === PromiseStatus.REJECTED);

    if (errors.length) {
        return {
            errors: errors.map((e) => e.id),
            status: ApiStatusEnum.Error,
        };
    } else if (resp.length) {
        return {
            errors: [],
            status: ApiStatusEnum.Success,
        };
    }
    return {
        errors: [],
        status: ApiStatusEnum.Idle,
    };
};
