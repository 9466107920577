// @flow
import { useMutation, type MutationResult } from 'react-query';
import { type IPostBodyLoggerPayloadType } from 'models/apps/client/PostBodyLogger';
import { type ServicesForPostBodyEnumType } from 'enums/PostBodyLogger';
import { serviceAxiosMap } from './useFetchMethods';
type UpdateVariables = {
    service: ServicesForPostBodyEnumType,
    clientId: string,
    payload: IPostBodyLoggerPayloadType,
};

export default function useUpdatePostBodyLogging(): MutationResult<
    UpdateVariables,
    void,
> {
    return useMutation<UpdateVariables, void>(
        ({ service, clientId, payload }) => {
            const instance = serviceAxiosMap[service];
            if (instance) {
                return instance.post(
                    `/client/${clientId}/post-body-logging`,
                    payload,
                );
            } else
                throw new Error(
                    `Invalid service type in PostBody Logger: ${service}`,
                );
        },
    );
}
