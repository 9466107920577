// @flow
import { useMutation, type MutationResult } from 'react-query';
import { apiInstance } from 'middleware/axios';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';

type Variables = {
    clientId: string,
    reason: string,
    client: ClientType,
};

const pathLists = [
    {
        path: 'sectionVisibilitySettings.showNetOptSection',
        value: true,
    },
    {
        path: 'sectionVisibilitySettings.showInsightsSection',
        value: false,
    },
    {
        path: 'sectionVisibilitySettings.showBatchSection',
        value: false,
    },
    {
        path: 'sectionVisibilitySettings.showLiveViewSection',
        value: false,
    },
    {
        path: 'entitySettings.locationSettings.enabled',
        value: false,
    },
    {
        path: 'entitySettings.lineItemSettings.enabled',
        value: false,
    },
    {
        path: 'entitySettings.contactSettings.enabled',
        value: false,
    },
    {
        path: 'entitySettings.riderSettings.enabled',
        value: false,
    },
    {
        path: 'entitySettings.riderPersonaSettings.enabled',
        value: false,
    },
    {
        path: 'entitySettings.mapSettings.enabled',
        value: false,
    },
    {
        path: 'entitySettings.teamSettings.enabled',
        value: false,
    },
    {
        path: 'entitySettings.homebaseSettings.enabled',
        value: false,
    },
];

export default function useCreateNetoptClient(): MutationResult<
    Variables,
    void,
> {
    return useMutation<Variables, void>(
        async ({ clientId, client, reason }) => {
            await apiInstance.put(`client/${clientId}`, client);

            await ClientSettingsUtils.fetchAndUpdateSettings(
                clientId,
                pathLists,
                reason,
            );
        },
    );
}
