//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';

import Drawer from 'components/core/drawer';
import Button from 'components/core/button';
import Form from 'components/core/form';
import { type FormSectionType } from 'models/FormSection';

export type Mode = 'EDIT' | 'ADD';
export type SubmitData<T> = {
    formData: T,
};
type Props<T> = {
    formInfo: {
        mode: Mode,
        formData?: T,
        visible?: boolean,
    },
    onSubmit: (data: SubmitData<T>, mode: Mode) => void,
    onClose: () => void,
    section: FormSectionType,
};

export const RecordForm = <T>({
    section,
    open = true,
    onClose,
    onSubmit: onSubmitProp,
    formInfo,
}: Props<T>): React.Node => {
    const formRef = React.useRef();
    const { mode, formData } = formInfo;
    const onSubmit = (data) => {
        onSubmitProp(data, mode);
        onClose();
    };
    return (
        <Drawer open={open} anchor="right" onClose={onClose}>
            <Box width="400px" p={2}>
                <Form
                    ref={formRef}
                    section={section}
                    onSubmit={onSubmit}
                    initialValue={formData}
                />
                <Box py={1} width="100%" textAlign="center">
                    <Button
                        onClick={() => {
                            formRef.current?.submit();
                        }}
                        color="primary"
                    >
                        SUBMIT
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};
