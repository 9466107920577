//@flow

import * as React from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { NavigateNext } from '@material-ui/icons';

import classes from './NavigationBar.scss';

type Props = {
    navLinks: Array<{ id: string, link: string, label: string }>,
};

const NavigationBar = (props: Props): React.Node => {
    const { navLinks } = props;
    return (
        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
            {navLinks.map((navLink) => {
                const { id, link, label } = navLink;
                return (
                    <Link key={id} to={link} className={classes.navigationLink}>
                        {label}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default NavigationBar;
