//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import Button from 'components/core/button';
import Loader from 'components/core/loader';
import { ApiStatusEnum, type ApiStatusEnumType } from 'enums/API';

type Props = {
    apiStatus: ApiStatusEnumType,
    successMessage: ?string,
    errorMessage: ?string,
    disabled?: boolean,
    onClick?: (event: SyntheticEvent<>) => void,
    children?: React.Node,
};

const ApiButton = (props: Props): React.Node => {
    const {
        apiStatus,
        successMessage,
        errorMessage,
        disabled,
        onClick,
    } = props;
    return (
        <>
            {apiStatus === ApiStatusEnum.Success && (
                <Box pb={1} width="100%">
                    <Alert severity="success">{successMessage}</Alert>
                </Box>
            )}
            {apiStatus === ApiStatusEnum.Error && (
                <Box pb={1} width="100%">
                    <Alert severity="error">{errorMessage}</Alert>
                </Box>
            )}
            <Box py={1} width="100%" textAlign="center">
                <Button
                    color="primary"
                    onClick={onClick}
                    disabled={disabled || apiStatus === ApiStatusEnum.Loading}
                    type="submit"
                >
                    {apiStatus === ApiStatusEnum.Loading ? (
                        <Loader size={22} color="primary" />
                    ) : (
                        'SAVE & PROCEED'
                    )}
                </Button>
            </Box>
        </>
    );
};

export { ApiButton };
