//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';

import { Select, type OptionsListType } from 'components/core/select';
import { Body1, Body2 } from 'components/core/typography';

const defaultRowLimitOptions = [15, 25, 50, 100];

type Props = {
    pageSize: number,
    rowLimitOptions?: Array<number>,
    onChange: (pageSize: number) => void,
};

const PaginationSizeChooser = (props: Props): React.Node => {
    const {
        pageSize,
        rowLimitOptions = defaultRowLimitOptions,
        onChange,
    } = props;
    const rowLimitItems: OptionsListType = rowLimitOptions.map((option) => ({
        id: option,
        label: option,
        value: option,
    }));

    return (
        <Box display="flex" alignItems="center" height="100%">
            <Body2>Show</Body2>
            <Box p={1}>
                <Select
                    value={pageSize}
                    items={rowLimitItems}
                    onChange={onChange}
                />
            </Box>
            <Body1>items on page</Body1>
        </Box>
    );
};

export default PaginationSizeChooser;
