//@flow

import * as React from 'react';
import JSONAceEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

import classes from './JsonEditor.scss';
import {
    JsonEditorModeEnum,
    type JsonEditorModeEnumTypes,
} from 'enums/JsonEditorMode';

type Props = {
    name?: string,
    value: Object,
    mode?: JsonEditorModeEnumTypes,
    modes?: Array<JsonEditorModeEnumTypes>,
    onChangeJSON?: (data: Object) => void,
    onChangeText?: (data: string) => void,
};

const JsonEditor = (props: Props): React.Node => {
    const {
        name,
        value,
        mode = 'tree',
        modes,
        onChangeJSON,
        onChangeText,
    } = props;

    const elRef = React.useRef();
    const [jsonEditor, setJsonEditor] = React.useState(null);

    const options = {
        mode,
        modes,
        name,
        onChangeJSON,
        onChangeText,
    };

    React.useEffect(() => {
        const editor = new JSONAceEditor(elRef.current, options);
        setJsonEditor(editor);
        editor.set(value);
        return () => {
            setJsonEditor(null);
            editor.destroy();
        };
    }, []);

    React.useEffect(() => {
        if (mode === JsonEditorModeEnum.TREE) {
            jsonEditor?.update(value);
        }
        if (mode === JsonEditorModeEnum.TEXT) {
            jsonEditor?.updateText(value);
        }
    }, [value]);

    return <div className={classes.jsonEditor} ref={elRef} />;
};

export default JsonEditor;
