//@flow

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ToastComponent from 'components/core/toast';
import { ToastActions } from 'containers/toast/duck';
import { type ToastTypeEnumType } from 'models/Toast';

const Toast = (): React.Node => {
    const { message, open, toastDuration = 2000 } = useSelector(
        (state) => state.toast,
    );
    const dispatch = useDispatch();
    return (
        <ToastComponent
            message={message}
            autoHideDuration={toastDuration}
            open={open}
            onClose={() => dispatch(ToastActions.hideToast())}
        />
    );
};

type UseToastType = {
    showToast: (
        message: string,
        type?: ToastTypeEnumType,
        duration?: number,
    ) => void,
    hideToast: () => void,
};

export function useToast(): UseToastType {
    const dispatch = useDispatch();
    return {
        showToast: (message, type, duration) => {
            dispatch(ToastActions.showToast(message, type, duration));
        },
        hideToast: () => {
            dispatch(ToastActions.hideToast());
        },
    };
}

export default Toast;
