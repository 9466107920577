//@flow

import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import Loader from 'components/core/loader';
import { H3 } from 'components/core/typography';
import ErrorContainer from 'containers/ErrorContainer';
import { useClientSettings } from 'hooks/client';
import { ApiStatusEnum } from 'enums/API';
import SettingsForm from './SettingsForm';

const Settings = (): React.Node => {
    const { clientId } = useParams();
    const settingsQuery = useClientSettings(clientId);
    if (settingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
        >
            <Box p={1}>
                <H3> Client Settings </H3>
            </Box>
            {settingsQuery.status === ApiStatusEnum.Success && (
                <SettingsForm initialValue={settingsQuery.data} />
            )}
            {settingsQuery.status === ApiStatusEnum.Error && (
                <ErrorContainer message={settingsQuery.error.message} />
            )}
        </Box>
    );
};

export default Settings;
