// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress, {
    CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles({
    loaderWrap: {
        width: '100%',
        height: '100%',
    },
});

const Loader = (props: CircularProgressProps) => {
    const { size, color } = props;
    const classes = useStyles();
    return (
        <Box
            className={classes.loaderWrap}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={size} color={color} />
        </Box>
    );
};

export const LoaderWithBackdrop = () => (
    <Backdrop open>
        <CircularProgress />
    </Backdrop>
);

export default Loader;
