//@flow

import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import {
    usePopupState,
    bindTrigger,
    bindMenu,
    type PopupState,
    type Variant,
} from 'material-ui-popup-state/hooks';

import { Body1 } from 'components/core/typography';
import { ApiStatusEnum, type ApiStatusEnumType } from 'enums/API';

type MenuItemsType = {
    id: string,
    label: string,
    onClick?: () => void,
    divider?: boolean,
};

type PopoverOrigin = {
    vertical: 'top' | 'center' | 'bottom' | number,
    horizontal: 'left' | 'center' | 'right' | number,
};

type Props = {
    status?: ApiStatusEnumType,
    error?: ?string,
    component: string | React.AbstractComponent<any>,
    menuProps: {
        anchorOrigin?: PopoverOrigin,
        transformOrigin?: PopoverOrigin,
        menuItems: Array<MenuItemsType>,
        PaperProps?: any,
    },
    componentProps: any,
    popupProps: {
        parentPopupState?: ?PopupState,
        popupId: ?string,
        variant: Variant,
    },
};

const ComponentWithDropdown = (props: Props): React.Node => {
    const {
        component: Component,
        status,
        error,
        menuProps: { anchorOrigin, transformOrigin, menuItems, PaperProps },
        componentProps: { label, classes, onClick, ...restComponentProps },
        popupProps,
    } = props;

    const popupState = usePopupState(popupProps);

    const onButtonClick = (event) => {
        const { onClick: popoverClick } = bindTrigger(popupState);
        onClick && onClick(event);
        popoverClick(event);
    };

    return (
        <Box component="div">
            <Component
                {...bindTrigger(popupState)}
                {...restComponentProps}
                classes={classes}
                onClick={onButtonClick}
            >
                {label}
            </Component>
            <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={PaperProps}
            >
                <Box component="div">
                    <MenuListBody
                        menuItems={menuItems}
                        status={status}
                        error={error}
                        onClose={popupState.close}
                    />
                </Box>
            </Menu>
        </Box>
    );
};

const MenuListBody = (props) => {
    const { onClose, menuItems, status, error } = props;
    switch (status) {
        case ApiStatusEnum.Loading:
            return (
                <MenuItem onClick={onClose}>
                    <Body1> Loading ... </Body1>
                </MenuItem>
            );
        case ApiStatusEnum.Error:
            return (
                <MenuItem onClick={onClose}>
                    <Body1>{error} </Body1>
                </MenuItem>
            );
        default:
            return menuItems.map((menuItem) => {
                const { id, label, divider, onClick } = menuItem;
                return (
                    <MenuItem
                        key={id}
                        selected={false}
                        onClick={onClick || onClose}
                        onClick={onClick}
                        button={!!onClick}
                        divider={divider}
                    >
                        <Body1>{label}</Body1>
                    </MenuItem>
                );
            });
    }
};

export default ComponentWithDropdown;
