//@flow

export const ChecklistStatusType = Object.freeze({
    STARTED: 'STARTED',
    ARRIVED: 'ARRIVED',
    TRANSACTING: 'TRANSACTING',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
});

export type ChecklistStatusEnumType = $Values<typeof ChecklistStatusType>;
