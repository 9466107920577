// @flow
import * as React from 'react';
import { FormControl } from '@material-ui/core';

import FormLabel from 'components/core/form-label';
import { type TextWidgetProps } from 'components/core/widgets/text-field';
import Dropzone from './Dropzone';

type FileWidgetProps = {
    ...TextWidgetProps,
    multiple?: boolean,
};

const FileWidget = (props: FileWidgetProps): React.Node => {
    const {
        options: { previewHeight, previewWidth, accept, onImageLoad },
        required,
        label,
        multiple,
        onChange,
        schema: { description },
    } = props;

    return (
        <FormControl component="fieldset">
            <FormLabel
                label={label}
                required={required}
                description={description}
            />
            <Dropzone
                accept={accept}
                onChange={onChange}
                multiple={multiple}
                previewHeight={previewHeight}
                previewWidth={previewWidth}
                onImageLoad={onImageLoad}
            />
        </FormControl>
    );
};

export default FileWidget;
