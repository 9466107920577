//@flow

import * as React from 'react';
import { FormControl } from '@material-ui/core';

import { SelectWithAutoComplete } from 'components/core/select';
import FormLabel from 'components/core/form-label';
import { type SchemaType } from 'models/Schema';

type Props = {
    value: string | number,
    label: string,
    options: {
        enumOptions: Array<{
            label: string | number,
            value: string | number,
        }>,
    },
    required?: boolean,
    onChange: (value: any) => void,
    schema: SchemaType,
};

const AutocompleteWidget = (props: Props): React.Node => {
    const {
        options: { enumOptions },
        value,
        label,
        onChange,
        required,
        schema: { description, multiple },
    } = props;

    const options = React.useMemo(
        () =>
            enumOptions.map((option) => {
                const { label } = option;
                return { ...option, id: label };
            }),
        [enumOptions],
    );

    return (
        <FormControl component="fieldset">
            <FormLabel
                label={label}
                required={required}
                description={description}
            />
            <SelectWithAutoComplete
                value={multiple ? value || [] : value}
                items={options}
                onChange={onChange}
                multiple={!!multiple}
            />
        </FormControl>
    );
};

export default AutocompleteWidget;
