// @flow
import { useQuery, type QueryResult } from 'react-query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';
import { type ClientSettingsType } from 'models/apps/client';

export default function useClientSettings(
    clientId: string,
): QueryResult<ClientSettingsType> {
    return useQuery({
        queryKey: ['clients', clientId, 'settings'],
        queryFn: () => {
            return ClientSettingsUtils.fetchSettings(clientId);
        },
    });
}
