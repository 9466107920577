//@flow

import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import ClientSettingsForm from './ClientSettingsForm';
import { H4, Body1 } from 'components/core/typography';
import { useClient } from 'hooks/client';
import Loader from 'components/core/loader';
import ErrorContainer from 'containers/ErrorContainer';
import { ApiStatusEnum } from 'enums/API';

type Props = {
    clientId: string,
    finish: () => void,
};

const useStyles = makeStyles({
    fixedBottomBar: {
        position: 'fixed',
        top: 'auto',
        bottom: '0px',
    },
    actionButtons: {
        gap: '8px',
    },
    localeSettingsFormWrapper: {
        gap: '8px',
    },
});

const ClientSettings = (props: Props): React.Node => {
    const { clientId, finish } = props;
    const classes = useStyles();

    const clientQuery = useClient(clientId);

    if (clientQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    if (clientQuery.status === ApiStatusEnum.Error) {
        return <ErrorContainer message={clientQuery.error.message} />;
    }

    return (
        <Box width="100%" py={2}>
            <Box
                px={10}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
            >
                <Box display="flex" flexDirection="column" width="30%">
                    <Box>
                        <H4> Country Details and Locale Settings</H4>
                    </Box>
                    <Box>
                        <Body1>
                            The input given here will act as default values for
                            this client. Some of these values can also be
                            overwritten at team/personnel level.
                        </Body1>
                    </Box>
                </Box>
                <Box
                    ml={10}
                    pb={3}
                    className={classes.localeSettingsFormWrapper}
                >
                    <ClientSettingsForm
                        clientId={clientId}
                        onDone={finish}
                        localeSettings={
                            clientQuery.data.clientPreferences.localeSettings
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default ClientSettings;
