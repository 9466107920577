//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';

import { ApiButton } from 'components/core/button';
import Form from 'components/core/form';
import { useClientId, useConfigureOrderIqClient } from 'hooks/client';

import configureProductClientObject from './ConfigureProductFormObject';
import { ApiStatusEnum } from 'enums/API';
import { ProductsEnum } from 'enums/Products';

const ConfigureProduct = (): React.Node => {
    const orderIqProductConfigMutation = useConfigureOrderIqClient();
    const [error, setError] = React.useState(null);
    const [apiStatus, setApiStatus] = React.useState(ApiStatusEnum.Idle);
    const clientId = useClientId();

    const onSubmit = React.useCallback(
        (data) => {
            const {
                formData: { productToConfigure },
            } = data;

            switch (productToConfigure) {
                case ProductsEnum.OrderIq:
                    orderIqProductConfigMutation.mutate(
                        {
                            clientId,
                            reason:
                                'Configuring Order Iq from internal dashboard',
                        },
                        {
                            onSuccess: () => {
                                setApiStatus(ApiStatusEnum.Success);
                            },
                            onError: (err) => {
                                setError(err.message);
                                setApiStatus(ApiStatusEnum.Error);
                            },
                        },
                    );
                    break;
                default:
                    throw new Error(`Invalid product ${productToConfigure}`);
            }
        },
        [clientId, orderIqProductConfigMutation],
    );

    const configureProductFormObject = React.useMemo(
        () => configureProductClientObject(),
        [],
    );

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="65%"
        >
            <Form section={configureProductFormObject} onSubmit={onSubmit}>
                <ApiButton
                    apiStatus={apiStatus}
                    successMessage="Product configured successfully"
                    errorMessage={error}
                />
            </Form>
        </Box>
    );
};

export default ConfigureProduct;
