//@flow

export const FormFieldTypeEnum = Object.freeze({
    TEXT_FIELD: 'text-field',
    NUMBER_FIELD: 'number',
    INTEGER_FIELD: 'integer',
    TEXT_AREA: 'text-area',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    AUTOCOMPLETE: 'autocomplete',
    RADIO_BUTTONS: 'radio-buttons',
    SWITCH: 'switch',
    DATE: 'date',
    DATETIME: 'datetime',
    FILE: 'file',
});

export type FormFieldTypeEnumType = $Values<typeof FormFieldTypeEnum>;
