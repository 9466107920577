//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import { ApiButton } from 'components/core/button';
import passwordAuthSettingsFormObject from './PasswordAuthSettingsFormObject';
import useUpdatePasswordAuthSettings from './useUpdatePasswordAuthSettings';
import Form from 'components/core/form';
import { type PasswordAuthSettingsType } from 'models/apps/client';

type Props = {
    clientId: string,
    initialValue: PasswordAuthSettingsType,
};

const PasswordAuthSettingsForm = (props: Props): React.Node => {
    const { clientId, initialValue } = props;
    const formRef = React.useRef();
    const updatePasswordAuthSettingsMutation = useUpdatePasswordAuthSettings();

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const onSubmit = (data) => {
        updatePasswordAuthSettingsMutation.mutate({
            clientId,
            settings: data.formData,
        });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
        >
            <Form
                section={passwordAuthSettingsFormObject}
                ref={formRef}
                onSubmit={onSubmit}
                initialValue={initialValue}
            />
            <ApiButton
                apiStatus={updatePasswordAuthSettingsMutation.status}
                successMessage={'Password Auth Settings updated successfully'}
                errorMessage={updatePasswordAuthSettingsMutation.error?.message}
                onClick={triggerClick}
            />
        </Box>
    );
};

export default PasswordAuthSettingsForm;
