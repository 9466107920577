//@flow
import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { FieldDependencyTypeEnum } from 'enums/FieldDependencyType';
import { ServicesForPostBodyEnum } from 'enums/PostBodyLogger';
export const serviceTypes = [
    {
        id: 'taxy-service',
        label: 'Taxy Service',
        value: ServicesForPostBodyEnum.TAXY,
    },
    {
        id: 'oms-service',
        label: 'OMS Service',
        value: ServicesForPostBodyEnum.OMS,
    },
    {
        id: 'platform-service',
        label: 'Platform Service',
        value: ServicesForPostBodyEnum.PLATFORM,
    },
];

export const loggingDuration = [
    {
        id: '1-hr',
        label: '1 Hour',
        value: 60,
    },
    {
        id: '2-hr',
        label: '2 Hour',
        value: 120,
    },
    {
        id: '3-hr',
        label: '3 Hour',
        value: 180,
    },
    {
        id: '6-hr',
        label: '6 Hour',
        value: 360,
    },
    {
        id: '10-hr',
        label: '10 Hour',
        value: 600,
    },
    {
        id: '12-hr',
        label: '12 Hour',
        value: 720,
    },
    {
        id: '1-d',
        label: '1 Day',
        value: 1440,
    },
    {
        id: '2-d',
        label: '2 Days',
        value: 2880,
    },
    {
        id: '3-d',
        label: '3 Days',
        value: 4320,
    },
];

export const customMethodOption = {
    id: 'OTHER',
    label: 'Other',
    value: 'OTHER',
};

const FormObject: FormSectionType = {
    id: 'postBodyLogger',
    type: 'SECTION',
    subSections: [
        {
            id: 'serviceType',
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.SELECT,
            title: 'Service',
            required: true,
            options: {
                itemsType: FormPropertyTypeEnum.STRING,
                items: serviceTypes,
            },
        },
        {
            id: 'methodName',
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
            title: 'Method Name',
            required: true,
            options: {
                itemsType: FormPropertyTypeEnum.STRING,
                items: [customMethodOption],
            },
        },
        {
            dependency: {
                dependeeFieldId: 'methodName',
                dependeeFieldValue: 'OTHER',
                dependencyType:
                    FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
            },
            id: 'customMethod',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            title: 'Custom Method',
            type: 'FIELD',
        },
        {
            title: 'Duration',
            id: 'loggingDuration',
            fieldType: FormFieldTypeEnum.SELECT,
            required: true,
            type: 'FIELD',
            options: {
                itemsType: FormPropertyTypeEnum.INTEGER,
                items: loggingDuration,
            },
        },
    ],
};

export default FormObject;
