// @flow
import { useQuery, type QueryResult } from 'react-query';

import { type AllClientsType } from 'models/apps/client/AllClients';

import { internalApiInstance } from 'middleware/axios';

export default function useClients(): QueryResult<AllClientsType> {
    return useQuery({
        queryKey: 'clients',
        queryFn: () => {
            return internalApiInstance.get('/client').then((response) => {
                return response.data.minimalClients;
            });
        },
    });
}
