//@flow

import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import Loader from 'components/core/loader';
import { H3 } from 'components/core/typography';
import ErrorContainer from 'containers/ErrorContainer';
import { ApiStatusEnum } from 'enums/API';
import MastersStateForm from './MastersStateForm';

import { useClientSettings } from 'hooks/client';

type Props = {
    label: string,
};

const MastersState = (props: Props): React.Node => {
    const { label } = props;
    const { clientId } = useParams();
    const settingsQuery = useClientSettings(clientId);

    if (!clientId) {
        return null;
    }

    if (settingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
        >
            <Box p={1}>
                <H3>{label}</H3>
            </Box>
            {settingsQuery.status === ApiStatusEnum.Success && (
                <MastersStateForm
                    clientId={clientId}
                    clientSettings={settingsQuery.data}
                />
            )}
            {settingsQuery.status === ApiStatusEnum.Error && (
                <ErrorContainer message={settingsQuery.error.message} />
            )}
        </Box>
    );
};

export default MastersState;
