//@flow

import * as React from 'react';
import {
    Radio as MuiRadio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@material-ui/core';

import FormLabel from 'components/core/form-label';
import { type OptionsListType } from 'components/core/select';

type Props = {
    required?: boolean,
    size?: 'small' | 'medium',
    inline?: boolean,
    title: string,
    value: string,
    options: OptionsListType,
    color?: 'primary' | 'secondary' | 'default',
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom',
    description?: string,
    onChange: (value: string) => void,
};

const RadioButtons = (props: Props): React.Node => {
    const {
        required,
        size = 'small',
        inline,
        title,
        value,
        color = 'primary',
        options,
        labelPlacement,
        description,
        onChange,
    } = props;

    const handleChange = (event) => {
        const {
            target: { value: updatedValue },
        } = event;
        onChange(updatedValue);
    };

    return (
        <FormControl required={required} component="fieldset">
            <FormLabel
                label={title}
                required={required}
                description={description}
            />
            <RadioGroup row={inline} value={value} onChange={handleChange}>
                {options.map((option) => {
                    const { id, label, value } = option;
                    return (
                        <FormControlLabel
                            key={id}
                            value={value}
                            label={label}
                            labelPlacement={labelPlacement}
                            control={<MuiRadio size={size} color={color} />}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtons;
