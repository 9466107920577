//@flow
export const PromiseStatus = {
    RESOLVED: 'RESOLVED',
    REJECTED: 'REJECTED',
};

type SuccessResponse = {
    response: any,
    id?: string,
    status: $Values<typeof PromiseStatus>,
};

type FailureResponse = {
    error: any,
    id?: string,
    status: $Values<typeof PromiseStatus>,
};

export default class PromiseUtils {
    static reflect = function (
        promise: Promise<*>,
        id?: string,
    ): Promise<SuccessResponse | FailureResponse> {
        return promise.then(
            (response) => {
                return { response, id, status: PromiseStatus.RESOLVED };
            },
            (error) => {
                return { error, id, status: PromiseStatus.REJECTED };
            },
        );
    };

    static processAll = function (promises: { [key: string]: Promise<*> }) {
        const reflectingPromises: Array<
            Promise<SuccessResponse | FailureResponse>,
        > = [];
        Object.keys(promises).forEach((id) => {
            reflectingPromises.push(PromiseUtils.reflect(promises[id], id));
        });
        return Promise.all(reflectingPromises);
    };

    static processAllSettled = function (promises: {
        [key: string]: Promise<*>,
    }) {
        const reflectingPromises: Array<
            Promise<SuccessResponse | FailureResponse>,
        > = [];
        Object.keys(promises).forEach((id) => {
            reflectingPromises.push(PromiseUtils.reflect(promises[id], id));
        });
        return Promise.allSettled(reflectingPromises).then((resp) =>
            resp.map((r) => r.value),
        );
    };
}
