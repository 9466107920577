//@flow

import * as React from 'react';
import { Box, Paper, makeStyles } from '@material-ui/core';

import { H4, Body1 } from 'components/core/typography';
import Form from 'components/core/form';
import Button from 'components/core/button';
import ErrorMessage from 'components/core/error-message';
import { ApiStatusEnum } from 'enums/API';

import taskProfileFormObject from './TaskProfileFormObject';
import useAddTaskProfile from './useAddTaskProfile';

type Props = {
    clientId: string,
    onNextClick: () => void,
    finish: () => void,
};

const useStyles = makeStyles({
    fixedBottomBar: {
        position: 'fixed',
        top: 'auto',
        bottom: '0px',
    },
    actionButtons: {
        gap: '8px',
    },
    setupInfoHeader: {
        gap: '4px',
    },
});

const TaskProfile = (props: Props): React.Node => {
    const { clientId, onNextClick } = props;
    const classes = useStyles();
    const formRef = React.useRef();

    const addTaskProfileMutation = useAddTaskProfile();

    const onSubmit = (data) => {
        const { formData } = data;
        addTaskProfileMutation.mutate(
            { clientId, taskProfile: formData },
            {
                onSuccess: onNextClick,
            },
        );
    };

    const triggerClick = () => {
        formRef.current?.submit();
    };

    return (
        <Box width="100%" py={2}>
            <Box
                px={10}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
            >
                <Box
                    className={classes.setupInfoHeader}
                    display="flex"
                    flexDirection="column"
                    width="30%"
                >
                    <H4>Setup Information</H4>
                    <Body1>
                        Here, you can set default volume unit which will act as
                        a constraint while allocating orders to a vehicle.
                        Setting up a Use Case will enable default batch upload
                        template for this client.
                    </Body1>
                </Box>
                <Box ml={10} width="30%">
                    <Form
                        section={taskProfileFormObject}
                        ref={formRef}
                        onSubmit={onSubmit}
                    />
                    {addTaskProfileMutation.status === ApiStatusEnum.Error && (
                        <ErrorMessage
                            message={addTaskProfileMutation.error.message}
                        />
                    )}
                </Box>
            </Box>
            <Box className={classes.fixedBottomBar} width="100%">
                <Paper variant="outlined">
                    <Box
                        mx={10}
                        display="flex"
                        flexDirection="row-reverse"
                        className={classes.actionButtons}
                        py={1}
                    >
                        <Button
                            type="submit"
                            color="primary"
                            onClick={triggerClick}
                            disabled={status === ApiStatusEnum.Loading}
                        >
                            SET TASK PROFILE
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default TaskProfile;
