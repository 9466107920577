//@flow

import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import { Body1 } from 'components/core/typography';
import { Infotip } from 'components/core/tooltip';

type Props = {
    label: string,
    required?: boolean,
    description?: string,
};

const useStyles = makeStyles({
    formLabelWrapper: {
        gap: '4px',
    },
});

const FormLabel = (props: Props): React.Node => {
    const { label, description, required } = props;
    const classes = useStyles();
    return (
        <Box
            className={classes.formLabelWrapper}
            alignItems="center"
            display="flex"
            spacing={1}
        >
            <Body1>{label}</Body1>
            {required && '*'}
            {description && (
                <Infotip title={description} customFontSize="14px" />
            )}
        </Box>
    );
};

export default FormLabel;
