// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { H2 } from 'components/core/typography';

import { Button } from 'components/core/button';
import { type Message } from './Messages.type';

const useStyles = makeStyles((theme) => {
    const {
        palette: { common },
    } = theme;

    return {
        modalStyle: {
            top: '20%',
            left: '20%',
            position: 'absolute',
            width: '60%',
            backgroundColor: common.white,
            padding: '15px',
        },
    };
});

const BodyModal = ({ original }: { original: Message }): React.Node => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div className={classes.modalStyle}>
            <H2>Message Body</H2>
            <TextareaAutosize
                readOnly
                style={{ width: '100%', resize: 'none' }}
                defaultValue={JSON.stringify(
                    JSON.parse(original.body),
                    null,
                    4,
                )}
            />
        </div>
    );

    return (
        <>
            <Button
                size="small"
                variant="text"
                color="primary"
                onClick={handleOpen}
            >
                View Body
            </Button>
            <Modal open={open} onClose={handleClose}>
                {body}
            </Modal>
        </>
    );
};

export { BodyModal };
