//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';

import { H3 } from 'components/core/typography';

type ModalTitlePropsTypes = {
    title: string,
    onClose: () => void,
};

type ModalPropsTypes = {
    ...ModalTitlePropsTypes,
    open: boolean,
    children: React.Node,
};

export const Modal = (props: ModalPropsTypes) => {
    const { open, title, onClose, children } = props;
    return (
        <Dialog open={open}>
            <ModalTitle title={title} onClose={onClose} />
            <Divider />
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export const ModalTitle = (props: ModalTitlePropsTypes) => {
    const { title, onClose } = props;
    return (
        <DialogTitle disableTypography>
            <Box display="flex" flexDirection="row">
                <Box flexGrow={1}>
                    <H3>{title}</H3>
                </Box>
                {onClose && (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        size="small"
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </Box>
        </DialogTitle>
    );
};

export default Modal;
