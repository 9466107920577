//@flow
import * as React from 'react';
import Box from '@material-ui/core/Box';

import { ApiButton } from 'components/core/button';
import Form from 'components/core/form';
import { createNetOptClientFormObject } from './CreateClientFormObject';

import { useCreateNetoptClient } from 'hooks/client';

const AddNetOptClientContainer = (): React.Node => {
    const netOptCreateClientMutation = useCreateNetoptClient();

    const formRef = React.useRef();

    const onSubmit = React.useCallback(
        (data) => {
            const { clientId, ...client } = data.formData;
            netOptCreateClientMutation.mutate(
                // Hack till ES-33917 is closed
                {
                    clientId,
                    client: { ...client, workflowType: 'SCHEDULED' },
                    reason:
                        'Setting up new NetOpt client from internal dashboard',
                },
            );
        },
        [netOptCreateClientMutation],
    );

    return (
        <Box width="400px" p={2}>
            <Form
                ref={formRef}
                section={createNetOptClientFormObject}
                onSubmit={onSubmit}
            />
            <ApiButton
                apiStatus={netOptCreateClientMutation.status}
                successMessage="Client Created Successfully"
                errorMessage={netOptCreateClientMutation.error?.message}
                onClick={() => {
                    formRef.current?.submit();
                }}
            />
        </Box>
    );
};

export default AddNetOptClientContainer;
