// @flow
import axios from 'axios';
import { useQuery, type QueryResult } from 'react-query';

import { type OncallsType } from './models/Oncalls';
import config from 'config';

const pagerdutyBaseUrl = 'https://api.opsgenie.com/v2/';
const { opsGenieApiKey } = config;

const instance = axios.create({
    baseURL: pagerdutyBaseUrl,
    headers: {
        Authorization: `GenieKey ${opsGenieApiKey}`,
    },
});

type Params = {
    fetchOnMount: boolean,
};

export default function useOncalls(params?: Params): QueryResult<OncallsType> {
    return useQuery({
        enabled: params?.fetchOnMount ?? true,
        queryKey: 'oncalls',
        retry: false,
        queryFn: async () => {
            const usersApi = instance.get('/users');
            const schedulesApi = instance.get('/schedules');
            const [usersResponse, schedulesResponse] = await Promise.all([
                usersApi,
                schedulesApi,
            ]);
            const {
                data: { data: users },
            } = usersResponse;
            const {
                data: { data: schedules },
            } = schedulesResponse;
            const emailFullNameMapping = users.reduce((acc, user) => {
                const { username: email, fullName } = user;
                return {
                    ...acc,
                    [email]: fullName,
                };
            }, {});

            const primarySchedules = schedules.filter(
                (schedule) =>
                    !schedule.name.toLowerCase().includes('secondary'),
            );
            const scheduleIdTeamNameMapping = primarySchedules.reduce(
                (acc, schedule) => {
                    const {
                        id,
                        ownerTeam: { name },
                    } = schedule;
                    return {
                        ...acc,
                        [id]: name,
                    };
                },
                {},
            );
            const scheduleIds = Object.keys(scheduleIdTeamNameMapping);
            const promises = scheduleIds.map((scheduleId) =>
                instance.get(`/schedules/${scheduleId}/on-calls`),
            );
            const responses = await Promise.all(promises);
            return responses.reduce((oncalls, response) => {
                const { data } = response;
                const {
                    _parent: { id },
                    onCallParticipants,
                } = data.data;
                if (onCallParticipants.length === 0) {
                    return oncalls;
                }
                const [{ name: email }] = onCallParticipants;
                const teamName = scheduleIdTeamNameMapping[id];
                const teamOncall = emailFullNameMapping[email];
                return [...oncalls, { id, teamName, teamOncall }];
            }, []);
        },
    });
}
