//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import { ApiButton } from 'components/core/button';
import clientChecklistsFormObject from './ClientChecklistsFormObject';
import useUpdateClientChecklists from './useUpdateClientChecklists';
import Form from 'components/core/form';
import { ApiStatusEnum } from 'enums/API';
import { type ClientSettingsType } from 'models/apps/client';

type Props = {
    clientId: string,
    settings: ClientSettingsType,
};

const ClientChecklistsForm = (props: Props): React.Node => {
    const {
        clientId,
        settings: { defaultChecklists, transactionSettings },
    } = props;
    const formRef = React.useRef();
    const useUpdateClientChecklistsMutation = useUpdateClientChecklists();

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const onSubmit = (data) => {
        const {
            checklists: { defaultChecklists },
            transactionSettings,
            reason,
        } = data.formData;
        useUpdateClientChecklistsMutation.mutate({
            clientId,
            defaultChecklists,
            transactionSettings,
            reason,
        });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
        >
            <Form
                section={clientChecklistsFormObject}
                ref={formRef}
                onSubmit={onSubmit}
                initialValue={{
                    checklists: { defaultChecklists },
                    transactionSettings,
                }}
            />
            <ApiButton
                apiStatus={useUpdateClientChecklistsMutation.status}
                successMessage={'Client Checklists added/updated successfully'}
                errorMessage={useUpdateClientChecklistsMutation.error?.message}
                onClick={triggerClick}
                disabled={
                    useUpdateClientChecklistsMutation.status ===
                    ApiStatusEnum.Loading
                }
            />
        </Box>
    );
};

export default ClientChecklistsForm;
