// @flow
import { useMutation, type MutationResult } from 'react-query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';

type Variables = {
    clientId: string,
    defaultChecklists: $FixMe,
    transactionSettings: $FixMe,
    reason: string,
};

export default function useUpdateClientChecklists(): MutationResult<
    Variables,
    void,
> {
    return useMutation<Variables, void>(
        ({ clientId, defaultChecklists, transactionSettings, reason }) => {
            const pathLists = [
                {
                    path: 'defaultChecklists',
                    value: defaultChecklists,
                },
                {
                    path: 'transactionSettings',
                    value: transactionSettings,
                },
            ];
            return ClientSettingsUtils.fetchAndUpdateSettings(
                clientId,
                pathLists,
                reason,
            );
        },
    );
}
