//@flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { Modal } from 'components/core/modal';
import { Body1, Body2 } from 'components/core/typography';
import TextField from 'components/core/input';
import { EditButton, ApiButton } from 'components/core/button';
import ChipInput from 'components/core/chipInput';
import Chip from 'components/core/chip';

import { type ClientType } from 'models/apps/client/Client';
import useUpdateClientDomains from 'hooks/client/useUpdateClientDomains';

type Props = {
    client: ClientType,
};

const DomainsContainer = (props: Props): React.Node => {
    const { client } = props;
    const { domains } = client;
    const updateDomainsMutation = useUpdateClientDomains();
    const [draftDomains, setDraftDomains] = React.useState(domains);
    const [addedDomains, setAddedDomains] = React.useState([]);
    const [deletedDomains, setDeletedDomains] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [reason, setReason] = React.useState('');

    const openUpdateDomainsModal = () => {
        setOpen(true);
        resetState();
    };

    const resetState = () => {
        setDraftDomains(domains);
        setAddedDomains([]);
        setDeletedDomains([]);
        setReason('');
    };

    const onDomainAdd = (newDomain) => {
        const index = draftDomains.indexOf(newDomain);
        if (index === -1) {
            setAddedDomains(addedDomains.concat(newDomain));
            setDraftDomains(draftDomains.concat(newDomain));
        }
    };

    const onDomainDelete = (deletedDomain) => {
        setDraftDomains(
            draftDomains.filter((domain) => domain !== deletedDomain),
        );
        const isNewlyAdded = addedDomains.includes(deletedDomain);
        if (isNewlyAdded) {
            setAddedDomains(
                addedDomains.filter((domain) => domain !== deletedDomain),
            );
        } else {
            setDeletedDomains(deletedDomains.concat(deletedDomain));
        }
    };

    const onUpdateDomainsClick = () => {
        updateDomainsMutation.mutate({
            clientId: client.clientId,
            domainsToAdd: addedDomains,
            domainsToDelete: deletedDomains,
            reason,
        });
    };

    const handleClose = () => {
        updateDomainsMutation.reset();
        setDraftDomains(domains);
        setOpen(false);
    };

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Box flexGrow={1}>
                    <Body1>Domains</Body1>
                </Box>
                <Box>
                    <EditButton
                        size="small"
                        onClick={openUpdateDomainsModal}
                        customFontSize="12px"
                    />
                </Box>
            </Box>
            <Divider />
            <Box py={1}>
                {domains.map((domain) => (
                    <Chip key={domain} label={domain} />
                ))}
            </Box>

            <Modal open={open} title="Update Domains" onClose={handleClose}>
                <Box width="500px">
                    <Box pb={0.5}>
                        <Body2> Domains </Body2>
                    </Box>
                    <Box pb={1}>
                        <ChipInput
                            value={draftDomains}
                            onAdd={onDomainAdd}
                            onDelete={onDomainDelete}
                        />
                    </Box>
                    <Box pb={0.5}>
                        <Body2> Reason for change </Body2>
                    </Box>
                    <Box pb={1}>
                        <TextField
                            multiline
                            fullWidth
                            rows="4"
                            placeholder="Please provide the exact reason for this change.
                            This will be used for dashboard audit purposes"
                            onChange={setReason}
                        />
                    </Box>
                    <ApiButton
                        apiStatus={updateDomainsMutation.status}
                        errorMessage={updateDomainsMutation.error?.message}
                        successMessage="Domains updated successfully"
                        onClick={onUpdateDomainsClick}
                        disabled={isEmpty(reason)}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default DomainsContainer;
