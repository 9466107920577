//@flow

import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { type FormFieldType } from 'models/FormField';
import { type FormSectionType } from 'models/FormSection';
import { type FormArrayType } from 'models/FormArrayField';
import { type FormObjectType } from 'models/FormObjectField';
import { type FormDependency } from 'models/FormDependency';
import {
    type SchemaType,
    type OneOfDependencyType,
    type OneOfDependenciesType,
    type DependencyType,
    type PropertiesType,
} from 'models/Schema';
import { type UiSchemaType } from 'models/UiSchema';
import {
    FormFieldTypeEnum,
    type FormFieldTypeEnumType,
} from 'enums/FormFieldType';
import { FieldDependencyTypeEnum } from 'enums/FieldDependencyType';

class FormUtils {
    // Only includes properties with one to one mapping with field type.
    // Doesn't include properties like select, radio buttons etc.
    static propertyTypeMapping: { [string]: string } = {
        [FormFieldTypeEnum.TEXT_FIELD]: FormPropertyTypeEnum.STRING,
        [FormFieldTypeEnum.NUMBER_FIELD]: FormPropertyTypeEnum.NUMBER,
        [FormFieldTypeEnum.INTEGER_FIELD]: FormPropertyTypeEnum.INTEGER,
        [FormFieldTypeEnum.TEXT_AREA]: FormPropertyTypeEnum.STRING,
        [FormFieldTypeEnum.DATE]: FormPropertyTypeEnum.STRING,
        [FormFieldTypeEnum.DATETIME]: FormPropertyTypeEnum.STRING,
        [FormFieldTypeEnum.SWITCH]: FormPropertyTypeEnum.BOOLEAN,
        [FormFieldTypeEnum.CHECKBOX]: FormPropertyTypeEnum.BOOLEAN,
        [FormFieldTypeEnum.FILE]: FormPropertyTypeEnum.STRING,
    };

    static customFieldWidgetMapping: { [string]: string } = {
        [FormFieldTypeEnum.SWITCH]: 'SwitchWidget',
        [FormFieldTypeEnum.AUTOCOMPLETE]: 'AutocompleteWidget',
        [FormFieldTypeEnum.RADIO_BUTTONS]: 'RadioWidget',
        [FormFieldTypeEnum.TEXT_AREA]: 'TextareaWidget',
    };

    static buildSchemaJSON(section: FormSectionType): SchemaType {
        const { title, description, subSections } = section;
        const properties = FormUtils.buildPropertiesJSON(subSections);
        const requiredProperties: Array<string> = subSections.flatMap(
            (subSection) => FormUtils.fetchRequiredFields(subSection),
        );
        const dependencies = FormUtils.buildDependencies(subSections);

        return {
            title,
            description,
            required: requiredProperties,
            properties,
            section,
            dependencies,
        };
    }

    static buildDependencies(fields: Array<FormObjectType>): ?DependencyType {
        const dependencyList = FormUtils.flattenDependencyList(fields);
        const allDependencies = dependencyList?.reduce(
            (dependenciesObject, field) => {
                const {
                    dependency: { dependeeFieldId },
                } = field;
                const dependencyObject = FormUtils.buildDependencyObject(
                    dependenciesObject,
                    field,
                );
                return {
                    ...dependenciesObject,
                    [dependeeFieldId]: dependencyObject,
                };
            },
            {},
        );
        return FormUtils.dependenciesWithBaseOneOf(allDependencies);
    }

    // Add Base of condition - property with no dependendee objects
    static dependenciesWithBaseOneOf(
        allDependencies: ?DependencyType,
    ): ?DependencyType {
        return (
            allDependencies &&
            Object.keys(allDependencies).reduce(
                (dependencies, dependentFieldId) => {
                    const dependency = allDependencies[dependentFieldId];
                    const { oneOf } = dependency;
                    if (!oneOf) {
                        return {
                            ...dependencies,
                            [dependentFieldId]: dependency,
                        };
                    }
                    const allEnums = oneOf.reduce(
                        (allEnums, oneOfDepedency) => {
                            const { properties } = oneOfDepedency;
                            const enums = properties[dependentFieldId].enum;
                            return allEnums.concat(enums);
                        },
                        [],
                    );
                    const baseOneOfCondition = {
                        properties: {
                            [dependentFieldId]: { not: { enum: allEnums } },
                        },
                    };
                    return {
                        ...dependencies,
                        [dependentFieldId]: {
                            oneOf: oneOf.concat(baseOneOfCondition),
                        },
                    };
                },
                {},
            )
        );
    }

    static flattenDependencyList(
        fields: Array<FormObjectType>,
    ): ?Array<FormObjectType> {
        return fields.reduce((updatedFields, field) => {
            const dependentFields = FormUtils.buildDependentFields(field);
            return updatedFields.concat(dependentFields);
        }, []);
    }

    static buildDependentFields(field: FormObjectType): ?Array<FormObjectType> {
        switch (field.type) {
            case 'SECTION':
            case 'FIELD':
            case 'ARRAY': {
                return FormUtils.extractDependentFields(field);
            }
            case 'ROW':
            case 'COLUMN': {
                const { fields, dependency } = field;
                return fields.reduce((allDependentFields, field) => {
                    const fieldDependency = field.dependency || dependency;
                    if (!fieldDependency) {
                        return allDependentFields;
                    }
                    const dependentFields = FormUtils.buildDependentFields({
                        ...field,
                        dependency: fieldDependency,
                    });
                    return allDependentFields.concat(dependentFields);
                }, []);
            }
        }
    }

    static extractDependentFields(
        field: FormObjectType,
    ): Array<FormObjectType> {
        if (!field.dependency) {
            return [];
        }
        const { dependeeFieldValue } = field.dependency;
        if (!dependeeFieldValue) return [];
        if (dependeeFieldValue instanceof Array) {
            const splitDependentFields = dependeeFieldValue.map(
                (dependeeFieldValue) => ({
                    ...field,
                    dependency: {
                        ...field.dependency,
                        dependeeFieldValue,
                    },
                }),
            );
            return splitDependentFields;
        } else {
            return [field];
        }
    }

    static buildDependencyObject(
        allDependencies: ?DependencyType,
        field: FormObjectType,
    ): ?OneOfDependenciesType {
        const {
            dependency: { dependencyType, dependeeFieldId },
            dependency,
        } = field;
        const existingDependency = allDependencies[dependeeFieldId];
        switch (dependencyType) {
            case FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY: {
                const properties = FormUtils.specificValueDependencyProperties(
                    allDependencies,
                    field,
                );
                if (!existingDependency) {
                    return { oneOf: [{ properties }] };
                }
                return FormUtils.updateOneOfDependencies(
                    allDependencies,
                    dependency,
                    existingDependency.oneOf,
                    properties,
                );
            }
        }
    }

    static specificValueDependencyProperties(
        allDependencies: ?DependencyType,
        field: FormObjectType,
    ): PropertiesType {
        const {
            dependency: { dependeeFieldId, dependeeFieldValue },
        } = field;
        const dependencyObjectProperty = FormUtils.buildObjectProperty(field);
        return {
            ...dependencyObjectProperty,
            [dependeeFieldId]: {
                enum: [dependeeFieldValue],
            },
        };
    }

    static updateOneOfDependencies(
        allDependencies: ?DependencyType,
        dependency: FormDependency,
        oneOfDependencies: Array<OneOfDependencyType>,
        properties: PropertiesType,
    ): OneOfDependenciesType {
        const { dependeeFieldId, dependeeFieldValue } = dependency;
        const oneOfDependencyIndex = oneOfDependencies.findIndex((oneOf) => {
            const oneOfEnums = oneOf.properties[dependeeFieldId].enum;
            return oneOfEnums[0] === dependeeFieldValue;
        });
        // Same Dependee Field but different dependee field values
        if (oneOfDependencyIndex === -1) {
            return {
                oneOf: oneOfDependencies.concat({
                    properties,
                }),
            };
        }
        // Same Dependee Field with Same dependee Field Value
        return {
            oneOf: oneOfDependencies.map((oneOf, index) => {
                if (index === oneOfDependencyIndex) {
                    return {
                        properties: {
                            ...oneOf.properties,
                            ...properties,
                        },
                    };
                }
                return { properties: oneOf.properties };
            }, []),
        };
    }

    static buildPropertiesJSON(fields: Array<FormObjectType>): PropertiesType {
        return fields.reduce((properties: PropertiesType, field) => {
            if (field.dependency) {
                return properties;
            }
            const objectProperties = FormUtils.buildObjectProperty(field);
            return { ...properties, ...objectProperties };
        }, {});
    }

    static buildObjectProperty(field: FormObjectType): ?PropertiesType {
        switch (field.type) {
            case 'SECTION':
            case 'FIELD':
            case 'ARRAY': {
                const { id } = field;
                return { [id]: FormUtils.buildFieldsProperty(field) };
            }
            case 'ROW':
            case 'COLUMN': {
                const { fields } = field;
                return fields.reduce(
                    (allFieldProperties: PropertiesType, field) => {
                        if (field.dependency) {
                            return allFieldProperties;
                        }
                        const propertyObject = FormUtils.buildObjectProperty(
                            field,
                        );
                        return { ...allFieldProperties, ...propertyObject };
                    },
                    {},
                );
            }
        }
    }

    static buildFieldsProperty(field: FormObjectType): ?SchemaType {
        switch (field.type) {
            case 'SECTION':
                return {
                    type: 'object',
                    ...FormUtils.buildSchemaJSON(field),
                };
            case 'FIELD':
                return FormUtils.buildPropertyJSON(field);
            case 'ARRAY':
                return FormUtils.buildArrayFieldProperty(field);
        }
    }

    static fetchRequiredFields(field: FormObjectType): Array<string> {
        switch (field.type) {
            case 'FIELD': {
                const { id } = field;
                return field.required ? [id] : [];
            }
            case 'COLUMN':
            case 'ROW': {
                const { fields } = field;
                return fields.flatMap((field) =>
                    FormUtils.fetchRequiredFields(field),
                );
            }
        }
        return [];
    }

    static buildArrayFieldProperty(field: FormArrayType): SchemaType {
        const { title, minItems, maxItems, description, items } = field;
        return {
            type: 'array',
            title,
            items: FormUtils.buildFieldsProperty(items),
            minItems,
            maxItems,
            description,
        };
    }

    static buildPropertyJSON(field: FormFieldType): SchemaType {
        const {
            reference,
            fieldType,
            title,
            description,
            additionalProperties,
        } = field;

        if (reference) {
            return { $ref: reference };
        }

        const additionalOptions = this.formAdditionalOptions(field, fieldType);
        const propertyType = this.fetchPropertyType(field, fieldType);
        const property = {
            type: propertyType,
            title,
            description,
            ...additionalOptions,
            ...additionalProperties,
        };
        return property;
    }

    static fetchPropertyType(
        field: FormFieldType,
        fieldType: FormFieldTypeEnumType,
    ): string {
        if (fieldType in this.propertyTypeMapping) {
            return this.propertyTypeMapping[fieldType];
        }

        if (this.isEnumType(fieldType)) {
            const {
                options: { itemsType },
            } = field;
            return itemsType;
        }
        // Fallback Property type
        return FormPropertyTypeEnum.STRING;
    }

    static isEnumType(fieldType: FormFieldTypeEnumType): boolean {
        return (
            fieldType === FormFieldTypeEnum.RADIO_BUTTONS ||
            fieldType === FormFieldTypeEnum.SELECT ||
            fieldType === FormFieldTypeEnum.AUTOCOMPLETE
        );
    }

    static formAdditionalOptions(
        field: FormFieldType,
        type: FormFieldTypeEnumType,
    ): Object {
        switch (type) {
            case FormFieldTypeEnum.RADIO_BUTTONS:
            case FormFieldTypeEnum.SELECT:
            case FormFieldTypeEnum.AUTOCOMPLETE:
                return this.buildSelectOptions(field);
            case FormFieldTypeEnum.DATE:
                return { format: 'date' };
            case FormFieldTypeEnum.DATETIME:
                return { format: 'datetime' };
            case FormFieldTypeEnum.FILE:
                return { format: 'data-url' };
            default:
                return {};
        }
    }

    static buildSelectOptions(field: FormFieldType): Object {
        const {
            options: { items },
        } = field;
        const enumOptions = { enum: [], enumNames: [] };
        items.forEach((option) => {
            const { label, value } = option;
            enumOptions.enum.push(value);
            enumOptions.enumNames.push(label);
        });
        return enumOptions;
    }

    static buildUISchema(section: FormSectionType): UiSchemaType {
        const { subSections } = section;
        const uiSchema = subSections.reduce(
            (uiSchema: UiSchemaType, subSection): UiSchemaType => {
                const fieldUISchemaObject = FormUtils.buildObjectUISchema(
                    subSection,
                );
                return { ...uiSchema, ...fieldUISchemaObject };
            },
            {},
        );
        return uiSchema;
    }

    static buildObjectUISchema(section: FormObjectType): ?UiSchemaType {
        switch (section.type) {
            case 'SECTION':
            case 'ARRAY':
            case 'FIELD': {
                const { id } = section;
                return { [id]: FormUtils.buildFieldUISchemaObject(section) };
            }
            case 'ROW':
            case 'COLUMN': {
                const { fields } = section;
                return fields.reduce(
                    (fieldUISchema: UiSchemaType, field): UiSchemaType => {
                        const objectUISchema = FormUtils.buildObjectUISchema(
                            field,
                        );
                        return {
                            ...fieldUISchema,
                            ...objectUISchema,
                        };
                    },
                    {},
                );
            }
        }
    }

    static buildFieldUISchemaObject(section: FormObjectType): ?UiSchemaType {
        switch (section.type) {
            case 'SECTION': {
                return FormUtils.buildUISchema(section);
            }
            case 'FIELD': {
                return FormUtils.buildPropertyUIOptions(section);
            }
            case 'ARRAY': {
                const { items } = section;
                return FormUtils.buildFieldUISchemaObject(items);
            }
        }
        return {};
    }

    static buildPropertyUIOptions(property: FormFieldType): UiSchemaType {
        const { fieldType, helpText, uiOptions } = property;
        if (fieldType in this.customFieldWidgetMapping) {
            return {
                'ui:options': {
                    ...uiOptions,
                    widget: this.customFieldWidgetMapping[fieldType],
                },
                'ui:help': helpText,
            };
        }
        return { 'ui:options': uiOptions, 'ui:help': helpText };
    }

    static buildInitialFormData(section: FormSectionType): Object {
        const { subSections } = section;
        const formData = subSections.reduce((formData, subSection) => {
            const objectInitialValue = FormUtils.buildObjectInitialValue(
                subSection,
            );
            return { ...formData, ...objectInitialValue };
        }, {});
        return formData;
    }

    static buildObjectInitialValue(formObject: FormObjectType): Object {
        switch (formObject.type) {
            case 'SECTION': {
                const { id } = formObject;
                return { [id]: FormUtils.buildInitialFormData(formObject) };
            }
            case 'FIELD': {
                const { id } = formObject;
                return { [id]: FormUtils.buildFieldInitialValue(formObject) };
            }
            case 'ROW':
            case 'COLUMN': {
                const { fields } = formObject;
                return fields.reduce((fieldInitialValues, field) => {
                    const initialObjectValues = FormUtils.buildObjectInitialValue(
                        field,
                    );
                    return { ...fieldInitialValues, ...initialObjectValues };
                }, {});
            }
        }
    }

    static buildFieldInitialValue(field: FormFieldType): Object {
        const { initialValue, fieldType, required } = field;
        if (initialValue) {
            return initialValue;
        }

        // For Required Select/Radio Field set first value as initial value
        if (this.isEnumType(fieldType) && required) {
            const {
                options: { items },
            } = field;
            return items[0]?.value;
        }

        const propertyType = this.propertyTypeMapping[fieldType];
        if (propertyType === FormPropertyTypeEnum.BOOLEAN) {
            return false;
        }
        return undefined;
    }
}

export default FormUtils;
