//@flow

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Link } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

import { ApiButton } from 'components/core/button';
import Loader from 'components/core/loader';
import TextField from 'components/core/input';
import { Body1, H3 } from 'components/core/typography';
import ErrorContainer from 'containers/ErrorContainer';
import VaeConfigForm from 'containers/configure-client/vae-config/VaeConfigForm';
import { ApiStatusEnum } from 'enums/API';

import { useClientSettings, useUpdateVaeConfig } from 'hooks/client';
import VaeConfigUtils from 'utils/VaeConfigUtils';
import { history } from 'store/configureStore';

type Props = {
    configuration: $FixMe,
};
const VaeConfigBase = (props: Props) => {
    const { configuration } = props;
    const formRef = React.useRef();
    const [reason, setReason] = React.useState('');
    const [value, onChange] = React.useState(() => ({
        configuration,
        useCase: VaeConfigUtils.getUseCase(configuration),
    }));
    const [defaultConfiguration, setDefaultConfiguration] = React.useState(
        configuration,
    );
    const [defaultUsecase, setDefaultUsecase] = React.useState(
        VaeConfigUtils.getUseCase(configuration),
    );
    const { clientId } = useParams();
    const updateVaeConfigMutation = useUpdateVaeConfig();

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const onSubmit = () => {
        const { configuration } = value;
        updateVaeConfigMutation.mutate({
            clientId,
            vaeConfig: configuration,
            reason,
        });
        setDefaultConfiguration(value.configuration);
        setDefaultUsecase(value.useCase);
    };

    return (
        <>
            <H3 align="center">VAE Config</H3>
            <VaeConfigForm
                ref={formRef}
                value={value}
                onChange={onChange}
                onSubmit={onSubmit}
                defaultConfiguration={defaultConfiguration}
                defaultUsecase={defaultUsecase}
            />
            <Box py={1} width="100%">
                <Body1>Reason</Body1>
                <TextField
                    multiline
                    fullWidth
                    value={reason}
                    rows="4"
                    placeholder="Please provide the exact reason for this change. This will be used for dashboard audit purposes"
                    onChange={setReason}
                />
            </Box>
            <ApiButton
                apiStatus={updateVaeConfigMutation.status}
                successMessage="VAE Config updated successfully"
                errorMessage={updateVaeConfigMutation.error?.message}
                onClick={triggerClick}
                disabled={isEmpty(reason)}
            />
        </>
    );
};

const VaeConfig = (): React.Node => {
    const { clientId } = useParams();
    const settingsQuery = useClientSettings(clientId);

    const renderTabContent = (vaeConfiguration) => {
        return vaeConfiguration ? (
            <VaeConfigBase configuration={vaeConfiguration} />
        ) : (
            <>
                <Body1>No VAE config found to update</Body1>
                <Link
                    component="button"
                    variant="body1"
                    onClick={() => {
                        history.push(`/client/${clientId}/configure`);
                    }}
                >
                    Add VAE Config
                </Link>
            </>
        );
    };

    return (
        <Box width="100%" p={1}>
            {settingsQuery.status === ApiStatusEnum.Loading && <Loader />}
            {settingsQuery.status === ApiStatusEnum.Error && (
                <ErrorContainer message={settingsQuery.error.message} />
            )}
            {settingsQuery.status === ApiStatusEnum.Success &&
                renderTabContent(settingsQuery.data.vaeConfiguration)}
        </Box>
    );
};

export default VaeConfig;
