//@flow

import * as React from 'react';

import { PaginatedTable } from 'components/core/table';
import { useToast } from 'containers/toast/Toast';
import { allQueueMessagesColumns } from './AllMessagesColumns';
import useDeleteMessage from './useDeleteMessage';
import invariant from 'utils/invariant';
import { type Messages } from './Messages.type';

type Props = {
    data: Messages,
    onSuccess: () => void,
    value: ?{
        queueName: string,
        namespace: string,
    },
};

const QueueMessagesTable = ({ data, value, onSuccess }: Props): React.Node => {
    const { showToast } = useToast();
    const deleteMessageMutation = useDeleteMessage();
    const onDeleteClick = React.useCallback(
        (messageId) => {
            invariant(value, 'Expected queue name and namespace to be present');
            const { queueName, namespace } = value;
            deleteMessageMutation.mutate(
                {
                    namespace,
                    queueName,
                    messagesIds: [messageId],
                },
                {
                    onSuccess: () => {
                        showToast(
                            `Message with id ${messageId} deleted from queue`,
                        );
                        onSuccess();
                    },
                    onError: (err) => {
                        showToast(err.message);
                    },
                },
            );
        },
        [deleteMessageMutation, onSuccess, showToast, value],
    );
    return (
        <PaginatedTable
            idField="messageId"
            data={data}
            columns={allQueueMessagesColumns(onDeleteClick)}
        />
    );
};

export default QueueMessagesTable;
