//@flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import AppHeader from 'containers/app-header/AppHeader';
import AppRoutes from 'containers/routes/AppRoutes';
import Toast from 'containers/toast/Toast';

const useStyles = makeStyles(() => {
    const headerHeight = '56px';
    return {
        headerWrap: {
            height: headerHeight,
        },
        mainContainerWrap: {
            width: '100%',
            height: `calc(100vh - ${headerHeight})`,
        },
    };
});

const AppContainer = (props): React.Node => {
    const classes = useStyles();
    return (
        <Box>
            <Box className={classes.headerWrap}>
                <AppHeader />
            </Box>
            <Box className={classes.mainContainerWrap}>
                <AppRoutes {...props} />
            </Box>
            <Toast />
        </Box>
    );
};

export default AppContainer;
