//@flow

import * as React from 'react';
import { type RouteComponentProps } from 'react-router';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import NavigationBar from 'components/core/navigation-bar';
import Loader from 'components/core/loader';
import ClientTabRoutes from 'containers/client/routes/ClientTabRoutes';
import SideBar from 'containers/client/side-bar/SideBar';
import { ApiStatusEnum } from 'enums/API';
import ErrorContainer from 'containers/ErrorContainer';

import useClient from 'hooks/client/useClient';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        clientRightSideBar: {
            width: '100%',
            minHeight: '100%',
        },
        clientMainContainerWrap: {
            width: '100%',
            height: '100%',
        },
        sideBar: {
            borderRight: `1px solid ${grey[200]}`,
        },
        navigationWrap: {
            borderBottom: `1px solid ${grey[200]}`,
        },
    };
});

const homePageNavLink = {
    id: 'home',
    link: `/`,
    label: 'Home',
};

type Props = {
    ...RouteComponentProps,
};

export const ClientContainer = (props: Props): React.Node => {
    const { clientId } = props.match.params;
    const classes = useStyles();
    const clientQuery = useClient(clientId);

    if (clientQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box display="flex" flexDirection="column" height="100%">
            {clientQuery.status === ApiStatusEnum.Success && (
                <>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        px={10}
                        py={1}
                        className={classes.navigationWrap}
                    >
                        <NavigationBar
                            navLinks={[
                                homePageNavLink,
                                {
                                    id: 'clientPage',
                                    link: `/client/${clientId}`,
                                    label: clientQuery.data.name,
                                },
                            ]}
                        />
                    </Box>
                    <Box
                        className={classes.clientMainContainerWrap}
                        display="flex"
                        flexDirection="row"
                    >
                        <SideBar client={clientQuery.data} />
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={classes.clientRightSideBar}
                            height="100%"
                        >
                            <ClientTabRoutes {...props} />
                        </Box>
                    </Box>
                </>
            )}
            {clientQuery.status === ApiStatusEnum.Error && (
                <ErrorContainer message={clientQuery.error.message} />
            )}
        </Box>
    );
};

export default ClientContainer;
