//@flow
import * as React from 'react';
import Box from '@material-ui/core/Box';
import Form from 'components/core/form';
import FormObject, {
    serviceTypes,
    customMethodOption,
    loggingDuration,
} from './FormObject';
import useFetchMethods from './useFetchMethods';
import useUpdatePostBodyLogging from './useUpdatePostBodyLogging';
import { useParams } from 'react-router-dom';
import { ApiButton } from 'components/core/button';
import Loader from 'components/core/loader';

type FormData = {
    methodName: string,
    serviceType: string,
    loggingDuration: string,
    customMethod: string,
};
const initialFormData: FormData = {
    methodName: '',
    customMethod: undefined,
    serviceType: serviceTypes[0].value,
    loggingDuration: loggingDuration[0].value,
};

const PostBodyLogger = () => {
    const formRef = React.useRef();
    const { clientId } = useParams();
    const [formData, setFormData] = React.useState<FormData>(initialFormData);

    const { data } = useFetchMethods({
        clientId,
        service: formData.serviceType,
    });
    const updatePostBodyLoggingMutation = useUpdatePostBodyLogging();

    const methods = React.useMemo(() => {
        const _methods =
            data?.methods.map((item, index) => {
                return {
                    id: index.toString(),
                    label: item,
                    value: item,
                };
            }) ?? [];
        _methods.push(customMethodOption);
        return _methods;
    }, [data]);

    const section = React.useMemo(() => {
        const section: any = FormObject.subSections.find(
            (item) => item.id === 'methodName',
        );
        if (section) {
            section.options.items = methods;
        }
        return JSON.parse(JSON.stringify(FormObject)); // deep clone
    }, [methods]);

    const triggerClick = React.useCallback(() => {
        formRef.current?.submit();
    }, []);

    const onSubmit = React.useCallback(
        ({ formData }) => {
            const obj = {};
            if (formData.methodName === customMethodOption.value) {
                obj['custom-method'] = formData.customMethod;
            }
            updatePostBodyLoggingMutation.mutate({
                service: formData.serviceType,
                clientId,
                payload: {
                    method: formData.methodName,
                    durationMins: formData.loggingDuration,
                    ...obj,
                },
            });
        },
        [clientId, updatePostBodyLoggingMutation],
    );

    return (
        <>
            <Box width="100%" flex="1 1 0">
                {section ? (
                    <Form
                        ref={formRef}
                        section={section}
                        onSubmit={onSubmit}
                        onChange={setFormData}
                        value={formData}
                    >
                        <ApiButton
                            apiStatus={updatePostBodyLoggingMutation.status}
                            successMessage="Post body logging Enabled."
                            errorMessage={
                                updatePostBodyLoggingMutation.error?.message
                            }
                            onClick={triggerClick}
                        />
                    </Form>
                ) : (
                    <Loader />
                )}
            </Box>
        </>
    );
};

export default PostBodyLogger;
