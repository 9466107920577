//@flow

import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';

import TextField from 'components/core/input';
import JSONEditor from 'components/core/json-editor';
import { ApiButton } from 'components/core/button';
import { Body1 } from 'components/core/typography';
import { useUpdateTeamSettings } from 'hooks/team';
import { useToast } from 'containers/toast/Toast';
import { JsonEditorModeEnum } from 'enums/JsonEditorMode';
import JSONUtils from 'utils/JSONUtils';
import { type TeamSettingsType } from 'models/apps/team';

type Props = { clientId: string, teamId: string, settings: TeamSettingsType };

const UpdateTeamSettingsForm = (props: Props): React.Node => {
    const { clientId, teamId, settings } = props;
    const [value, setValue] = React.useState(settings);
    const [reason, setReason] = React.useState('');
    const { showToast } = useToast();

    const updateTeamSettingsMutation = useUpdateTeamSettings();

    const onClick = () => {
        updateTeamSettingsMutation.mutate({
            clientId,
            teamId,
            settings: value,
            reason,
        });
    };
    const onChangeText = (textValue) => {
        const isValid = JSONUtils.isValidJSON(textValue);
        if (isValid) {
            const jsonValue = JSON.parse(textValue);
            setValue(jsonValue);
        } else {
            showToast('Invalid Config set in Text editor');
        }
    };

    return (
        <>
            <Box py={1} width="100%" maxHeight="500px">
                <JSONEditor
                    mode={JsonEditorModeEnum.TREE}
                    modes={[JsonEditorModeEnum.TREE, JsonEditorModeEnum.TEXT]}
                    value={value}
                    onChangeJSON={(config) => setValue(config)}
                    onChangeText={onChangeText}
                />
            </Box>
            <Box py={1} width="100%">
                <Body1>Reason</Body1>
                <TextField
                    multiline
                    fullWidth
                    value={reason}
                    rows="4"
                    placeholder="Please provide the exact reason for this change. This will be used for dashboard audit purposes"
                    onChange={setReason}
                />
            </Box>
            <ApiButton
                apiStatus={updateTeamSettingsMutation.status}
                successMessage="Team Settings updated successfully"
                errorMessage={updateTeamSettingsMutation.error?.message}
                onClick={onClick}
                disabled={isEmpty(reason)}
            />
        </>
    );
};

export default UpdateTeamSettingsForm;
