// @flow
import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import startCase from 'lodash/startCase';
import Box from '@material-ui/core/Box';

import TextField from 'components/core/input';
import { ApiButton } from 'components/core/button';
import { Body1 } from 'components/core/typography';
import TransferList from 'components/core/transferList';

import { useUpdateClientMastersState } from 'hooks/client';

type Props = {
    clientId: string,
    clientSettings: $FixMe,
};

const MastersStateForm = (props: Props): React.Node => {
    const { clientId } = props;
    const [reason, setReason] = React.useState('');
    const [value, setValue] = React.useState(() => {
        const { entitySettings } = props.clientSettings;
        if (!entitySettings) {
            return {
                left: [],
                right: [],
            };
        }

        return Object.keys(entitySettings).reduce(
            (acc, id) => {
                const { enabled } = entitySettings[id];

                if (isUndefined(enabled)) {
                    return acc;
                }

                const { left, right } = acc;
                const entityId = id.replace(/Settings\b/, '');
                const updatedEntity = {
                    id,
                    value: startCase(entityId),
                    checked: false,
                };

                if (enabled) {
                    return {
                        left: [...left, updatedEntity],
                        right,
                    };
                }

                return {
                    left,
                    right: [...right, updatedEntity],
                };
            },
            {
                left: [],
                right: [],
            },
        );
    });

    const updateMastersStateMutation = useUpdateClientMastersState();

    const saveMastersState = () => {
        updateMastersStateMutation.mutate({
            clientId,
            enabledList: value.left,
            disabledList: value.right,
            reason,
        });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="500px"
        >
            <Box py={1}>
                <TransferList value={value} onChange={setValue} />
            </Box>
            <Box py={1} width="100%">
                <Body1>Reason</Body1>
                <TextField
                    multiline
                    fullWidth
                    value={reason}
                    rows="4"
                    placeholder="Please provide the exact reason for this change. This will be used for dashboard audit purposes"
                    onChange={setReason}
                />
            </Box>
            <ApiButton
                apiStatus={updateMastersStateMutation.status}
                successMessage="State updated successfully"
                errorMessage={updateMastersStateMutation.error?.message}
                onClick={saveMastersState}
                disabled={isEmpty(reason)}
            />
        </Box>
    );
};

export default MastersStateForm;
