// @flow

import { type ActionType } from 'models/redux';
import { type ToastTypeEnumType } from 'models/Toast';

export class ToastActions {
    static SHOW_TOAST: string = 'SHOW_TOAST';
    static HIDE_TOAST: string = 'HIDE_TOAST';

    static showToast(
        message: string,
        type?: ToastTypeEnumType,
        toastDuration?: number,
    ): ActionType {
        const toast = { message, type, toastDuration };
        return {
            type: ToastActions.SHOW_TOAST,
            payload: toast,
        };
    }

    static hideToast(): ActionType {
        return {
            type: ToastActions.HIDE_TOAST,
        };
    }
}
