//@flow

import padStart from 'lodash/padStart';

class DateTimeUtils {
    static localToUTC(dateString: string): string {
        return new Date(dateString).toJSON();
    }

    static utcToLocal(dateString: string): string {
        const date = new Date(dateString);

        const yyyy = padStart(date.getFullYear().toString(), 4, '0');
        const MM = padStart((date.getMonth() + 1).toString(), 2, '0');
        const dd = padStart(date.getDate().toString(), 2, '0');
        const hh = padStart(date.getHours().toString(), 2, '0');
        const mm = padStart(date.getMinutes().toString(), 2, '0');
        const ss = padStart(date.getSeconds().toString(), 2, '0');
        const SSS = padStart(date.getMilliseconds().toString(), 3, '0');

        return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}.${SSS}`;
    }
}

export default DateTimeUtils;
