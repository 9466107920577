// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import BackgroundImage from 'images/404.png';

const useStyles = makeStyles({
    noMatchContainer: {
        backgroundImage: `url(${BackgroundImage})`,
        height: '90vh',
    },
});

const NoMatchContainer = () => {
    const classes = useStyles();
    return <div className={classes.noMatchContainer} />;
};

export default NoMatchContainer;
