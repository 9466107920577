//@flow

import * as React from 'react';
import { type Match } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';

import { history } from 'store/configureStore';
import Stepper from 'components/core/stepper';
import Loader from 'components/core/loader';
import { H3 } from 'components/core/typography';
import ErrorContainer from 'containers/ErrorContainer';
import NavigationBar from 'components/core/navigation-bar';
import { ApiStatusEnum } from 'enums/API';

import TaskProfile from './task-profile/TaskProfile';
import DashSettings from './dash-settings/DashSettings';
import ClientSettings from './client-settings/ClientSettings';
import VaeConfig from './vae-config/VaeConfig';
import useClient from 'hooks/client/useClient';

const steps = [
    {
        label: 'Create Task Profile',
        id: 'createTaskProfile',
        component: TaskProfile,
    },
    {
        label: 'Set VAE Config',
        id: 'setVAEConfig',
        component: VaeConfig,
    },
    {
        label: 'Dash Settings',
        id: 'dashSettings',
        component: DashSettings,
    },
    {
        label: 'Client Settings',
        id: 'clientSettings',
        component: ClientSettings,
    },
];

const homePageNavLink = {
    id: 'home',
    link: `/`,
    label: 'Home',
};

type Props = {
    match: Match,
};

export const ConfigureClient = (props: Props): React.Node => {
    const {
        match: {
            params: { clientId },
        },
    } = props;

    const [activeStep, setActiveStep] = React.useState(0);
    const { component: Component } = steps[activeStep];

    const configureClientPageNavLink = {
        id: 'configureClient',
        link: `/client/${clientId}/configure`,
        label: 'Configure Client',
    };
    const navLinks = [homePageNavLink, configureClientPageNavLink];

    const onNextClick = () => {
        setActiveStep(activeStep + 1);
    };

    const navigateToClientPage = () => {
        history.push(`/client/${clientId}`);
    };

    const clientQuery = useClient(clientId);

    if (clientQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box display="flex" flexDirection="column" height="100%">
            {clientQuery.status === ApiStatusEnum.Success && (
                <>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        ml={10}
                        p={1}
                    >
                        <NavigationBar navLinks={navLinks} />
                    </Box>
                    <Paper variant="outlined">
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            px={10}
                        >
                            <Box width="100%">
                                <H3> {clientQuery.data.name} </H3>
                            </Box>
                            <Box width="100%" minWidth="700px">
                                <Stepper
                                    steps={steps}
                                    activeStepIndex={activeStep}
                                />
                            </Box>
                        </Box>
                    </Paper>
                    <Box pb={7}>
                        <Component
                            clientId={clientId}
                            onNextClick={onNextClick}
                            finish={navigateToClientPage}
                        />
                    </Box>
                </>
            )}
            {clientQuery.status === ApiStatusEnum.Error && (
                <ErrorContainer message={clientQuery.error.message} />
            )}
        </Box>
    );
};

export default ConfigureClient;
