//@flow

export const FieldDependencyTypeEnum = Object.freeze({
    // dependent field will be shown based on dependee field value
    SPECIFIC_VALUE_DEPENDENCY: 'SPECIFIC_VALUE_DEPENDENCY',
});

export type FieldDependencyTypeEnumTypes = $Values<
    typeof FieldDependencyTypeEnum,
>;
