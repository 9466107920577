//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import Loader from 'components/core/loader';
import { H3, Body1 } from 'components/core/typography';
import { useBusinessUnits } from 'hooks/businessUnit';
import { useClientId } from 'hooks/client';
import { ApiStatusEnum } from 'enums/API';
import BUSelectorForm from './BUSelectorForm';

const BUSettings = (): React.Node => {
    const clientId = useClientId();
    const businessUnitsQuery = useBusinessUnits(clientId);
    if (businessUnitsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            //Hack to allow min height as full height of container
            minHeight="70vh"
        >
            <Box p={1}>
                <H3> Business Unit Settings </H3>
            </Box>
            {businessUnitsQuery.status === ApiStatusEnum.Error ? (
                <Box pb={1} width="100%">
                    <Alert severity="error">
                        {businessUnitsQuery.error.message}
                    </Alert>
                </Box>
            ) : businessUnitsQuery.data.businessUnits.length === 0 ? (
                <Body1> No Business Units Found</Body1>
            ) : (
                <BUSelectorForm
                    clientId={clientId}
                    businessUnits={businessUnitsQuery.data.businessUnits}
                />
            )}
        </Box>
    );
};

export default BUSettings;
