// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { useAuth0 } from 'auth/AuthHelper';
import { LoaderWithBackdrop } from 'components/core/loader';
import Background from 'images/background.png';
import { H1, Body1 } from 'components/core/typography';
import Button from 'components/core/button';
import { Modal } from 'components/core/modal';

const useStyles = makeStyles(() => ({
    appLoginView: {
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        height: '100vh',
        backgroundPositionY: 'top',
        backgroundPositionX: 'right',
    },
    appLoginViewBoxWrap: {
        paddingTop: '150px',
        textAlign: 'center',
        gap: '8px',
    },
    appLoginButton: {
        width: '300px',
    },
    actionsContainer: {
        paddingTop: '16px',
        gap: '8px',
    },
    loginTextWrap: {
        color: '#ffffff',
    },
}));

const AppLoginView = (): React.Node => {
    const classes = useStyles();
    const { error, loading, loginWithRedirect, logout } = useAuth0();
    const [open, setOpen] = React.useState(!!error);

    if (loading) {
        return <LoaderWithBackdrop />;
    }

    return (
        <div className={classes.appLoginView}>
            <Box
                className={classes.appLoginViewBoxWrap}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <H1 className={classes.loginTextWrap}>
                    Locus Client Management Dashboard
                </H1>
                <Body1 className={classes.loginTextWrap}>
                    Launch and Modify customer accounts better,
                    <br /> with the new - upgraded Internal Dashboard
                </Body1>
                <Button
                    className={classes.appLoginButton}
                    color="primary"
                    onClick={loginWithRedirect}
                >
                    Login
                </Button>
                {error && (
                    <Modal
                        open={open}
                        title={error.title}
                        onClose={() => setOpen(false)}
                    >
                        <Box
                            width="400px"
                            display="flex"
                            flexDirection="column"
                        >
                            {error.description}
                            <Box
                                display="flex"
                                flexDirection="row"
                                className={classes.actionsContainer}
                            >
                                <Button
                                    color="secondary"
                                    onClick={loginWithRedirect}
                                    fullWidth
                                >
                                    Try Again
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={logout}
                                    fullWidth
                                >
                                    Logout
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                )}
            </Box>
        </div>
    );
};

export default AppLoginView;
