// @flow
import { useMutation, type MutationResult } from 'react-query';

import { platformInstance } from 'middleware/axios';

type Variables = {
    clientId: string,
    duration: number,
};

export default function useRequestClientWriteAccess(): MutationResult<
    Variables,
    void,
> {
    return useMutation<Variables, void>(({ clientId, duration }) => {
        return platformInstance.get(`/write-access`, {
            params: {
                clientId,
                duration,
            },
        });
    });
}
