// @flow

import { useQuery, type QueryResult } from 'react-query';

import TeamSettingsUtils from 'utils/TeamSettingsUtils';
import { type TeamSettingsType } from 'models/apps/team';

type Params = {
    clientId: string,
    teamId: string,
};
export default function useTeamSettings(
    params: Params,
): QueryResult<TeamSettingsType> {
    const { clientId, teamId } = params;
    return useQuery({
        queryKey: ['clients', clientId, 'team', teamId, 'settings'],
        queryFn: () => {
            return TeamSettingsUtils.fetchSettings(clientId, teamId);
        },
    });
}
