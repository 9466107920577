// @flow
import { useMutation, type MutationResult } from 'react-query';

import { apiInstance } from 'middleware/axios';

import { type ClientType } from 'models/apps/client/Client';

type Variables = {
    clientId: string,
    client: ClientType,
};

export default function useCreateClient(): MutationResult<
    Variables,
    ClientType,
> {
    return useMutation<Variables, ClientType>(({ clientId, client }) => {
        return apiInstance
            .put(`client/${clientId}`, client)
            .then((response) => {
                return response.data;
            });
    });
}
