// @flow

import { useQuery, type QueryResult } from 'react-query';

import { apiInstance } from 'middleware/axios';

type Params = {
    clientId: string,
    businessUnitId: string,
};

type BuSettingsType = {
    payload: string,
};

export default function useBUSettings(
    params: Params,
): QueryResult<BuSettingsType> {
    const { clientId, businessUnitId } = params;
    return useQuery({
        queryKey: [
            'clients',
            clientId,
            'businessUnit',
            businessUnitId,
            'settings',
        ],
        queryFn: () => {
            return apiInstance
                .get(
                    `/client/${clientId}/businessUnit/${businessUnitId}/settings`,
                )
                .then((response) => {
                    return response.data;
                });
        },
    });
}
