//@flow

import * as React from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

import { history } from 'store/configureStore';
import { setAuthHeader } from 'middleware/axios';
import config from 'config';
import AuthContext from 'auth/AuthContext';
import queryString from 'query-string';

type Props = {
    children: React.Node,
};

export type AppStateType = {
    targetUrl: string,
};

export type LoginWithRedirectType = ({ fragment?: string }) => Promise<any>;

export type GetTokenSilentlyType = (options?: {
    ignoreCache?: boolean,
}) => Promise<string>;

export type HandleRedirectCallbackType = () => Promise<{
    appState: AppStateType,
}>;

export type Auth0ClientType = {
    logout: (options: {
        returnTo?: string,
    }) => void,
    loginWithRedirect: LoginWithRedirectType,
    handleRedirectCallback: HandleRedirectCallbackType,
    isAuthenticated: () => boolean,
    getTokenSilently: GetTokenSilentlyType,
};

const onRedirectCallback = (appState: AppStateType) => {
    window.history.replaceState({}, '', window.location.pathname);
    if (appState?.targetUrl) {
        history.push(appState.targetUrl);
    }
};

const { auth0: Auth0Config } = config;
const { origin: redirectUri } = window.location;

export const AuthProvider = (props: Props) => {
    const { children } = props;
    const [isAuthenticated, setIsAuthenticated] = React.useState();
    const [authClient, setAuthClient] = React.useState<Auth0ClientType>();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            let auth0Client;
            try {
                auth0Client = await createAuth0Client({
                    login_hint: Auth0Config.loginHint,
                    domain: Auth0Config.domain,
                    client_id: Auth0Config.clientId,
                    audience: Auth0Config.audience,
                    redirect_uri: redirectUri,
                });
            } catch (error) {
                onErrorCallback(error);
                return;
            }
            const searchParams = new URLSearchParams(window.location.search);
            if (
                (searchParams.has('code') && searchParams.has('state')) ||
                searchParams.has('error')
            ) {
                await handleUrlRedirect(auth0Client, onRedirectCallback);
            }
            await fetchAndSetAuthData(auth0Client);
            setAuthClient(auth0Client);
            setLoading(false);
        })();
    }, []);

    const fetchAndSetAuthData = async (
        auth0Client: Auth0ClientType,
    ): Promise<void> => {
        const isAuthenticated = await auth0Client.isAuthenticated();
        setIsAuthenticated(isAuthenticated);
        if (isAuthenticated) {
            await fetchAndSetToken(auth0Client);
        }
    };

    const handleUrlRedirect = async (
        auth0Client: Auth0ClientType,
        onRedirectCallback,
    ): Promise<void> => {
        try {
            const { appState } = await auth0Client.handleRedirectCallback();
            onRedirectCallback(appState);
        } catch (error) {
            onErrorCallback(error);
        }
    };

    const fetchAndSetToken = async (
        auth0Client: Auth0ClientType,
    ): Promise<void> => {
        const token = await auth0Client.getTokenSilently();
        setAccessToken(token);
    };

    const setAccessToken = (token: string) => {
        setAuthHeader(`Bearer ${token}`);
    };

    const handleRedirectCallback = async (): Promise<void> => {
        setLoading(true);
        await authClient.handleRedirectCallback();
        await fetchAndSetToken(authClient);
        setIsAuthenticated(true);
        setLoading(false);
    };

    const loginWithRedirect = async () => {
        const fragment = queryString.stringify({ locusClientId: 'mara' });
        return (await authClient) && authClient.loginWithRedirect({ fragment });
    };

    const logout = async () => {
        return (
            (await authClient) &&
            authClient.logout({ returnTo: window.location.origin })
        );
    };

    const onErrorCallback = (
        error: Error | { error: string, error_description: string },
    ) => {
        const { name, message } =
            error instanceof Error
                ? error
                : { name: error.error, message: error.error_description };
        setError({ title: name, description: message });
    };

    return (
        <AuthContext.Provider
            value={{
                loading,
                isAuthenticated,
                error,
                loginWithRedirect,
                handleRedirectCallback,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
