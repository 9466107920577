//@flow
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import Loader from 'components/core/loader';
import usePasswordAuthSettings from './usePasswordAuthSettings';
import { ApiStatusEnum } from 'enums/API';
import ErrorContainer from 'containers/ErrorContainer';
import PasswordAuthSettingsForm from './PasswordAuthSettingsForm';

const PasswordAuthSettings = (): React.Node => {
    const { clientId } = useParams();
    const settingsQuery = usePasswordAuthSettings(clientId);
    if (settingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            height="100%"
        >
            {settingsQuery.status === ApiStatusEnum.Success && (
                <PasswordAuthSettingsForm
                    clientId={clientId}
                    initialValue={settingsQuery.data}
                />
            )}
            {settingsQuery.status === ApiStatusEnum.Error && (
                <ErrorContainer message={settingsQuery.error.message} />
            )}
        </Box>
    );
};

export default PasswordAuthSettings;
