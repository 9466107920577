// @flow
import { useQuery, type QueryResult } from 'react-query';

import { platformInstance } from 'middleware/axios';
import { type Provider } from 'models/apps/client';

export default function useGetProviders(): QueryResult<Array<Provider>> {
    return useQuery({
        queryKey: ['provider'],
        queryFn: () => {
            return platformInstance.get(`/provider`).then((response) => {
                return response.data.providers;
            });
        },
    });
}
