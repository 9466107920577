//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import Loader from 'components/core/loader';
import { useTeamSettings } from 'hooks/team';
import { ApiStatusEnum } from 'enums/API';
import UpdateTeamSettingsForm from './UpdateTeamSettingsForm';

type Props = { clientId: string, teamId: string };

const TeamSettingsForm = (props: Props): React.Node => {
    const { clientId, teamId } = props;
    const teamSettingsQuery = useTeamSettings({
        clientId,
        teamId,
    });

    if (teamSettingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }
    if (teamSettingsQuery.status === ApiStatusEnum.Error) {
        return (
            <Box pb={1} width="100%">
                <Alert severity="error">
                    {teamSettingsQuery.error.message}
                </Alert>
            </Box>
        );
    }

    return (
        <UpdateTeamSettingsForm
            clientId={clientId}
            teamId={teamId}
            settings={teamSettingsQuery.data}
        />
    );
};

export default TeamSettingsForm;
