//@flow

import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Paper, RootRef, makeStyles } from '@material-ui/core';

import { Body1 } from 'components/core/typography';
import FileUtils from 'utils/FileUtils';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        filePreview: {
            border: `1px solid ${grey[300]}`,
        },
    };
});

type Props = {
    accept: string,
    previewHeight: string,
    previewWidth: string,
    multiple?: boolean,
    onChange: (value: string | Array<string>) => void,
    onImageLoad?: (e: Event) => void,
};

const Dropzone = ({
    accept,
    previewHeight,
    previewWidth,
    onChange,
    multiple,
    onImageLoad,
}: Props): React.Node => {
    const [files, setFiles] = React.useState([]);
    const classes = useStyles();
    const isImage = accept.startsWith('image/');
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
        onDropAccepted: (acceptedFiles) => {
            FileUtils.processFiles(acceptedFiles).then((filesInfo) => {
                const values = filesInfo.map((fileInfo) => fileInfo.dataURL);
                if (multiple) {
                    onChange(values);
                } else {
                    onChange(values[0]);
                }
            });
        },
    });
    const { ref, ...rootProps } = getRootProps();

    React.useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files],
    );

    return (
        <>
            <RootRef rootRef={ref}>
                <Paper variant="outlined" {...rootProps}>
                    <Box p={1}>
                        <input {...getInputProps()} />
                        <Body1>
                            Drag 'n' drop some files here, or click to select
                            files
                        </Body1>
                    </Box>
                </Paper>
            </RootRef>
            {isImage && (
                <Box display="flex" mt={1}>
                    {files.map((file) => (
                        <Box
                            className={classes.filePreview}
                            display="inline-flex"
                            key={file.name}
                        >
                            <Box
                                display="flex"
                                height={previewHeight}
                                width={previewWidth}
                                m={0.5}
                            >
                                <img src={file.preview} onLoad={onImageLoad} />
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
};

export default Dropzone;
