// @flow
import { useMutation, type MutationResult } from 'react-query';
import { queryClient } from 'query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';
import { type CustomFieldType } from 'models/apps/client';

type Variables = {
    clientId: string,
    entityId: string,
    customFields: Array<CustomFieldType>,
    reason: string,
};

export default function useUpdateCustomFields(): MutationResult<
    Variables,
    void,
> {
    return useMutation<Variables, void>(
        ({ clientId, entityId, reason, customFields }) => {
            const pathLists = [
                {
                    path: `entitySettings.${entityId}.customFields`,
                    value: customFields,
                },
            ];
            return ClientSettingsUtils.fetchAndUpdateSettings(
                clientId,
                pathLists,
                reason,
            );
        },
        {
            onSuccess: (_, { clientId }) => {
                queryClient.invalidateQueries([
                    'clients',
                    clientId,
                    'entity-settings',
                ]);
            },
        },
    );
}
