//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';

const addProviderFormObject: FormSectionType = {
    id: 'addProviderFormObject',
    title: 'Add Provider',
    type: 'SECTION',
    subSections: [
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'id',
            title: 'Provider Id',
            required: true,
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'name',
            title: 'Provider Name',
            required: true,
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'noReplyEmail',
            title: 'No reply email',
        },
        {
            type: 'SECTION',
            id: 'assets',
            title: 'Provider assets',
            subSections: [
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.FILE,
                    id: 'smallLogoContent',
                    title: 'Small Logo',
                    required: true,
                    description: 'Preferred size is 32 × 32 px',
                    uiOptions: {
                        previewHeight: '32px',
                        previewWidth: '32px',
                        accept: 'image/*',
                    },
                },
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.FILE,
                    id: 'largeLogoContent',
                    title: 'Large Logo',
                    required: true,
                    description: 'Preferred size is 144 × 32 px',
                    uiOptions: {
                        previewHeight: '32px',
                        previewWidth: '144px',
                        accept: 'image/*',
                    },
                },
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.FILE,
                    id: 'faviconContent',
                    title: 'Favicon',
                    required: true,
                    description: 'Preferred size is 24 × 24 px',
                    uiOptions: {
                        previewHeight: '24px',
                        previewWidth: '24px',
                        accept: 'image/*',
                    },
                },
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.TEXT_FIELD,
                    id: 'termsAndCondtionsDocumentUrl',
                    title: 'Terms And Conditions Document URL',
                },
            ],
        },
    ],
};

export default addProviderFormObject;
