//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { H2, Body1 } from 'components/core/typography';
import ErrorBackground from 'images/error_background.png';

const useStyles = makeStyles(() => ({
    errorBackgroundWrap: {
        height: '100%',
        padding: '5%',
        gap: '8px',
    },
    errorBackground: {
        backgroundImage: `url(${ErrorBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPositionY: 'center',
        backgroundPositionX: 'right',
    },
}));

type Props = {
    message?: string,
};

const ErrorContainer = (props: Props): React.Node => {
    const { message = 'Something went wrong' } = props;
    const classes = useStyles();
    return (
        <Box
            className={classes.errorBackgroundWrap}
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <Box
                className={classes.errorBackground}
                height="350px"
                width="500px"
            />
            <H2>Oops, there was an API failure!</H2>
            <Body1>{message}</Body1>
            <Body1>
                Incase of any issues, please reach out to Backend OnCall
            </Body1>
        </Box>
    );
};

export default ErrorContainer;
