//@flow

import { apiInstance } from 'middleware/axios';
import { type TeamSettingsType } from 'models/apps/team';
import { type ClientSettingsType } from 'models/apps/client';
import { type PathListsType } from 'models/apps/client/Client';
import ClientSettingsUtils from './ClientSettingsUtils';
import JSONUtils from 'utils/JSONUtils';
import set from 'lodash/set';
import merge from 'lodash/merge';

class TeamSettingsUtils {
    static async fetchSettings(
        clientId: string,
        teamId: string,
    ): Promise<TeamSettingsType> {
        const response = await this._getSettingsApi(clientId, teamId);
        return this._extractSettings(response);
    }

    static async updateSettings(
        clientId: string,
        teamId: string,
        settings: TeamSettingsType,
        reason: string,
    ): Promise<TeamSettingsType> {
        const payload = JSON.stringify(settings);
        const updateSettingsResponse = await this._updateSettingsApi(
            clientId,
            teamId,
            {
                reason,
                payload,
            },
        );
        return this._extractSettings(updateSettingsResponse);
    }

    static _getSettingsApi(clientId: string, teamId: string) {
        return apiInstance.get(`/client/${clientId}/team/${teamId}/settings`);
    }

    static _updateSettingsApi(
        clientId: string,
        teamId: string,
        settings: TeamSettingsType,
    ) {
        return apiInstance.post(
            `/client/${clientId}/team/${teamId}/settings`,
            settings,
        );
    }

    static _extractSettings(response) {
        const {
            data: { payload },
        } = response;
        return JSON.parse(payload);
    }

    static _fetchUpdatedSettings(currentSettings, pathList) {
        return pathList.reduce((settings, pathObject) => {
            const { path, value } = pathObject;
            return set(settings, path, value);
        }, currentSettings);
    }

    static _getPathListWithDiff(clientSettings, pathList, diffAttributeList) {
        let unChangedPathCount = 0;
        diffAttributeList.reduce((pathList, attr) => {
            const pathIndex = pathList.findIndex(
                (pathObject) => pathObject.path === attr,
            );

            if (pathIndex > -1) {
                const diff = JSONUtils.findJSONDiff(
                    clientSettings[attr],
                    pathList[pathIndex].value,
                );
                if (Object.keys(diff).length) {
                    pathList[pathIndex].value = diff;
                } else {
                    unChangedPathCount++;
                }
            }
        }, pathList);
        if (unChangedPathCount === diffAttributeList.length) {
            throw new Error('There is no change');
        }
        return pathList;
    }

    static async fetchAndUpdateSettings(
        clientId: string,
        teamId: string,
        pathList: PathListsType,
        reason: string,
        diffAttributeList?: Array<string>,
    ): Promise<TeamSettingsType> {
        const response = await this._getSettingsApi(clientId, teamId);
        const settings = this._extractSettings(response);
        const clientSettings = await ClientSettingsUtils.fetchSettings(
            clientId,
        );

        const settingsPathList =
            diffAttributeList && diffAttributeList.length
                ? this._getPathListWithDiff(
                      clientSettings,
                      pathList,
                      diffAttributeList,
                  )
                : pathList;

        const settingsToBeUpdated = this._fetchUpdatedSettings(
            settings,
            settingsPathList,
        );
        return this.updateSettings(
            clientId,
            teamId,
            settingsToBeUpdated,
            reason,
        );
    }

    static getMergedSettings(
        clientSettings: ClientSettingsType,
        teamSettings: TeamSettingsType,
        mergeAttributeList: Array<string>,
    ): TeamSettingsType {
        return mergeAttributeList.reduce((teamConfig, mergeAttribute) => {
            return {
                ...teamConfig,
                ...merge(
                    clientSettings[mergeAttribute],
                    teamSettings[mergeAttribute] || {},
                ),
            };
        }, {});
    }
}

export default TeamSettingsUtils;
