//@flow

import * as React from 'react';
import { makeStyles, Tooltip as MuiTooltip } from '@material-ui/core';

export type TooltipPlacement =
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

export type Props = {
    title: string,
    open?: boolean,
    placement?: TooltipPlacement,
    children: React.Node,
};

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        arrow: {
            color: grey[900],
        },
        tooltip: {
            backgroundColor: grey[900],
        },
    };
});

const Tooltip = (props: Props): React.Node => {
    const { open, title, placement = 'right', children } = props;
    const classes = useStyles();
    return (
        <MuiTooltip
            classes={classes}
            title={title}
            placement={placement}
            open={open}
            arrow
        >
            {children}
        </MuiTooltip>
    );
};

export default Tooltip;
