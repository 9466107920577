//@flow

import * as React from 'react';
import { FormControl } from '@material-ui/core';

import TextField from 'components/core/input';
import FormLabel from 'components/core/form-label';
import { type SchemaType } from 'models/Schema';

export type TextWidgetProps = {
    value: any,
    label: string,
    type: string,
    options: { [key: string]: any },
    schema: SchemaType,
    required?: boolean,
    multiline?: boolean,
    rows?: number,
    placeholder?: string,
    onChange: (value: any) => void,
    disabled: boolean,
};

const TextWidget = (props: TextWidgetProps): React.Node => {
    const {
        options: { emptyValue },
        placeholder,
        schema: { description, type: schemaType, minimum, maximum },
        value,
        label,
        required,
        onChange,
        type,
        multiline,
        rows,
    } = props;

    const handleChange = (value: string) => {
        // Empty Value is Default Field Value and is set as undefined by default
        onChange(value === '' ? emptyValue : value);
    };

    return (
        <FormControl component="fieldset">
            <FormLabel
                label={label}
                required={required}
                description={description}
            />
            <TextField
                type={type || schemaType}
                minimum={minimum}
                maximum={maximum}
                required={required}
                placeholder={placeholder}
                multiline={multiline}
                rows={rows}
                value={value || value === 0 ? value : ''}
                onChange={handleChange}
            />
        </FormControl>
    );
};

export default TextWidget;
