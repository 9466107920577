//@flow
import * as React from 'react';

import ComponentWithDropdown from 'components/core/componentWithDropdown';
import { useAuth0 } from 'auth/AuthHelper';
import { ApiStatusEnum } from 'enums/API';
import { useSession } from 'hooks/session';
import userImage from 'images/user-photo-success.png';

const fallbackPhotoUrl = userImage;

const UserIconDropdown = (): React.Node => {
    const { logout, isAuthenticated, loading } = useAuth0();
    const sessionQuery = useSession({
        enabled: !loading && isAuthenticated,
    });

    if (sessionQuery.status === ApiStatusEnum.Loading) {
        return null;
    }

    const photoUrl = sessionQuery.data?.personnel.photoUrl || fallbackPhotoUrl;

    return (
        <ComponentWithDropdown
            popupProps={{
                popupId: 'personnelIconPopup',
                variant: 'popover',
            }}
            menuProps={{
                menuItems: [
                    {
                        id: 'name',
                        label: sessionQuery.data?.personnel.name || '',
                        divider: true,
                    },
                    {
                        id: 'logout',
                        label: `Logout`,
                        onClick: logout,
                    },
                ],
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
            }}
            component="div"
            componentProps={{
                style: {
                    backgroundImage: `url(${photoUrl})`,
                    width: '30px',
                    height: '30px',
                    borderRadius: '4rem',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                },
            }}
        />
    );
};

export default UserIconDropdown;
