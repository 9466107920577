//@flow

import startCase from 'lodash/startCase';

class StringUtils {
    static toTitleCase(str: string): string {
        const lowerCaseString = str.toLowerCase();
        return startCase(lowerCaseString);
    }
}

export default StringUtils;
