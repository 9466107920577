// @flow
import { useQuery, type QueryResult } from 'react-query';

import { apiInstance } from 'middleware/axios';
import { type TeamType } from 'models/apps/team';

export default function useTeams(
    clientId: string,
): QueryResult<Array<TeamType>> {
    return useQuery({
        queryKey: ['clients', clientId, 'team'],
        queryFn: () => {
            return apiInstance
                .get(`/client/${clientId}/team`)
                .then((response) => {
                    return response.data;
                });
        },
    });
}
