// @flow
import { useMutation, type MutationResult } from 'react-query';

import { instance } from 'middleware/axios';

import { type TaskProfile } from 'models/TaskProfile';

type Variables = {
    clientId: string,
    taskProfile: TaskProfile,
};

export default function useAddTaskProfile(): MutationResult<
    Variables,
    TaskProfile,
> {
    return useMutation<Variables, TaskProfile>(({ clientId, taskProfile }) => {
        return instance.post(`client/${clientId}/taskprofile`, taskProfile);
    });
}
