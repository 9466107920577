//@flow

import {
    statusOptionsFilters,
    stageOptionsFilters,
} from 'constants/apps/client';
import {
    TextFilter,
    SelectColumnFilter,
} from 'components/core/table/TableFilters';

import { type Column } from 'react-table';
import { type ClientType } from 'models/apps/client/AllClients';

export const allClientColumns: Array<Column<ClientType>> = [
    {
        Header: 'Client Id',
        accessor: 'clientId',
        filter: 'fuzzyText',
        Filter: TextFilter,
    },
    {
        Header: 'Client Name',
        accessor: 'name',
        filter: 'fuzzyText',
        Filter: TextFilter,
    },
    {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: {
            selectOptions: statusOptionsFilters,
            defaultValue: 'all',
        },
    },
    {
        Header: 'Stage',
        accessor: 'stage',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: {
            selectOptions: stageOptionsFilters,
            defaultValue: 'all',
        },
    },
];
