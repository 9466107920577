//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/core/button';
import { type TeamType } from 'models/apps/team';
import TeamSelect from 'components/common/TeamSelect';
import TeamSettingsForm from './TeamSettingsForm';
import invariant from 'utils/invariant';

type Props = {
    teams: Array<TeamType>,
    clientId: string,
};

const useStyles = makeStyles({
    teamSelectorForm: {
        gap: '16px',
    },
});

const TeamSelectorForm = (props: Props): React.Node => {
    const { teams, clientId } = props;

    invariant(teams.length > 0, 'Expected atleast one team to be present here');

    const [{ teamId: initialValue }] = teams;
    const classes = useStyles();
    const [value, onChange] = React.useState(initialValue);
    const [showSettingsForm, setShowSettingsForm] = React.useState(false);

    const onTeamChange = (teamId) => {
        setShowSettingsForm(false);
        onChange(teamId);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            className={classes.teamSelectorForm}
        >
            <TeamSelect teams={teams} value={value} onChange={onTeamChange} />
            <Box width="100%" textAlign="center">
                <Button
                    type="submit"
                    size="small"
                    color="primary"
                    onClick={() => setShowSettingsForm(true)}
                >
                    SUBMIT
                </Button>
            </Box>
            {showSettingsForm && (
                <TeamSettingsForm teamId={value} clientId={clientId} />
            )}
        </Box>
    );
};

export default TeamSelectorForm;
