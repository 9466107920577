//@flow
import get from 'lodash/get';

import {
    TextFilter,
    SelectColumnFilter,
    type FilterOptions,
} from 'components/core/table/TableFilters';

import { type Column as ReactTableColumn } from 'react-table';

import { type FormSectionType } from 'models/FormSection';
import { type FormFieldType } from 'models/FormField';
import { type FormObjectType } from 'models/FormObjectField';
import { type FormArrayType } from 'models/FormArrayField';
import { FormFieldTypeEnum } from 'enums/FormFieldType';

type Column<T> = {
    ...ReactTableColumn<T>,
    filterOptions?: FilterOptions,
};

type Columns<T> = Array<Column<T>>;

class TableUtils {
    static buildTableColumns<T>(section: FormSectionType): Columns<T> {
        const { subSections } = section;
        const columns = subSections.flatMap((subSection) =>
            TableUtils.buildObjectColumns(subSection),
        );
        return columns;
    }

    static buildObjectColumns<T>(formObject: FormObjectType): Columns<T> {
        let column;
        switch (formObject.type) {
            case 'ROW':
            case 'COLUMN':
                return formObject.fields.reduce((allColumns, field) => {
                    const columns = TableUtils.buildObjectColumns(field);
                    if (columns.length === 0) {
                        return allColumns;
                    }
                    return [...allColumns, ...columns];
                }, []);
            case 'ARRAY':
                column = TableUtils.buildArrayColumn(formObject);
                return column ? [column] : [];
            case 'SECTION':
                return TableUtils.buildTableColumns(formObject);
            default:
                column = TableUtils.buildFieldColumn(formObject);
                return column ? [column] : [];
        }
    }

    static buildArrayColumn<T>(field: FormArrayType): ?Column<T> {
        const {
            items: { type },
            filterable,
            accessor,
            title,
        } = field;
        if (type === 'FIELD') {
            const baseArrayProperties = {
                Header: title,
                accessor: (data) => get(data, accessor).join(),
            };
            if (!filterable) {
                return baseArrayProperties;
            }
            return {
                ...baseArrayProperties,
                filter: 'fuzzyText',
                Filter: TextFilter,
            };
        }
        throw new Error('Unsupported items type for arrays in Tables');
    }

    static buildFieldColumn<T>(field: FormFieldType): Column<T> {
        const { fieldType } = field;
        switch (fieldType) {
            case FormFieldTypeEnum.SELECT:
            case FormFieldTypeEnum.AUTOCOMPLETE:
                return TableUtils.buildSelectColumn(field);
            case FormFieldTypeEnum.SWITCH:
                return TableUtils.buildBooleanColumn(field);
            default:
                return TableUtils.buildDefaultColumn(field);
        }
    }

    static buildDefaultColumn<T>(field: FormFieldType): Column<T> {
        const { filterable } = field;
        const baseColumnProperties = TableUtils.buildBaseColumnProperties(
            field,
        );
        if (!filterable) {
            return baseColumnProperties;
        }
        return {
            ...baseColumnProperties,
            filter: 'fuzzyText',
            Filter: TextFilter,
        };
    }

    static buildSelectColumn<T>(field: FormFieldType): Column<T> {
        const { options, filterable } = field;
        const baseColumnProperties = TableUtils.buildBaseColumnProperties(
            field,
        );
        if (!filterable) {
            return baseColumnProperties;
        }
        return {
            ...baseColumnProperties,
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: {
                selectOptions: [
                    ...(options?.items || []),
                    {
                        id: 'all',
                        value: 'all',
                        label: 'All',
                    },
                ],
            },
        };
    }

    static buildBooleanColumn<T>(field: FormFieldType): Column<T> {
        const { filterable } = field;
        const {
            accessor,
            ...restColumnProperties
        } = TableUtils.buildBaseColumnProperties(field);
        const accessorFn =
            typeof accessor === 'function'
                ? accessor
                : (data) => {
                      return get(data, accessor).toString();
                  };
        if (!filterable) {
            return { accessor: accessorFn, ...restColumnProperties };
        }
        return {
            ...restColumnProperties,
            accessor: accessorFn,
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: {
                selectOptions: [
                    {
                        id: 'trueField',
                        label: 'true',
                        value: 'true',
                    },
                    {
                        id: 'falseField',
                        label: 'false',
                        value: 'false',
                    },
                ],
            },
        };
    }

    static buildBaseColumnProperties<T>(field: FormFieldType): Column<T> {
        const { accessor, id, title } = field;
        return {
            accessor: accessor || id,
            Header: title,
        };
    }
}

export default TableUtils;
