//@flow

import * as React from 'react';
import classnames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import {
    default as MuiSelect,
    type SelectProps,
} from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import Chip from 'components/core/chip';
import { Body1 } from 'components/core/typography';

export type OptionsType = {
    id: string | number,
    label: string | number,
    value: string | number,
};

export type OptionsListType = Array<OptionsType>;

export type SelectPropsTypes = {
    ...SelectProps,
    items: OptionsListType,
    onChange: (value: string) => void,
};

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        fullWidthSelectField: {
            width: '100%',
        },
        selectField: {
            borderRadius: (props) => (props.removeBorderRadius ? '0px' : '2px'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: grey[500],
            },
        },
        menuListPaperWrap: {
            maxHeight: '300px',
        },
    };
});

const Select = (props: SelectPropsTypes) => {
    const defaultAnchorOrigin = { vertical: 'bottom', horizontal: 'left' };
    const defaultTransformOrigin = { vertical: 'top', horizontal: 'left' };
    const defaultMenuProps = {
        anchorOrigin: defaultAnchorOrigin,
        transformOrigin: defaultTransformOrigin,
        getContentAnchorEl: null,
    };
    const {
        items,
        fullWidth,
        menuProps = defaultMenuProps,
        inputProps,
        variant = 'outlined',
        value,
        multiple,
        onChange,
    } = props;
    const classes = useStyles(props);

    const handleChange = (event) => {
        const { value: updatedValue } = event.target;
        onChange(updatedValue);
    };

    return (
        <FormControl
            variant={variant}
            size="small"
            className={classnames({
                [classes.fullWidthSelectField]: fullWidth,
            })}
        >
            <MuiSelect
                className={classes.selectField}
                variant={variant}
                value={value}
                multiple={multiple}
                MenuProps={{
                    ...menuProps,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.menuListPaperWrap },
                }}
                inputProps={inputProps}
                renderValue={
                    multiple
                        ? (selected) => {
                              return selected.map((value) => (
                                  <Chip key={value} label={value} />
                              ));
                          }
                        : null
                }
                onChange={handleChange}
            >
                {items.map((item) => {
                    const { id, label, value } = item;
                    return (
                        <MenuItem key={id} value={value}>
                            <Body1 align="center">{label}</Body1>
                        </MenuItem>
                    );
                })}
            </MuiSelect>
        </FormControl>
    );
};

export default Select;
export { Select };
