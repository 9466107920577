//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import Loader from 'components/core/loader';
import { H3, Body1 } from 'components/core/typography';
import { useTeams } from 'hooks/team';
import { useClientId } from 'hooks/client';
import { ApiStatusEnum } from 'enums/API';
import TeamSelectorForm from './TeamSelectorForm';

const TeamSettings = (): React.Node => {
    const clientId = useClientId();
    const teamsQuery = useTeams(clientId);
    if (teamsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            //Hack to allow min height as full height of container
            minHeight="70vh"
        >
            <Box p={1}>
                <H3> Team Settings </H3>
            </Box>
            {teamsQuery.status === ApiStatusEnum.Error ? (
                <Box pb={1} width="100%">
                    <Alert severity="error">{teamsQuery.error.message}</Alert>
                </Box>
            ) : teamsQuery.data.length === 0 ? (
                <Body1> No Teams Found</Body1>
            ) : (
                <TeamSelectorForm clientId={clientId} teams={teamsQuery.data} />
            )}
        </Box>
    );
};

export default TeamSettings;
