//@flow
import * as React from 'react';

import Drawer from 'components/core/drawer';
import SplitButton from 'components/core/split-button';

import AddGenericClientContainer from './AddGenericClientContainer';
import AddNetOptClientContainer from './AddNetOptClientContainer';
import AddOrderIqClientContainer from './AddOrderIqClientContainer';

const clientCreationOptions = [
    {
        id: 'ADD_CLIENT',
        label: 'ADD CLIENT',
        Component: AddGenericClientContainer,
    },
    {
        id: 'ADD_ORDER_IQ_CLIENT',
        label: 'ADD ORDER IQ CLIENT',
        Component: AddOrderIqClientContainer,
    },
    {
        id: 'ADD_NETOPT_CLIENT',
        label: 'ADD NETOPT CLIENT',
        Component: AddNetOptClientContainer,
    },
];

const AddClientWrapperContainer = (): React.Node => {
    const [showAddClient, setShowAddClient] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState(
        clientCreationOptions[0].id,
    );

    const onAddClientClick = (key) => {
        setSelectedOption(key);
        setShowAddClient(true);
    };

    const handleDrawerClose = () => {
        setShowAddClient(false);
    };

    const ClientContainerComponent = React.useMemo(() => {
        const Component = clientCreationOptions.find(
            (option) => option.id === selectedOption,
        )?.Component;

        return Component ? <Component /> : null;
    }, [selectedOption]);

    return (
        <>
            <SplitButton
                disabled
                options={clientCreationOptions}
                onClick={onAddClientClick}
            />
            <Drawer
                open={showAddClient}
                anchor="right"
                onClose={handleDrawerClose}
            >
                {ClientContainerComponent}
            </Drawer>
        </>
    );
};

export default AddClientWrapperContainer;
