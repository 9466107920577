//@flow

import * as React from 'react';
import Typography, {
    type TypographyProps,
    type Variant,
} from '@material-ui/core/Typography';

export const Body1 = (props: TypographyProps) => (
    <BaseTypography variant="body1" typographyProps={props} />
);

export const Body2 = (props: TypographyProps) => (
    <BaseTypography variant="body2" typographyProps={props} />
);

export const H6 = (props: TypographyProps) => (
    <BaseTypography variant="h6" typographyProps={props} />
);

export const H4 = (props: TypographyProps) => (
    <BaseTypography variant="h4" typographyProps={props} />
);

export const H3 = (props: TypographyProps) => (
    <BaseTypography variant="h3" typographyProps={props} />
);

export const H2 = (props: TypographyProps) => (
    <BaseTypography variant="h2" typographyProps={props} />
);

export const H1 = (props: TypographyProps) => (
    <BaseTypography variant="h1" typographyProps={props} />
);

type Props = {
    variant: Variant,
    typographyProps: { ...TypographyProps, children: React.Node<*> },
};

const BaseTypography = (props: Props) => {
    const { variant, typographyProps } = props;
    const { children, ...otherProps } = typographyProps;
    return (
        <Typography variant={variant} {...otherProps}>
            {children}
        </Typography>
    );
};
