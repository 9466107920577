//@flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import MuiErrorIcon from '@material-ui/icons/Error';
import MuiCloseIcon from '@material-ui/icons/Close';
import MuiDeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';

const FontSizeEnum = Object.freeze({
    xxLarge: 'xxLarge',
    xLarge: 'xLarge',
    large: 'large',
    medium: 'medium',
    small: 'small',
});

type FontSizeEnumType = $Values<typeof FontSizeEnum>;

const fontSizeMap = {
    xxLarge: '32px',
    xLarge: '24px',
    large: '20px',
    medium: '16px',
    small: '12px',
};

const useStyles = makeStyles({
    iconWrapper: {
        cursor: (props) => {
            const { onClick } = props;
            return onClick ? 'pointer' : 'default';
        },
        fontSize: (props) => fontSizeMap[props.fontSize],
    },
});

type Props = {
    color?: string,
    fontSize?: FontSizeEnumType,
    onClick?: (event: SyntheticEvent<>) => void,
};

type IconFactoryProps = {
    ...Props,
    icon: React.ComponentType<any>,
};

const IconFactory = (props: IconFactoryProps): React.Node => {
    const {
        icon: Icon,
        color,
        fontSize = FontSizeEnum.medium,
        onClick,
    } = props;
    const classes = useStyles({ fontSize, onClick });
    return (
        <Icon
            className={classes.iconWrapper}
            style={{ color }}
            onClick={onClick}
        />
    );
};

const ErrorIcon = (props: Props): React.Node => {
    return <IconFactory {...props} icon={MuiErrorIcon} color={red[500]} />;
};

const DeleteIcon = (props: Props): React.Node => {
    return <IconFactory {...props} icon={MuiDeleteIcon} />;
};

const CloseIcon = (props: Props): React.Node => {
    return <IconFactory {...props} icon={MuiCloseIcon} />;
};

export { ErrorIcon, DeleteIcon, CloseIcon };
