// @flow
import * as React from 'react';
import { validateIconSize } from 'utils/BrandingUtils';

const useBrandingFormValidation = () => {
    const [errors, setErrors] = React.useState({});

    const setValidationError = React.useCallback(
        (key: string, width: number, height: number) => {
            const validationErrorMsg = validateIconSize(key, width, height);
            if (validationErrorMsg) {
                setErrors({
                    ...errors,
                    [key]: {
                        __errors: [validationErrorMsg],
                    },
                });
            } else {
                const newErrors = { ...errors };
                delete newErrors[key];
                setErrors(newErrors);
            }
        },
        [errors],
    );

    const onFaviconLoad = React.useCallback(
        (width, height) => {
            setValidationError('favicon', width, height);
        },
        [setValidationError],
    );

    const onVehicleIconLoad = React.useCallback(
        (width, height) => {
            setValidationError('vehicleIcon', width, height);
        },
        [setValidationError],
    );

    return [errors, onFaviconLoad, onVehicleIconLoad];
};

export default useBrandingFormValidation;
