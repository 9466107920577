// @flow
import { useMutation, type MutationResult } from 'react-query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';

type Variables = {
    clientId: string,
    providerId: string,
    enabled?: boolean,
    reason: string,
};

export default function useUpdateClientProvider(): MutationResult<
    Variables,
    void,
> {
    return useMutation<Variables, void>(
        ({ clientId, providerId, enabled = true, reason }) => {
            const pathLists = [
                {
                    path: `platformSettings.whiteLabelSettings.enabled`,
                    value: enabled,
                },
                {
                    path: `platformSettings.whiteLabelSettings.provider`,
                    value: providerId,
                },
            ];
            return ClientSettingsUtils.fetchAndUpdateSettings(
                clientId,
                pathLists,
                reason,
            );
        },
    );
}
