// @flow
import { useMutation, type MutationResult } from 'react-query';
import { apiInstance } from 'middleware/axios';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';
import {
    WorkflowTypeEnum,
    type WorkflowTypeEnumType,
} from 'enums/WorkflowTypeEnum';

type CreateOpVariables = {
    clientId: string,
    reason: string,
    client: ClientType,
};

type ConfigureOpVariables = {
    clientId: string,
    reason: string,
};

const pathLists = [
    {
        path: 'taskSettings.softCancellationEnabled',
        value: true,
    },
    {
        path: 'batchSettings.enableFreezePlanTour',
        value: true,
    },
    {
        path: 'batchSettings.enableFreezePlan',
        value: true,
    },
    {
        path: 'batchSettings.editsConfig.allowEditsOnPreallocatedVisits',
        value: false,
    },
    {
        path: 'batchSettings.unallocateTaskOnBatchEdit',
        value: false,
    },
    {
        path: 'sectionVisibilitySettings.showOrderManagementSection',
        value: true,
    },
    {
        path: 'orderManagementSettings.orderMasterSettings.enabled',
        value: true,
    },
    {
        path: 'orderManagementSettings.receivedStatusSettings.enabled',
        value: true,
    },
    {
        path: 'orderManagementSettings.receivedStatusSettings.autoVerifyOrder',
        value: false,
    },
    {
        path: 'callbackSettings.taskCallbackSettings.taskCallbacks',
        value: [
            {
                type: {
                    type: 'TASK_STATUS_UPDATE',
                },
                url: 'lqs://oms-task-fifo-callback-queue',
            },
        ],
    },
    {
        path: 'callbackSettings.tourCallBackSettings.tourCallbacks',
        value: [
            {
                type: {
                    type: 'FREEZE',
                },
                url: 'lqs://oms-tour-callback-queue',
            },
            {
                type: {
                    type: 'UNFREEZE',
                },
                url: 'lqs://oms-tour-callback-queue',
            },
            {
                type: {
                    type: 'ASSIGN',
                },
                url: 'lqs://oms-tour-callback-queue',
            },
        ],
    },
    {
        path: 'callbackSettings.motiontrackTourCallBackSettings.tourCallbacks',
        value: [
            {
                type: {
                    type: 'TOUR_STATUS_UPDATE',
                },
                url: 'lqs://oms-motiontrack-tour-callback-queue',
            },
        ],
    },
    {
        path: 'geocodeSettings.enableApi',
        value: true,
    },
    {
        path:
            'geocodeSettings.addressStreamSettings.enableBatchingGeocodingRequests',
        value: true,
    },
    {
        path: 'geocodeSettings.addressStreamSettings.callbackUrl',
        value: 'lqs://oms-geocoder-callback-queue',
    },
    {
        path: 'orderManagementSettings.geocodingSettings.enabled',
        value: true,
    },
];

export default function useCreateOrderIqClient(): MutationResult<
    CreateOpVariables,
    void,
> {
    return useMutation<CreateOpVariables, void>(
        async ({ clientId, client, reason }) => {
            await apiInstance.put(`client/${clientId}`, client);
            await configure({
                clientId,
                reason,
                workflowType: client.workflowType,
            });
        },
    );
}

export function useConfigureOrderIqClient(): MutationResult<
    ConfigureOpVariables,
    void,
> {
    return useMutation<ConfigureOpVariables, void>(
        async ({ clientId, reason }) => {
            await configure({ clientId, reason });
        },
    );
}

const getPathList = (workflowType) => {
    if (workflowType === WorkflowTypeEnum.SCHEDULED) {
        return pathLists.filter(
            (setting) => !setting.path.includes('callbackSettings.'),
        );
    }
    return pathLists;
};

async function configure({
    clientId,
    reason,
    workflowType,
}: {
    clientId: string,
    reason: string,
    workflowType: WorkflowTypeEnumType,
}) {
    await apiInstance.post(`/client/${clientId}/callback/generate-credential`);
    await ClientSettingsUtils.fetchAndUpdateSettings(
        clientId,
        getPathList(workflowType),
        reason,
    );
}
