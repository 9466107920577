// @flow
import { useMutation, type MutationResult } from 'react-query';

import { lqsInstance } from 'middleware/axios';
import { type Messages } from './Messages.type';

type Variables = {
    namespace: string,
    queueName: string,
    getMessagesRequest?: {
        page: number,
        size: number,
    },
};

export default function useFetchMessages(): MutationResult<
    Variables,
    Messages,
> {
    return useMutation<Variables, Messages>(
        ({
            namespace,
            queueName,
            getMessagesRequest = { page: 1, size: 100 },
        }) => {
            return lqsInstance
                .post(
                    `namespace/${namespace}/dlq/${queueName}`,
                    getMessagesRequest,
                )
                .then((response) => {
                    return response.data.messages;
                });
        },
    );
}
