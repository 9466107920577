//@flow

import * as React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    List,
    ListItem,
} from '@material-ui/core';

import ErrorMessage from 'components/core/error-message';
import { type UiSchemaType } from 'models/UiSchema';

type Props = {
    id: string,
    label: string,
    children: React.Node,
    rawErrors?: ?Array<string>,
    rawHelp?: ?string,
    uiSchema?: UiSchemaType,
};

const FormFieldTemplate = (props: Props): React.Node => {
    const { label, children, rawErrors, rawHelp, uiSchema } = props;
    const fieldUiOptions = uiSchema?.['ui:options'];
    return (
        <Box width={fieldUiOptions?.width || 1}>
            <FormControl fullWidth>
                {children}
                {rawErrors && (
                    <List dense disablePadding>
                        {rawErrors.map((error, index: number) => (
                            <ListItem key={index} disableGutters>
                                <ErrorMessage message={`${label} ${error}`} />
                            </ListItem>
                        ))}
                    </List>
                )}
                {rawHelp && <FormHelperText>{rawHelp}</FormHelperText>}
            </FormControl>
        </Box>
    );
};

export default FormFieldTemplate;
