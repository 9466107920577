//@flow
import set from 'lodash/set';

import { apiInstance } from 'middleware/axios';
import { type PathListsType } from 'models/apps/client/Client';
import { type ClientSettingsType } from 'models/apps/client';

class ClientSettingsUtils {
    static async fetchSettings(clientId: string): Promise<$FixMe> {
        const response = await this._getSettingsApi(clientId);
        return this._extractSettings(response);
    }

    static async updateSettings(
        clientId: string,
        settings: ClientSettingsType,
        reason: string,
    ): Promise<ClientSettingsType> {
        const payload = JSON.stringify(settings);
        const updateSettingsResponse = await this._updateSettingsApi(clientId, {
            reason,
            payload,
        });
        return this._extractSettings(updateSettingsResponse);
    }

    static async fetchAndUpdateSettings(
        clientId: string,
        pathList: PathListsType,
        reason: string,
    ): Promise<ClientSettingsType> {
        const response = await this._getSettingsApi(clientId);
        const settings = this._extractSettings(response);
        const settingsToBeUpdated = this._fetchUpdatedSettings(
            settings,
            pathList,
        );
        return this.updateSettings(clientId, settingsToBeUpdated, reason);
    }

    static _getSettingsApi(clientId: string) {
        return apiInstance.get(`/client/${clientId}/settings`);
    }

    static _updateSettingsApi(clientId: string, settings: $FixMe) {
        return apiInstance.post(`/client/${clientId}/settings`, settings);
    }

    static _extractSettings(response) {
        const {
            data: { payload },
        } = response;
        return JSON.parse(payload);
    }

    static _fetchUpdatedSettings(currentSettings, pathList) {
        return pathList.reduce((settings, pathObject) => {
            const { path, value } = pathObject;
            return set(settings, path, value);
        }, currentSettings);
    }
}

export default ClientSettingsUtils;
