//@flow

import * as React from 'react';
import { Route, Switch, type Match } from 'react-router-dom';

import AllClients from 'containers/all-clients/AllClientsContainer';
import ClientContainer from 'containers/client/ClientContainer';
import NoMatchContainer from 'containers/NoMatchContainer';
import ConfigureClient from 'containers/configure-client/ConfigureClient';

const noMatchRoutes = ['/no-match'];

type Props = {
    match: Match,
};

const AppRoutes = (props: Props): React.Node => {
    const { match } = props;
    return (
        <Switch>
            <Route
                exact
                path={`${match.path}client/:clientId/configure`}
                component={ConfigureClient}
            />
            <Route
                path={`${match.path}client/:clientId`}
                component={ClientContainer}
            />
            <Route path={noMatchRoutes} component={NoMatchContainer} />
            <Route path="/" component={AllClients} />
        </Switch>
    );
};

export default AppRoutes;
