// @flow
import { useMutation, type MutationResult } from 'react-query';

import { lqsInstance } from 'middleware/axios';

type Variables = {
    namespace: string,
    queueName: string,
    reseedRequest: {
        messageIds: Array<string>,
    },
};

type Result = {
    successCount: number,
};

export default function useReseedMessages(): MutationResult<Variables, Result> {
    return useMutation<Variables, Result>(
        ({ namespace, queueName, reseedRequest }) => {
            return lqsInstance
                .post(
                    `namespace/${namespace}/dlq/${queueName}/reseed-messages`,
                    reseedRequest,
                )
                .then((response) => {
                    return response.data;
                });
        },
    );
}
