// @flow
import { useMutation, type MutationResult } from 'react-query';
import { queryClient } from 'query';

import { apiInstance } from 'middleware/axios';

type Variables = {
    clientId: string,
    domainsToAdd: Array<string>,
    domainsToDelete: Array<string>,
    reason: string,
};

type Result = Array<string>;

export default function useUpdateClientDomains(): MutationResult<
    Variables,
    Result,
> {
    return useMutation<Variables, Result>(
        ({ clientId, domainsToAdd, domainsToDelete, reason }) => {
            return apiInstance.post(`/client/${clientId}/domain`, {
                domainsToAdd,
                domainsToDelete,
                reason,
            });
        },
        {
            onSuccess: (_, { clientId }) => {
                queryClient.invalidateQueries(['clients', clientId]);
            },
        },
    );
}
