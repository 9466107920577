// @flow
import { useMutation, type MutationResult } from 'react-query';

import { type IdGeneratorPayloadType } from 'models/apps/client/IdGenerator';

import { internalApiInstance } from 'middleware/axios';

type UpdateVariables = {
    clientId: string,
    payload: IdGeneratorPayloadType,
};

export default function useUpdateIdGenerator(): MutationResult<
    UpdateVariables,
    void,
> {
    return useMutation<UpdateVariables, void>(({ clientId, payload }) => {
        return internalApiInstance.post(
            `/v1/client/${clientId}/id-template`,
            payload,
        );
    });
}
