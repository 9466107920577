//@flow
import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';

import { stageOptions } from 'constants/apps/client';
import { SelectWithBoxedLabel } from 'components/core/select';
import { Modal } from 'components/core/modal';
import { Body2 } from 'components/core/typography';
import TextField from 'components/core/input';
import { ApiButton } from 'components/core/button';

import { type ClientType } from 'models/apps/client/Client';
import useUpdateClientStage from 'hooks/client/useUpdateClientStage';

type Props = {
    client: ClientType,
};

const StageContainer = (props: Props): React.Node => {
    const { client } = props;
    const { stage } = client;

    const [reason, setReason] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [selectedStage, setSelectedStage] = React.useState(stage);
    const updateStageMutation = useUpdateClientStage();

    const openUpdateStageModal = (selectedStage) => {
        setOpen(true);
        setSelectedStage(selectedStage);
        setReason('');
    };

    const onUpdateStageClick = () => {
        updateStageMutation.mutate({
            clientId: client.clientId,
            stage: selectedStage,
            reason,
        });
    };

    const onModalClose = () => {
        updateStageMutation.reset();
        setOpen(false);
    };

    return (
        <>
            <SelectWithBoxedLabel
                labelBoxProps={{ label: 'Stage' }}
                selectProps={{
                    items: stageOptions,
                    value: stage,
                    fullWidth: true,
                    onChange: openUpdateStageModal,
                }}
            />
            <Modal open={open} title="Reason For Change" onClose={onModalClose}>
                <Box width="500px">
                    <Box pb={0.5}>
                        <Body2> Description </Body2>
                    </Box>
                    <Box pb={1}>
                        <TextField
                            multiline
                            fullWidth
                            rows="4"
                            placeholder="Please provide the exact reason for this change.
                            This will be used for dashboard audit purposes"
                            onChange={setReason}
                        />
                    </Box>
                    <ApiButton
                        apiStatus={updateStageMutation.status}
                        errorMessage={updateStageMutation.error?.message}
                        successMessage="Stage updated successfully"
                        disabled={isEmpty(reason)}
                        onClick={onUpdateStageClick}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default StageContainer;
