// @flow
import { useMutation, type MutationResult } from 'react-query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';

type Item = {
    id: string,
    value: string,
    checked?: boolean,
};

type Variables = {
    clientId: string,
    enabledList: Array<Item>,
    disabledList: Array<Item>,
    reason: string,
};

export default function useUpdateClientMastersState(): MutationResult<
    Variables,
    void,
> {
    return useMutation<Variables, void>(
        ({ clientId, reason, enabledList, disabledList }) => {
            const allEntities = [...enabledList, ...disabledList];
            const pathLists = allEntities.reduce((mastersPathMap, entity) => {
                const { id } = entity;
                const path = `entitySettings.${id}.enabled`;
                const isEnabled = enabledList.includes(entity);
                return mastersPathMap.concat({ path, value: isEnabled });
            }, []);

            return ClientSettingsUtils.fetchAndUpdateSettings(
                clientId,
                pathLists,
                reason,
            );
        },
    );
}
