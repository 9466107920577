//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import { ApiButton } from 'components/core/button';
import reseedMessagesFormObject from './ReseedMessagesFormObject';
import useReseedMessages from './useReseedMessages';
import Form from 'components/core/form';

const ReseedMessages = (): React.Node => {
    const formRef = React.useRef();
    const reseedMessagesMutation = useReseedMessages();

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const onSubmit = (data) => {
        const {
            formData: { queueName, namespace, messageIds },
        } = data;
        reseedMessagesMutation.mutate({
            queueName,
            namespace,
            reseedRequest: { messageIds },
        });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
        >
            <Form
                section={reseedMessagesFormObject}
                ref={formRef}
                onSubmit={onSubmit}
            />
            <ApiButton
                apiStatus={reseedMessagesMutation.status}
                successMessage={`${reseedMessagesMutation.data?.successCount} messages reseeded`}
                errorMessage={reseedMessagesMutation.error?.message}
                onClick={triggerClick}
            />
        </Box>
    );
};

export default ReseedMessages;
