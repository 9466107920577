//@flow

import { type FormSectionType } from 'models/FormSection';
import { type FormFieldType } from 'models/FormFieldType';

import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { WorkflowTypeEnum } from 'enums/WorkflowTypeEnum';
import localeValues from 'resources/localeValues.json';

const countries = Object.keys(localeValues).reduce((countries, countryName) => {
    const { code } = localeValues[countryName];
    return countries.concat({
        id: code,
        label: countryName,
        value: code,
    });
}, []);

const basicClientFields: FormFieldType = [
    {
        id: 'clientId',
        type: 'FIELD',
        fieldType: FormFieldTypeEnum.TEXT_FIELD,
        title: 'Client Id',
        required: true,
    },
    {
        id: 'name',
        type: 'FIELD',
        fieldType: FormFieldTypeEnum.TEXT_FIELD,
        title: 'Client Name',
        required: true,
    },
    {
        id: 'clientWebsite',
        type: 'FIELD',
        fieldType: FormFieldTypeEnum.TEXT_FIELD,
        title: 'Client Website',
        required: true,
    },
    {
        id: 'domains',
        type: 'ARRAY',
        title: 'Domains',
        items: {
            type: 'FIELD',
            id: 'domain',
            fieldType: FormFieldTypeEnum.TEXT_AREA,
            title: 'Domain',
            helpText:
                'Only the above domains will be accepted in personnel emails. Domains can be updated at a later.',
        },
        minItems: 1,
    },
];

const createGenericClientFormObject: FormSectionType = {
    title: 'CREATE CLIENT',
    id: 'createClient',
    type: 'SECTION',
    subSections: [
        ...basicClientFields,
        {
            type: 'FIELD',
            id: 'enableTaskAutoAccept',
            title: 'Enable Task Auto Accept',
            fieldType: FormFieldTypeEnum.CHECKBOX,
        },
        {
            type: 'FIELD',
            id: 'workflowType',
            title: 'Workflow Type',
            description: 'Workflows that clients execute on Locus',
            fieldType: FormFieldTypeEnum.SELECT,
            options: {
                itemsType: FormPropertyTypeEnum.STRING,
                items: [
                    {
                        id: 'scheduled',
                        label: 'Scheduled',
                        value: WorkflowTypeEnum.SCHEDULED,
                    },
                    {
                        id: 'onDemand',
                        label: 'On Demand',
                        value: WorkflowTypeEnum.ON_DEMAND,
                    },
                    {
                        id: 'scheduledExecution',
                        label: 'Scheduled Execution',
                        value: WorkflowTypeEnum.SCHEDULED_EXECUTION,
                    },
                    {
                        id: 'scheduledExecutionOnDemand',
                        label: 'Scheduled Execution On Demand',
                        value: WorkflowTypeEnum.SCHEDULED_EXECUTION_ON_DEMAND,
                    },
                ],
            },
            required: true,
        },
        {
            type: 'FIELD',
            id: 'countryCode',
            title: 'Country',
            description: 'Country of the client',
            fieldType: FormFieldTypeEnum.SELECT,
            options: {
                itemsType: FormPropertyTypeEnum.STRING,
                items: countries,
            },
            required: true,
        },
    ],
};

const createNetOptClientFormObject = {
    title: 'CREATE NETOPT CLIENT',
    id: 'createNetOptClient',
    type: 'SECTION',
    subSections: [...basicClientFields],
};

export { createGenericClientFormObject, createNetOptClientFormObject };
