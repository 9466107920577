//@flow

import * as React from 'react';
import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core';

type Props = {
    checked?: boolean,
    label: string,
    color?: 'primary' | 'secondary' | 'default',
    onChange: (event: SyntheticEvent<>) => void,
};

const Switch = (props: Props): React.Node => {
    const { label, checked, color = 'primary', onChange } = props;

    return (
        <FormControlLabel
            control={
                <MuiSwitch
                    color={color}
                    checked={checked}
                    name={label}
                    onChange={onChange}
                />
            }
            label={label}
        />
    );
};

export default Switch;
