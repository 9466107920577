//@flow

import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import isBoolean from 'lodash/isBoolean';

import { H3, H4 } from 'components/core/typography';
import Divider from 'components/core/divider';
import { type SchemaType } from 'models/Schema';
import { type FormSectionType } from 'models/FormSection';
import FieldTemplate from './FieldTemplate';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        objectFieldWrapper: {
            border: (props) => {
                const { isRoot } = props;
                return isBoolean(isRoot) ? 'none' : `1px solid ${grey[300]}`;
            },
        },
    };
});

type FieldPropertyType = {
    content: React.Node,
    name: string,
    disabled: boolean,
    readonly: boolean,
};

type Props = {
    properties: Array<FieldPropertyType>,
    title: string,
    schema: {
        ...SchemaType,
        section: { ...FormSectionType, isRoot: boolean },
    },
};

const ObjectFieldTemplate = (props: Props): React.Node => {
    const {
        properties,
        schema: {
            section: { title, subSections, isRoot },
        },
    } = props;
    const classes = useStyles({ isRoot });
    const fieldIdElementMapping = React.useMemo(
        () =>
            properties.reduce((fieldIdElementMapping, property) => {
                const { name, content } = property;
                fieldIdElementMapping[name] = content;
                return fieldIdElementMapping;
            }, {}),
        [properties],
    );

    return (
        <Box
            className={classes.objectFieldWrapper}
            display="flex"
            flexDirection="column"
            py={isRoot ? 0 : 1}
            px={isRoot ? 0 : 2}
            width="100%"
        >
            {title && (
                <>
                    {isRoot ? <H3>{title} </H3> : <H4>{title}</H4>}
                    <Divider />
                </>
            )}
            <Box display="flex" flexDirection="column" alignItems="flex-end">
                {subSections.map((section) => {
                    const { id } = section;
                    return (
                        <FieldTemplate
                            key={id}
                            field={section}
                            fieldIdElementMapping={fieldIdElementMapping}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};
export default ObjectFieldTemplate;
