//@flow

import * as React from 'react';
import { Drawer as MuiDrawer } from '@material-ui/core';

type Props = {
    anchor?: 'left' | 'top' | 'right' | 'bottom',
    open?: boolean,
    children: React.Node,
    onClose: (event?: SyntheticEvent<>) => void,
};

const Drawer = (props: Props): React.Node => {
    const { open = false, anchor = 'right', children, onClose } = props;
    return (
        <MuiDrawer anchor={anchor} open={open} onClose={onClose}>
            {children}
        </MuiDrawer>
    );
};

export default Drawer;
