// @flow
import { useQuery, type QueryResult } from 'react-query';
import { internalApiInstance } from 'middleware/axios';
import { type IdGeneratorPayloadType } from 'models/apps/client/IdGenerator';

export default function useIdGenerator({
    clientId,
}: {
    clientId: string,
}): QueryResult<IdGeneratorPayloadType> {
    return useQuery({
        queryKey: ['clients', clientId, 'id-template-search'],
        queryFn: () => {
            return internalApiInstance
                .post(`/v1/client/${clientId}/id-template-search`, {})
                .then((response) => {
                    return response.data;
                });
        },
    });
}
