//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';

const reseedMessagesFormObject: FormSectionType = {
    id: 'reseedMessages',
    title: 'Reseed Messages',
    type: 'SECTION',
    subSections: [
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'namespace',
            title: 'Namespace',
            required: true,
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'queueName',
            title: 'Queue Name',
            required: true,
        },
        {
            type: 'ARRAY',
            id: 'messageIds',
            title: 'Message Ids',
            items: {
                id: 'messageId',
                title: 'Message Id',
                type: 'FIELD',
                fieldType: FormFieldTypeEnum.TEXT_FIELD,
            },
            minItems: 1,
        },
    ],
};

export default reseedMessagesFormObject;
