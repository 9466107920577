// @flow
import {
    type FormDataType,
    type IdGeneratorSubsectionType,
    type FormDataPatternArrayType,
    type DateTimePatternDataType,
} from 'models/apps/client/IdGenerator';
import { random } from 'lodash';

const processPatternArray = (patternArray) => {
    return patternArray.map(({ patternType, patternData }) => {
        if (patternType === 'FIXED') {
            return { patternType, FIXED: patternData };
        }
        if (patternType === 'NUMBER') {
            return { patternType, NUMBER: patternData };
        }
        if (patternType === 'DATE_TIME') {
            return {
                patternType,
                DATE_TIME: patternData,
            };
        }
    });
};

export function processInitialData(
    initialData: Array<IdGeneratorSubsectionType>,
): FormDataType {
    return initialData.reduce((acc, curr) => {
        if (curr.entityType === 'TOUR') {
            return {
                ...acc,
                tourIdStatus: curr.status,
                tourIdPatternArray: processPatternArray(curr.patternArray),
            };
        }
        return acc;
    }, {});
}

const getDateTimePreviewData = ({
    sequence,
    separator = '',
}: DateTimePatternDataType) => {
    const date = new Date();
    const dateMappingObject = {
        YYYY: date.getFullYear(),
        YY: date.getFullYear().toString().substr(-2),
        MM: (date.getMonth() + 1).toString().padStart(2, '0'),
        dd: date.getDate().toString().padStart(2, '0'),
        hh: date.getHours().toString().padStart(2, '0'),
        mm: date.getMinutes().toString().padStart(2, '0'),
        ss: date.getSeconds().toString().padStart(2, '0'),
    };
    return sequence
        .map((element) => dateMappingObject[element])
        .join(separator);
};

export function generatePreview(
    patternArray: Array<FormDataPatternArrayType>,
): string {
    return patternArray
        .map((pattern) => {
            switch (pattern.patternType) {
                case 'NUMBER': {
                    const { min, max } = pattern.NUMBER;
                    const { length } = max.toString();
                    return random(min, max, false)
                        .toString()
                        .padStart(length, '0');
                }
                case 'FIXED': {
                    return pattern.FIXED.pattern;
                }
                case 'DATE_TIME': {
                    return getDateTimePreviewData(pattern.DATE_TIME);
                }
                default:
                    return '';
            }
        })
        .join('');
}
