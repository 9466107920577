//@flow
import * as React from 'react';
import produce from 'immer';
import filter from 'lodash/filter';
import xor from 'lodash/xor';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Button from 'components/core/button';
import List, { type ListItemsType } from 'components/core/list';

const sizes = {
    medium: {
        height: '250px',
        width: '500px',
    },
};

type Value = {
    left: ListItemsType,
    right: ListItemsType,
};

type Props = {
    value: Value,
    onChange: (value: Value) => void,
    size?: string,
};

const useStyles = makeStyles({
    root: {
        margin: 'auto',
    },
    button: {
        margin: '4px',
    },
    transferListWrap: ({ size = 'medium' }: Props) => sizes[size],
});

const TransferList = (props: Props): React.Node => {
    const { value, onChange } = props;
    const classes = useStyles(props);

    const handleLeftListItemClick = (id, checked) => {
        const updatedLeftList = findAndUpdateChecked(value.left, id, checked);
        onChange({ ...value, left: updatedLeftList });
    };

    const handleRightListItemClick = (id, checked) => {
        const updatedRightList = findAndUpdateChecked(value.right, id, checked);
        onChange({ ...value, right: updatedRightList });
    };

    const findAndUpdateChecked = (list, id, checked) => {
        return produce(list, (draft) => {
            const itemIndex = draft.findIndex((item) => item.id === id);
            draft[itemIndex].checked = !checked;
        });
    };

    const moveLeft = () => {
        const { updatedSourceList, updatedDestinationList } = updateMovedLists(
            value.right,
            value.left,
        );
        onChange({ left: updatedDestinationList, right: updatedSourceList });
    };

    const moveRight = () => {
        const { updatedSourceList, updatedDestinationList } = updateMovedLists(
            value.left,
            value.right,
        );
        onChange({ left: updatedSourceList, right: updatedDestinationList });
    };

    const updateMovedLists = (sourceList, destinationList) => {
        const checkedSourceListItems = filter(sourceList, 'checked');
        const updatedSourceList = xor(sourceList, checkedSourceListItems);
        const addedDestinationListItems = checkedSourceListItems.map(
            (item) => ({
                ...item,
                checked: !item.checked,
            }),
        );
        const updatedDestinationList = destinationList.concat(
            addedDestinationListItems,
        );
        return { updatedSourceList, updatedDestinationList };
    };

    const moveAllLeft = () => {
        onChange({ left: [...value.left, ...value.right], right: [] });
    };

    const moveAllRight = () => {
        onChange({ left: [], right: [...value.right, ...value.left] });
    };

    return (
        <Box
            className={classes.transferListWrap}
            display="flex"
            flexDirection="row"
        >
            <Box width="40%">
                <List items={value.left} onClick={handleLeftListItemClick} />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="20%"
            >
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={moveAllRight}
                    disabled={value.left.length === 0}
                >
                    &#8811;
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={moveRight}
                >
                    &gt;
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={moveLeft}
                >
                    &lt;
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={moveAllLeft}
                    disabled={value.right.length === 0}
                >
                    &#8810;
                </Button>
            </Box>
            <Box width="40%">
                <List items={value.right} onClick={handleRightListItemClick} />
            </Box>
        </Box>
    );
};

export default TransferList;
