// @flow

export type AxiosError = {
    message?: string,
    response?: {
        data:
            | {
                  message?: string,
              }
            | string,
    },
};

class ErrorUtils {
    static extractErrorMessage(error: AxiosError): string {
        const { message, response } = error;
        if (!response) return message;
        const {
            data: { message: errorMessage },
            data,
        } = response;
        return errorMessage || data;
    }
}

export default ErrorUtils;
