//@flow

import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { BaseTable } from 'components/core/table';
import Button from 'components/core/button';
import { Body2, H3 } from 'components/core/typography';
import TableUtils from 'utils/TableUtils';
import { RecordForm, type SubmitData, type Mode } from './RecordForm';
import { useTableInstance } from '../useTableInstance';
import { type FormSectionType } from 'models/FormSection';

const useStyles = makeStyles({
    headingRowWrap: {
        gap: '8px',
    },
});

type Props<T> = {
    // Defaults to section's  header if not provided
    header?: string,
    canAddItem?: boolean,
    canEditItem?: boolean,
    canDeleteItem?: boolean,
    section: FormSectionType,
    data: Array<T>,
    idField: string,
    onDelete?: (rowIds: Array<string>) => void,
    width?: string,
    hiddenColumns?: Array<string>,
    onSubmit?: (data: SubmitData<T>, mode: Mode) => void,
    hiddenColumns?: Array<string>,
};

export const EditableTable = <T>(props: Props<T>): React.Node => {
    const {
        header,
        idField,
        canAddItem = true,
        canEditItem = true,
        canDeleteItem = true,
        section,
        data,
        onSubmit,
        onDelete,
        width,
        hiddenColumns,
    } = props;
    const classes = useStyles();
    const columns = React.useMemo(() => TableUtils.buildTableColumns(section), [
        section,
    ]);
    const [formInfo, setFormInfo] = React.useState({ visible: false });
    const tableHeader = header || section.title;
    const tableInstance = useTableInstance({
        columns,
        data,
        idField,
        hiddenColumns,
        rowSelection: canEditItem || canDeleteItem,
    });
    const { selectedRowIds: rowIdsObj } = tableInstance.state;
    const selectedRowIds = rowIdsObj ? Object.keys(rowIdsObj) : [];
    const showEdit = canEditItem && selectedRowIds.length === 1;
    const showDelete = canDeleteItem && selectedRowIds.length !== 0;

    const onEditClick = () => {
        const [row] = tableInstance.selectedFlatRows;
        setFormInfo({ visible: true, formData: row.original, mode: 'EDIT' });
    };

    const onRecordFormClose = () => {
        tableInstance.toggleAllRowsSelected(false);
        setFormInfo({ visible: false });
    };

    return (
        <>
            <Box
                className={classes.headingRowWrap}
                display="flex"
                flexDirection="row"
                pt={2}
                width="100%"
            >
                <Box flexGrow="1">
                    <H3>{tableHeader}</H3>
                </Box>
                {showEdit && (
                    <Button
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={onEditClick}
                    >
                        <Body2>EDIT</Body2>
                    </Button>
                )}
                {showDelete && (
                    <Button
                        color="primary"
                        startIcon={<DeleteIcon />}
                        onClick={() => onDelete(selectedRowIds)}
                    >
                        <Body2>DELETE</Body2>
                    </Button>
                )}
                {canAddItem && (
                    <Button
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() =>
                            setFormInfo({ visible: true, mode: 'ADD' })
                        }
                    >
                        <Body2>ADD NEW</Body2>
                    </Button>
                )}
            </Box>
            {formInfo.visible && (
                <RecordForm
                    onSubmit={onSubmit}
                    section={section}
                    onClose={onRecordFormClose}
                    formInfo={formInfo}
                />
            )}
            <Box overflow="auto" width={width} my={3}>
                <BaseTable tableInstance={tableInstance} idField={idField} />
            </Box>
        </>
    );
};
