// @flow
import * as React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { default as Tooltip } from 'components/core/tooltip';

type OptionsType = {
    id: string,
    label: string,
};

type Props = {
    options: Array<OptionsType>,
    onClick: (id: string) => void,
    color?: string,
    size?: 'small' | 'medium' | 'large',
    disabled?: boolean,
};

const SplitButton = ({
    options,
    onClick,
    color = 'primary',
    size = 'small',
    disabled,
}: Props): React.Node => {
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const anchorRef = React.useRef(null);

    const handleClick = () => {
        onClick(options[selectedIndex].id);
    };

    const handleMenuItemClick = (_, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Tooltip
                title="Please use internal dashboard to create client"
                placement="bottom"
            >
                <ButtonGroup
                    variant="contained"
                    color={color}
                    ref={anchorRef}
                    disabled={disabled}
                >
                    <Button size={size} onClick={handleClick}>
                        {options[selectedIndex].label}
                    </Button>
                    <Button color={color} size={size} onClick={handleToggle}>
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
            </Tooltip>
            <Popper open={open} anchorEl={anchorRef.current}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                                <MenuItem
                                    key={option.id}
                                    selected={index === selectedIndex}
                                    onClick={(event) =>
                                        handleMenuItemClick(event, index)
                                    }
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};

export default SplitButton;
