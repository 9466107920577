//@flow
import concat from 'lodash/concat';

import { type OptionsListType } from 'components/core/select';

const allFilterValue = {
    id: 'all',
    value: 'all',
    label: 'All',
};

export const statusOptions: OptionsListType = Object.freeze([
    { id: 'enabled', value: 'ENABLED', label: 'Enabled' },
    { id: 'disabled', value: 'DISABLED', label: 'Disabled' },
]);

export const stageOptions: OptionsListType = Object.freeze([
    { id: 'test', value: 'TEST', label: 'Test' },
    { id: 'demo', value: 'DEMO', label: 'Demo' },
    { id: 'demo-internal', value: 'DEMO_INTERNAL', label: 'Demo Internal' },
    { id: 'devo', value: 'DEVO', label: 'Devo' },
    { id: 'pilot', value: 'PILOT', label: 'Pilot' },
    { id: 'pre-prod', value: 'PRE_PROD', label: 'Pre Prod' },
    { id: 'prod', value: 'PROD', label: 'Prod' },
    { id: 'prod-plus', value: 'PROD_PLUS', label: 'Prod Plus' },
]);

export const statusOptionsFilters = concat(statusOptions, allFilterValue);
export const stageOptionsFilters = concat(stageOptions, allFilterValue);
