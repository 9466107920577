// @flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { history } from 'store/configureStore';
import { getItem } from './Tabs';
import LeftBar from 'containers/leftBar';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        appItemsContainer: {
            backgroundColor: grey[100],
        },
        appItemsMainContainerWrap: {
            border: `1px solid ${grey[200]}`,
            height: '100%',
        },
    };
});

type Props = {};

const ClientAppItemsContainer = (props: Props): React.Node => {
    const {
        match: {
            params: { clientId, appId, itemId },
        },
        tab,
    } = props;
    const classes = useStyles();
    const item = getItem(appId, itemId);

    if (!item) {
        return <Redirect to="/no-match" />;
    }

    const { component: Component, ...restComponentProps } = item;
    const onListItemClick = (itemId) => {
        return history.replace(
            `/client/${clientId}/app/${appId}/item/${itemId}`,
        );
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            className={classes.appItemsContainer}
            p={2}
        >
            <LeftBar
                selectedItemId={itemId}
                items={tab.items}
                onListItemClick={onListItemClick}
            />
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                px={3}
                className={classes.appItemsMainContainerWrap}
            >
                <Component {...restComponentProps} />
            </Box>
        </Box>
    );
};

export default ClientAppItemsContainer;
