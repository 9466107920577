//@flow
import * as React from 'react';
import startCase from 'lodash/startCase';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Select from 'components/core/select';
import Button from 'components/core/button';
import { customFieldsFormObject } from './CustomFieldsFormObject';
import { EditableTable } from 'components/core/table';
import { useUpdateCustomFields } from 'hooks/client';
import { useToast } from 'containers/toast/Toast';
import { type EntitySettingsType } from 'models/apps/client';
import invariant from 'utils/invariant';

const useStyles = makeStyles({
    formWrapper: {
        gap: '16px',
    },
    tableWrap: {
        overflow: 'auto',
    },
});

type Props = {
    clientId: string,
    data: EntitySettingsType,
};

export const ManageCustomFieldsForm = (props: Props): React.Node => {
    const { clientId, data: entitySettings } = props;
    const idField = 'key';
    const classes = useStyles();
    const [showCustomFields, setShowCustomFields] = React.useState(false);
    const [entityId, setEntityId] = React.useState();

    const { showToast } = useToast();
    const updateCustomFieldsMutation = useUpdateCustomFields();
    const items = React.useMemo(
        () =>
            Object.keys(entitySettings).map((id) => ({
                id,
                value: id,
                label: startCase(id),
            })),
        [entitySettings],
    );
    const onEntityChange = (value) => {
        setEntityId(value);
        setShowCustomFields(false);
    };

    const customFields = entityId ? entitySettings[entityId].customFields : [];

    const onDelete = (selectedItemIds) => {
        const updatedCustomFields = customFields.filter(
            (field) => !selectedItemIds.includes(field[idField]),
        );
        const reason = 'Deleting custom field(s) from internal dashboard v2';
        const successMessage = 'Custom Field(s) deleted successfully';
        updateCustomFields({ updatedCustomFields, reason, successMessage });
    };

    const onSubmit = (data) => {
        const { reason, ...formData } = data.formData;
        const updatedCustomFields = fetchUpdatedCustomFields(formData);
        const successMessage = 'Custom Field added/edited successfully';
        updateCustomFields({ updatedCustomFields, reason, successMessage });
    };

    const fetchUpdatedCustomFields = (data) => {
        const index = customFields.findIndex(
            (customField) => customField[idField] === data[idField],
        );
        if (index === -1) {
            return [...customFields, data];
        }
        return customFields.map((customField, loopIndex) => {
            return index === loopIndex ? data : customField;
        });
    };

    const updateCustomFields = ({
        updatedCustomFields,
        reason,
        successMessage,
    }) => {
        invariant(
            entityId,
            'Expected entity id to be present for updating custom fields',
        );
        updateCustomFieldsMutation.mutate(
            {
                reason,
                entityId,
                clientId,
                customFields: updatedCustomFields,
            },
            {
                onSuccess: () => showToast(successMessage),
                onError: (err) => {
                    showToast(err.message);
                },
            },
        );
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            className={classes.formWrapper}
            minHeight="70vh"
        >
            <Select items={items} onChange={onEntityChange} value={entityId} />
            <Box width="100%" textAlign="center">
                <Button
                    type="submit"
                    size="small"
                    color="primary"
                    onClick={() => setShowCustomFields(true)}
                >
                    SUBMIT
                </Button>
            </Box>
            {showCustomFields && (
                <Box className={classes.tableWrap} width="100%">
                    <EditableTable
                        header="Custom Fields"
                        idField={idField}
                        section={customFieldsFormObject}
                        data={customFields}
                        onDelete={onDelete}
                        onSubmit={onSubmit}
                        hiddenColumns={['reason']}
                        width="65vw"
                    />
                </Box>
            )}
        </Box>
    );
};
