//@flow

import * as React from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';

type Props = {
    label: string,
    checked?: boolean,
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom',
    color?: 'primary' | 'secondary' | 'default',
    onChange: (checked: boolean, event: Event) => void,
    size?: 'small' | 'medium',
};

const Checkbox = (props: Props): React.Node => {
    const {
        label,
        checked,
        color = 'primary',
        labelPlacement,
        onChange,
        size,
    } = props;

    const onCheckboxClick = (event, isChecked: boolean) => {
        onChange(isChecked, event);
    };

    return (
        <FormControlLabel
            checked={checked}
            label={label}
            control={<MuiCheckbox disableRipple color={color} size={size} />}
            labelPlacement={labelPlacement}
            onChange={onCheckboxClick}
        />
    );
};

export default Checkbox;
