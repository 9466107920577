//@flow

import * as React from 'react';
import classnames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Body1 } from 'components/core/typography';

const useStyles = makeStyles((theme) => {
    const {
        palette: { primary, grey },
    } = theme;
    return {
        listItem: {
            borderTop: `1px solid ${grey[200]}`,
            borderColor: grey[200],
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px',
            width: '185px',
            '&:last-child': {
                borderBottom: `1px solid ${grey[200]}`,
            },
            '&:hover': {
                cursor: 'pointer',
            },
        },
        selectedListItem: {
            borderLeft: `2px solid ${primary.main}`,
        },
    };
});

type LeftBarItemTypes = {
    id: number,
    label: number | string,
};

type Props = {
    selectedItemId: number,
    items: Array<LeftBarItemTypes>,
    onListItemClick: (id: number) => void,
};
const LeftBar = (props: Props) => {
    const { selectedItemId, items, onListItemClick } = props;
    const classes = useStyles();
    return (
        <Box display="flex" bgcolor="background.paper">
            <List disablePadding>
                {items.map((item) => {
                    const { id, label } = item;
                    const selected = id === selectedItemId;
                    return (
                        <ListItem
                            key={id}
                            className={classnames(classes.listItem, {
                                [classes.selectedListItem]: selected,
                            })}
                            onClick={() => onListItemClick(id)}
                        >
                            <Body1> {label}</Body1>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default LeftBar;
