//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';

import Loader from 'components/core/loader';
import Divider from 'components/core/divider';
import { H3, Body1 } from 'components/core/typography';
import useGetProviders from './useGetProviders';
import { ApiStatusEnum } from 'enums/API';
import ErrorContainer from 'containers/ErrorContainer';
import LinkProviderForm from './LinkProviderForm';

const LinkProvider = (): React.Node => {
    const getProvidersQuery = useGetProviders();
    if (getProvidersQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            p={1}
        >
            <Box
                display="flex"
                flexDirection="column"
                width="50%"
                alignItems="center"
            >
                <Box width="100%" pb={1}>
                    <H3> Link Provider </H3>
                    <Divider />
                </Box>
                {getProvidersQuery.status === ApiStatusEnum.Error ? (
                    <ErrorContainer message={getProvidersQuery.error.message} />
                ) : getProvidersQuery.data.length === 0 ? (
                    <Box width="100%">
                        <Body1> No Providers Found</Body1>
                    </Box>
                ) : (
                    <LinkProviderForm providers={getProvidersQuery.data} />
                )}
            </Box>
        </Box>
    );
};

export default LinkProvider;
