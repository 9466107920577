//@flow

import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { DeleteIcon } from 'components/core/icons';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        arrayFieldWrap: {
            border: `1px solid ${grey[200]}`,
        },
    };
});

export type ArrayItemType = {
    children: React.Node,
    hasRemove: boolean,
    index: number,
    onDropIndexClick: (index: number) => (event: SyntheticEvent<>) => void,
};

const ArrayItemTemplate = (props: ArrayItemType): React.Node => {
    const { index, children, hasRemove, onDropIndexClick } = props;
    const classes = useStyles();
    return (
        <Box
            className={classes.arrayFieldWrap}
            display="flex"
            flexDirection="row"
            p={2}
            my={1}
        >
            <Box flexGrow={1}>{children}</Box>
            {hasRemove && (
                <Box px={2}>
                    <DeleteIcon onClick={onDropIndexClick(index)} />
                </Box>
            )}
        </Box>
    );
};

export default ArrayItemTemplate;
