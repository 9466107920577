//@flow
import * as React from 'react';
import { Box } from '@material-ui/core';
import matchSorter from 'match-sorter';

import { FormFieldTemplate } from 'components/core/form-templates';
import { SelectWithAutoComplete } from 'components/core/select';
import { type BusinessUnitType } from 'models/apps/businessUnit';

type Props = {
    businessUnits: Array<BusinessUnitType>,
    value: string,
    onChange: (businessUnitId: string) => void,
};

const BUSelect = (props: Props): React.Node => {
    const { businessUnits, value, onChange } = props;

    const buOptions = React.useMemo(() => {
        return businessUnits.map((businessUnit) => {
            const { businessUnitId, name, description } = businessUnit;
            return {
                id: businessUnitId,
                label: name,
                value: businessUnitId,
                description,
            };
        });
    }, [businessUnits]);

    const filterOptions = (options, { inputValue }) =>
        matchSorter(options, inputValue, { keys: ['label', 'description'] });

    return (
        <FormFieldTemplate id="businessUnit" label="Business Unit">
            Business Unit
            <SelectWithAutoComplete
                value={value}
                items={buOptions}
                onChange={onChange}
                filterOptions={filterOptions}
                renderOption={(option) => {
                    const { label, description } = option;
                    return (
                        <Box display="flex" flexDirection="column">
                            <Box fontWeight="fontWeightBold"> {label}</Box>
                            <Box fontWeight="fontWeightRegular">
                                {description}
                            </Box>
                        </Box>
                    );
                }}
            />
        </FormFieldTemplate>
    );
};

export default BUSelect;
