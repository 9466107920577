//@flow

export const ChecklistFormatType = Object.freeze({
    BOOLEAN: 'BOOLEAN',
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    TEXT_FIELD: 'TEXT_FIELD',
    PIN: 'PIN',
    SIGNATURE: 'SIGNATURE',
    PHOTO: 'PHOTO',
    RATING: 'RATING',
    URL: 'URL',
});

export type ChecklistFormatEnumType = $Values<typeof ChecklistFormatType>;
