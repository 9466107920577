//@flow

import moment from 'moment-timezone';

export const DateTimeFormat = {
    UTC_FORMAT_WITH_MILLIS: 'YYYY-MM-DDTHH:mm:ss.SSSZZ',
};

class TimeUtils {
    /**
     * Gets the local date/time from the given moment &
     * converts it to a different timezone.
     * Uses moment.tz(..., timezone)
     */
    static convertToTimezone({
        dateTime,
        timezone,
        format = DateTimeFormat.UTC_FORMAT_WITH_MILLIS,
    }: {
        dateTime: string,
        timezone: string,
        format?: string,
    }) {
        return moment.tz(dateTime, format, timezone);
    }

    static convertToTimezoneString({
        dateTime,
        timezone,
        format = DateTimeFormat.UTC_FORMAT_WITH_MILLIS,
    }: {
        dateTime: string,
        timezone: string,
        format?: string,
    }): string {
        const updatedTimezone = TimeUtils.convertToTimezone({
            dateTime,
            timezone,
            format,
        });
        return updatedTimezone.toString();
    }

    static now() {
        return moment();
    }

    static add({
        date,
        period,
        unit = 'days',
    }: {
        date: string,
        period: number,
        unit?: string,
    }) {
        return moment(date).add(period, unit);
    }
}
export default TimeUtils;
