//@flow

import * as React from 'react';

import Loader from 'components/core/loader';
import { useBUSettings } from 'hooks/businessUnit';
import { ApiStatusEnum } from 'enums/API';
import Alert from '@material-ui/lab/Alert';
import UpdateBUSettingsForm from './UpdateBUSettingsForm';

type Props = {
    clientId: string,
    businessUnitId: string,
};

const BUSettingsForm = (props: Props): React.Node => {
    const { clientId, businessUnitId } = props;
    const buSettingsQuery = useBUSettings({
        clientId,
        businessUnitId,
    });

    if (buSettingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    if (buSettingsQuery.status === ApiStatusEnum.Error) {
        return (
            <Box pb={1} width="100%">
                <Alert severity="error">{buSettingsQuery.error.message}</Alert>
            </Box>
        );
    }

    return (
        <UpdateBUSettingsForm
            clientId={clientId}
            businessUnitId={businessUnitId}
            settings={buSettingsQuery.data}
        />
    );
};

export default BUSettingsForm;
