// @flow
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#70b3ff',
            main: '#0072f5',
            dark: '#0056b8',
        },
        secondary: {
            light: '#f3f4f6',
            main: '#8992a9',
            dark: '#343947',
            contrastText: '#ffffff',
        },
        white: {
            light: '#ffffff',
            main: '#ffffff',
        },
        grey: {
            100: '#f3f4f6',
            200: '#e7e9ee',
            300: '#d0d3dd',
            400: '#acb2c3',
            500: '#8992a9',
            600: '#67718e',
            700: '#4d556a',
            800: '#343947',
            900: '#111318',
        },
        text: {
            secondary: '#67718E',
        },
    },
    typography: {
        fontFamily: 'Open Sans, Helvetica Neue, Arial, Helvetica, sans-serif;',
        h1: {
            fontSize: '1.75rem',
            lineHeight: '2.5rem',
            fontWeight: 500,
            letterSpacing: '0.05rem',
        },
        h2: {
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            fontWeight: 600,
            letterSpacing: '0.01rem',
        },
        h3: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            letterSpacing: '0.01rem',
        },
        h4: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 500,
            letterSpacing: '0.01rem',
        },
        body1: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontWeight: 400,
            letterSpacing: '0.01rem',
        },
        body2: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontWeight: 500,
            letterSpacing: '0.01rem',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: '3px',
                    height: '1px',
                    borderRadius: '2rem',
                },
                '*::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#111318',
            },
            colorDefault: {
                backgroundColor: '#ffffff',
            },
        },
    },
});

export default theme;
