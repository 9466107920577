//@flow

import * as React from 'react';
import { type TextWidgetProps } from 'components/core/widgets/text-field';

import { TextWidget } from 'components/core/widgets';

const DateWidget = (props: TextWidgetProps): React.Node => (
    <TextWidget {...props} type="date" />
);

export default DateWidget;
