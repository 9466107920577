//@flow
import * as React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Button from 'components/core/button';
import ComponentWithDropdown from 'components/core/componentWithDropdown';

import useOncalls from './duck/useOncalls';

const anchorOrigin = { vertical: 'bottom', horizontal: 'left' };
const transformOrigin = { vertical: 'top', horizontal: 'left' };

const Oncalls = (): React.Node => {
    const oncallsQuery = useOncalls({ fetchOnMount: false });

    const menuItems =
        oncallsQuery.data?.map((oncall, index) => {
            const { id, teamName, teamOncall } = oncall;
            return {
                id: `${id}_${index}`,
                label: `${teamName} - ${teamOncall}`,
            };
        }) || [];

    return (
        <ComponentWithDropdown
            component={Button}
            status={oncallsQuery.status}
            error={oncallsQuery.error?.message}
            popupProps={{ popupId: 'oncallListPopup', variant: 'popover' }}
            menuProps={{
                anchorOrigin,
                transformOrigin,
                menuItems,
                PaperProps: {
                    style: {
                        maxHeight: '400px',
                    },
                },
            }}
            componentProps={{
                label: 'Oncalls',
                size: 'small',
                variantColor: 'grey',
                endIcon: <ExpandMore />,
                onClick: () => {
                    oncallsQuery.refetch();
                },
            }}
        />
    );
};

export default Oncalls;
