// @flow
import * as React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { useAuth0 } from 'auth/AuthHelper';
import { LoaderWithBackdrop } from 'components/core/loader';
import ErrorContainer from 'containers/ErrorContainer';
import useSession from 'hooks/session/useSession';

import { ApiStatusEnum } from 'enums/API';

const PrivateRoute = (props) => {
    const { component: Component, path, ...restProps } = props;
    const { loading, isAuthenticated } = useAuth0();
    const sessionQuery = useSession({
        enabled: !loading && isAuthenticated,
    });

    if (loading || sessionQuery.status === ApiStatusEnum.Loading) {
        return <LoaderWithBackdrop />;
    }

    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }

    if (sessionQuery.status === ApiStatusEnum.Error) {
        return <ErrorContainer message={sessionQuery.error.message} />;
    }

    if (sessionQuery.data.personnel.clientId !== 'mara') {
        return (
            <ErrorContainer
                message={
                    'Seems like you do not have the required permissions to access this page.'
                }
            />
        );
    }
    // Rest props with location, match etc are passed for path matching in the child components
    return <Route path={path} component={Component} {...restProps} />;
};

export default withRouter(PrivateRoute);
