//@flow
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { H3 } from 'components/core/typography';
import idGeneratorFormObject from './IdGeneratorFormObject';
import Form from 'components/core/form';
import { ApiButton, Button } from 'components/core/button';
import Loader from 'components/core/loader';
import { type FormDataType } from 'models/apps/client/IdGenerator';
import { generatePreview, processInitialData } from './IdGeneratorUtils';
import ErrorContainer from 'containers/ErrorContainer';
import { ApiStatusEnum } from 'enums/API';
import useIdGenerator from './useIdGenerator';
import useUpdateIdGenerator from './useUpdateIdGenerator';
type Props = {
    label: string,
};

const IdGeneratorContainer = (props: Props): React.Node => {
    const { label } = props;
    const [tourIdPreview, setTourIdPreview] = React.useState('');
    const formRef = React.useRef();
    const { clientId } = useParams();
    const getIdGeneratorQuery = useIdGenerator({ clientId });
    const updateIdGeneratorMutation = useUpdateIdGenerator();

    const onSubmit = ({ formData }: { formData: FormDataType }) => {
        const { tourIdStatus, tourIdPatternArray } = formData;
        const processedTourIdPatternArray = tourIdPatternArray.map(
            (pattern) => {
                const { patternType, ...others } = pattern;
                const [patternData] = Object.values(others);
                return {
                    patternType,
                    patternData,
                };
            },
        );
        const payload = {
            templates: [
                {
                    status: tourIdStatus,
                    entityType: 'TOUR',
                    patternArray: processedTourIdPatternArray,
                },
            ],
        };
        updateIdGeneratorMutation.mutate({ clientId, payload });
    };

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const getPreview = () => {
        if (!formRef.current) {
            return;
        }
        const {
            tourIdPatternArray,
            tourIdStatus,
        } = formRef.current.state.formData;
        if (tourIdStatus === 'ACTIVE') {
            const tourIdPreview = generatePreview(tourIdPatternArray);
            setTourIdPreview(tourIdPreview);
        } else {
            setTourIdPreview('');
        }
    };

    if (getIdGeneratorQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    if (getIdGeneratorQuery.status === ApiStatusEnum.Error) {
        return <ErrorContainer message={getIdGeneratorQuery.error.message} />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            height="100%"
        >
            <Box
                width="50%"
                minWidth="400px"
                display="flex"
                flexDirection="column"
            >
                <Box p={1} alignSelf="center">
                    <H3>{label}</H3>
                </Box>
                <Box width="100%" flex="1 1 0">
                    <Form
                        ref={formRef}
                        section={idGeneratorFormObject}
                        onSubmit={onSubmit}
                        initialValue={processInitialData(
                            getIdGeneratorQuery.data.templates,
                        )}
                    />
                </Box>
                <Box
                    alignSelf="flex-end"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                >
                    {tourIdPreview ? <Box>Tour ID: {tourIdPreview}</Box> : null}
                    <Button size="small" color="primary" onClick={getPreview}>
                        Get Preview
                    </Button>
                    <ApiButton
                        apiStatus={updateIdGeneratorMutation.status}
                        successMessage="ID updated successfully"
                        errorMessage={updateIdGeneratorMutation.error?.message}
                        onClick={triggerClick}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default IdGeneratorContainer;
