//@flow

import produce from 'immer';
import { ToastActions } from './actions';
import { type ActionType } from 'models/redux';
import { ToastType, type ToastTypeEnumType } from 'models/Toast';

export type State = {
    open: boolean,
    message?: string,
    toastDuration?: string,
    type: ToastTypeEnumType,
};

const initialState: State = { open: false, type: ToastType.DEFAULT };

export const ToastReducer: (
    state: State,
    action: ActionType,
) => State = produce<State, ActionType>((draft, action) => {
    switch (action.type) {
        case ToastActions.SHOW_TOAST: {
            const {
                payload: { message, type, toastDuration },
            } = action;
            return { open: true, message, type, toastDuration };
        }
        case ToastActions.HIDE_TOAST: {
            draft.open = false;
            break;
        }
    }
}, initialState);
