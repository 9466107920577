//@flow

import * as React from 'react';
import {
    Stepper as MuiStepper,
    Step,
    StepLabel,
    makeStyles,
} from '@material-ui/core';

export type StepType = {
    label: string,
    id: string,
    completed?: boolean,
};

type Props = {
    activeStepIndex: number,
    steps: Array<StepType>,
};

const useStyles = makeStyles({
    stepperWrap: {
        padding: '16px',
    },
});

const Stepper = (props: Props): React.Node => {
    const { steps, activeStepIndex } = props;
    const classes = useStyles();

    return (
        <MuiStepper
            className={classes.stepperWrap}
            activeStep={activeStepIndex}
        >
            {steps.map((step) => {
                const { label, id, completed } = step;
                return (
                    <Step key={id} completed={completed}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                );
            })}
        </MuiStepper>
    );
};

export default Stepper;
