//@flow
import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';

export const numberSubSectionFormObject: FormSectionType = {
    id: 'NUMBER',
    type: 'SECTION',
    subSections: [
        {
            id: 'min',
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.NUMBER_FIELD,
            title: 'Minimum',
            required: true,
        },
        {
            id: 'max',
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.NUMBER_FIELD,
            title: 'Maximum',
            required: true,
        },
    ],
};

export default numberSubSectionFormObject;
