//@flow
import * as React from 'react';
import Box from '@material-ui/core/Box';

import { ApiButton } from 'components/core/button';
import Form from 'components/core/form';
import { createGenericClientFormObject } from './CreateClientFormObject';
import { history } from 'store/configureStore';

import useCreateClient from 'hooks/client/useCreateClient';

const AddGenericClientContainer = (): React.Node => {
    const createClientMutation = useCreateClient();

    const formRef = React.useRef();

    const onSubmit = (data) => {
        const { clientId, ...client } = data.formData;
        createClientMutation.mutate(
            { clientId, client },
            {
                onSuccess: () => {
                    history.push(`client/${clientId}/configure`);
                },
            },
        );
    };

    return (
        <Box width="400px" p={2}>
            <Form
                ref={formRef}
                section={createGenericClientFormObject}
                onSubmit={onSubmit}
            />
            <ApiButton
                apiStatus={createClientMutation.status}
                successMessage="Client Created Successfully"
                errorMessage={createClientMutation.error?.message}
                onClick={() => {
                    formRef.current?.submit();
                }}
            />
        </Box>
    );
};

export default AddGenericClientContainer;
