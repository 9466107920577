//@flow

import * as React from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';

import TextField from 'components/core/input';
import JSONEditor from 'components/core/json-editor';
import { ApiButton } from 'components/core/button';
import { Body1 } from 'components/core/typography';
import { useUpdateClientSettings } from 'hooks/client';
import { useToast } from 'containers/toast/Toast';
import { JsonEditorModeEnum } from 'enums/JsonEditorMode';
import JSONUtils from 'utils/JSONUtils';
import { type ClientSettingsType } from 'models/apps/client';

type Props = { initialValue: ClientSettingsType };

const SettingsForm = (props: Props): React.Node => {
    const { initialValue } = props;
    const [value, setValue] = React.useState(initialValue);
    const [reason, setReason] = React.useState('');
    const { clientId } = useParams();
    const { showToast } = useToast();
    const updateClientSettingsMutation = useUpdateClientSettings();
    const onClick = () => {
        updateClientSettingsMutation.mutate({
            clientId,
            settings: value,
            reason,
        });
    };
    const onChangeText = (textValue) => {
        const isValid = JSONUtils.isValidJSON(textValue);
        if (isValid) {
            const jsonValue = JSON.parse(textValue);
            setValue(jsonValue);
        } else {
            showToast('Invalid Config set in Text editor');
        }
    };

    return (
        <>
            <Box py={1} width="100%" height="50vh">
                <JSONEditor
                    mode={JsonEditorModeEnum.TREE}
                    modes={[JsonEditorModeEnum.TREE, JsonEditorModeEnum.TEXT]}
                    value={value}
                    onChangeJSON={(config) => setValue(config)}
                    onChangeText={onChangeText}
                />
            </Box>
            <Box py={1} width="100%">
                <Body1>Reason</Body1>
                <TextField
                    multiline
                    fullWidth
                    value={reason}
                    rows="4"
                    placeholder="Please provide the exact reason for this change. This will be used for dashboard audit purposes"
                    onChange={setReason}
                />
            </Box>
            <ApiButton
                apiStatus={updateClientSettingsMutation.status}
                successMessage="Settings updated successfully"
                errorMessage={updateClientSettingsMutation.error?.message}
                onClick={onClick}
                disabled={isEmpty(reason)}
            />
        </>
    );
};

export default SettingsForm;
