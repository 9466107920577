// @flow
import { useMutation, type MutationResult } from 'react-query';

import { apiInstance } from 'middleware/axios';
import { type PasswordAuthSettingsType } from 'models/apps/client';

type Variables = { clientId: string, settings: PasswordAuthSettingsType };
type Result = PasswordAuthSettings;

export default function useUpdatePasswordAuthSettings(): MutationResult<
    Variables,
    Result,
> {
    return useMutation<Variables, Result>(({ clientId, settings }) => {
        return apiInstance
            .post(`client/${clientId}/password-auth-settings`, settings)
            .then((response) => {
                return response.data;
            });
    });
}
