//@flow

import * as React from 'react';
import { FormControl } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import FormLabel from 'components/core/form-label';
import Button from 'components/core/button';
import { Body2 } from 'components/core/typography';
import { type SchemaType } from 'models/Schema';
import ArrayItemTemplate, { type ArrayItemType } from './ArrayItemTemplate';

type Props = {
    title: string,
    items: Array<{ ...ArrayItemType, key: string }>,
    canAdd: boolean,
    onAddClick: (event: SyntheticEvent<>) => void,
    schema: SchemaType,
    disabled: boolean,
};

const ArrayFieldTemplate = (props: Props): React.Node => {
    const {
        title,
        schema: { description },
        items,
        canAdd,
        onAddClick,
        disabled,
    } = props;
    return (
        <FormControl component="fieldset">
            <FormLabel label={title} description={description} />
            {items.map((item) => {
                const { key } = item;
                return <ArrayItemTemplate {...item} key={key} />;
            })}
            {canAdd && (
                <Button
                    size="small"
                    variant="text"
                    color="primary"
                    disabled={disabled}
                    onClick={onAddClick}
                    startIcon={<AddIcon />}
                >
                    <Body2>ADD ITEM</Body2>
                </Button>
            )}
        </FormControl>
    );
};

export default ArrayFieldTemplate;
