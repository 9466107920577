// @flow
import { useQuery, type QueryResult } from 'react-query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';
import { type EntitySettingsType } from 'models/apps/client';

export default function useEntitySettings(
    clientId: string,
): QueryResult<EntitySettingsType> {
    return useQuery({
        queryKey: ['clients', clientId, 'entity-settings'],
        queryFn: async () => {
            const response = await ClientSettingsUtils.fetchSettings(clientId);
            return response.entitySettings;
        },
    });
}
