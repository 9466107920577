//@flow

import * as React from 'react';
import {
    default as TextWidget,
    type TextWidgetProps,
} from 'components/core/widgets/text-field';

const TextareaWidget = (props: TextWidgetProps): React.Node => {
    const {
        options: { rows },
    } = props;
    return <TextWidget {...props} rows={rows || 3} multiline />;
};

export default TextareaWidget;
