//@flow

import * as React from 'react';
import MuiForm from '@rjsf/material-ui';
import { Box } from '@material-ui/core';
import { useUncontrolledProp } from 'uncontrollable';

import {
    ObjectFieldTemplate,
    FormFieldTemplate,
    ArrayFieldTemplate,
} from 'components/core/form-templates';
import {
    TextWidget,
    CheckboxWidget,
    SelectWidget,
    AutocompleteWidget,
    RadioWidget,
    SwitchWidget,
    DateWidget,
    DateTimeWidget,
    TextareaWidget,
    FileWidget,
} from 'components/core/widgets';
import FormUtils from 'utils/FormUtils';
import classes from './Form.scss';

import { type FormSectionType } from 'models/FormSection';

const widgets = {
    TextWidget,
    SelectWidget,
    AutocompleteWidget,
    CheckboxWidget,
    RadioWidget,
    SwitchWidget,
    DateWidget,
    DateTimeWidget,
    TextareaWidget,
    FileWidget,
};

type Value = $FixMe;

type SubmitData = {
    formData: Value,
};

type CommonProps = {
    section: FormSectionType,
    onSubmit: (data: SubmitData) => void,
    children?: React.Node,
    extraErrors?: { [key: string]: any },
};

type Props =
    | {
          initialValue?: Value,
          ...CommonProps,
      }
    | {
          value: Value,
          onChange: (value: Value) => void,
          ...CommonProps,
      };

const Form: React.AbstractComponent<
    Props,
    { submit: () => void, state: SubmitData },
> = React.forwardRef((props, ref) => {
    const {
        section,
        initialValue = undefined,
        value = undefined,
        onChange = undefined,
        onSubmit,
        children,
        extraErrors,
    } = props;

    const [defaultValue] = React.useState(() => {
        return initialValue || FormUtils.buildInitialFormData(section);
    });

    const [formData, onChangeFormData] = useUncontrolledProp(
        value,
        defaultValue,
        onChange,
    );

    const [liveValidate, setLiveValidate] = React.useState(false);

    const { schema, uiSchema } = React.useMemo(() => {
        return {
            schema: FormUtils.buildSchemaJSON({
                ...section,
                // Hack to add border for nested i.e not root fields
                isRoot: true,
            }),
            uiSchema: FormUtils.buildUISchema(section),
        };
    }, [section]);

    return (
        <Box p={1} width="100%">
            <MuiForm
                ref={ref}
                liveValidate={liveValidate}
                schema={schema}
                uiSchema={uiSchema}
                widgets={widgets}
                ObjectFieldTemplate={ObjectFieldTemplate}
                FieldTemplate={FormFieldTemplate}
                ArrayFieldTemplate={ArrayFieldTemplate}
                showErrorList={false}
                // Set Live Validate after encountering first error on submit
                onError={() => setLiveValidate(true)}
                onSubmit={onSubmit}
                formData={formData}
                onChange={(event) => onChangeFormData(event.formData)}
                extraErrors={extraErrors}
            >
                {children || <button className={classes.hide} />}
            </MuiForm>
        </Box>
    );
});

export default Form;
