//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import Checkbox from 'components/core/checkbox';
import { type SchemaType } from 'models/Schema';
import { Infotip } from 'components/core/tooltip';

type Props = {
    label: string,
    value?: boolean,
    required?: boolean,
    schema: SchemaType,
    onChange: (value: ?boolean) => void,
};

const CheckboxWidget = (props: Props): React.Node => {
    const {
        label,
        value,
        required,
        schema: { description },
        onChange,
    } = props;

    const handleChange = (value) => {
        // When required field is unchecked, set value as undefined for validation to kick in
        const updatedValue = required && !value ? undefined : value;
        onChange(updatedValue);
    };

    return (
        <Box alignItems="center" display="flex" spacing={1}>
            <Checkbox
                required={required}
                checked={!!value}
                label={label}
                onChange={handleChange}
            />
            {description && (
                <Infotip title={description} customFontSize="14px" />
            )}
        </Box>
    );
};

export default CheckboxWidget;
