//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import { default as MuiPagination } from '@material-ui/lab/Pagination';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Button from 'components/core/button';
import { Body1 } from 'components/core/typography';

type Props = {
    currentPage: number,
    pageCount: number,
    canPreviousPage: boolean,
    canNextPage: boolean,
    setCurrentPage: (pageNum: number) => void,
    gotoPage: (updater: ((pageIndex: number) => number) | number) => void,
    previousPage: () => void,
    nextPage: () => void,
};

const PageChooser = (props: Props): React.Node => {
    const {
        currentPage,
        pageCount,
        canPreviousPage,
        canNextPage,
        setCurrentPage,
        gotoPage,
        previousPage,
        nextPage,
    } = props;

    const navigateToPage = (event: Event, clickedPageNum: number) => {
        const clickedPageIndex = clickedPageNum - 1;
        gotoPage(clickedPageIndex);
        setCurrentPage(clickedPageNum);
    };

    const navigateToPrevPage = () => {
        previousPage();
        setCurrentPage(currentPage - 1);
    };

    const navigateToNextPage = () => {
        nextPage();
        setCurrentPage(currentPage + 1);
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <Button
                size="small"
                variant="text"
                color="secondary"
                onClick={navigateToPrevPage}
                disabled={!canPreviousPage}
                startIcon={<NavigateBeforeIcon />}
            >
                <Body1>PREV</Body1>
            </Button>
            <Box px={5}>
                <MuiPagination
                    hideNextButton
                    hidePrevButton
                    size="small"
                    color="primary"
                    count={pageCount}
                    onChange={navigateToPage}
                    page={currentPage}
                />
            </Box>
            <Button
                size="small"
                variant="text"
                fullWidth={false}
                color="secondary"
                onClick={navigateToNextPage}
                disabled={!canNextPage}
                endIcon={<NavigateNextIcon />}
            >
                <Body1>NEXT</Body1>
            </Button>
        </Box>
    );
};

export default PageChooser;
