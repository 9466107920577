//@flow

import * as React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { type FilterProps } from 'react-table';

import TextField from 'components/core/input';
import {
    SelectWithAutoComplete,
    type OptionsListType,
} from 'components/core/select';
import invariant from 'utils/invariant';

export type FilterOptions = {
    selectOptions: OptionsListType,
    defaultValue?: string | number,
};

export const TextFilter = <T>(props: FilterProps<T>): React.Node => {
    const { column } = props;
    const { filterValue, setFilter } = column;
    return (
        <TextField
            fullWidth
            value={filterValue || ''}
            onChange={setFilter}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon color="action" fontSize="small" />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const SelectColumnFilter = <T>(props: FilterProps<T>): React.Node => {
    const { column } = props;
    const { filterValue, setFilter, filterOptions } = column;
    invariant(
        filterOptions?.selectOptions && filterOptions.selectOptions?.length > 0,
        'Expected selectOptions when filter is added',
    );
    const { selectOptions, defaultValue } = filterOptions;

    const handleChange = (value) => {
        const filterValue = value === 'all' ? undefined : value;
        setFilter(filterValue);
    };

    return (
        <SelectWithAutoComplete
            value={filterValue || defaultValue}
            items={selectOptions}
            onChange={handleChange}
        />
    );
};
