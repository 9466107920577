//@flow

import { isEmpty, get, isUndefined } from 'lodash';
import vaeConfigsRegistry from 'resources/vaeConfigRegistry.json';
import { FairnessMetricType } from 'enums/FairnessMetricType';
import { FleetOptimizationMetricType } from 'enums/FleetOptimizationMetricType';

const { baseConfiguration, patches } = vaeConfigsRegistry;
const baseVaeConfiguration = JSON.parse(baseConfiguration);
const patchIdJSONMapping = patches.reduce((updatedPatch, patch) => {
    const { id, serializedPatch } = patch;
    return { ...updatedPatch, [id]: JSON.parse(serializedPatch) };
}, {});

class VaeConfigUtils {
    // Eg. volumeConfig.mode will be the dependee, volumeConfig.weight will be the dependent
    // If the usecase is not changed, baseTemplateConfig will be the saved configuration otherwise it will be the baseVaeConfiguration according to the changed useCase
    // updatedConfig will be updated configuration by the user
    // This method will return last saved config value as the default value. For eg. -
    // If user modifies the dependee value, i.e. volumeConfig.mode from FUZZY to STRICT, the default value for the dependent will remain unchanged
    // If user modifies the dependent value, i.e. volumeConfig.weight from 0.15 to 0.20, the modified value i.e. 0.20 will be returned always

    static _getConfigValue(
        dependeeList: Array<Object>,
        dependent: Object,
        baseTemplateConfig: Object,
        updatedConfig: Object,
    ): any {
        const isNonDirtyDependent = dependeeList.find((dependee) => {
            const updatedDependeeValue = get(updatedConfig, dependee.key);
            return isUndefined(updatedDependeeValue);
        });

        if (isNonDirtyDependent) return dependent.value;

        const isDependeeModified = dependeeList.find((dependee) => {
            const updatedDependeeValue = get(updatedConfig, dependee.key);
            return dependee.value !== updatedDependeeValue;
        });
        const defaultDependentValue = get(baseTemplateConfig, dependent.key);

        if (isDependeeModified) return defaultDependentValue;

        return dependent.value || defaultDependentValue;
    }

    static resetConfig(
        useCase: string,
        defaultConfiguration: Object,
        defaultUsecase: Object,
    ): Object {
        if (useCase === defaultUsecase) {
            return defaultConfiguration;
        } else {
            return {
                ...baseVaeConfiguration,
                ...patchIdJSONMapping[useCase],
            };
        }
    }

    static updateVAEConfig(
        formData: Object,
        config: Object,
        baseTemplateConfig: Object,
        updatedConfig: Object,
    ): Object {
        const {
            form: {
                capacityConstraints,
                fleetOptimization,
                tardinessAndSlots,
                timeSlotDitributionConfig,
                volumePriority,
                multipleHomebases,
                tat,
                vehicleBreaks,
                zoneRestrictions,
                userRatings,
                skills,
                routeRestrictions,
                multipleTrips,
                costBasedOptimization,
                fairness,
                locationRevisits,
                runOnlyWAE,
                waeCapacityConstraints,
                zoneBasedRouting,
                wae,
            },
        } = formData;
        const fleetOptConfig = VaeConfigUtils._getFleetOptConfig(
            fleetOptimization,
            multipleHomebases,
            multipleTrips,
            routeRestrictions,
            vehicleBreaks,
        );
        const candidateConfig = VaeConfigUtils._getCandidateConfig(
            multipleTrips,
            vehicleBreaks,
            multipleHomebases,
            baseTemplateConfig,
            updatedConfig,
        );
        const tardinessAndSlotsConfig = VaeConfigUtils._getTardinessAndSlots(
            tardinessAndSlots,
            baseTemplateConfig,
            updatedConfig,
        );
        const capacityConfig = VaeConfigUtils._getCapacityConfig(
            capacityConstraints,
            baseTemplateConfig,
            updatedConfig,
        );
        const fairnessConfig = VaeConfigUtils._getFairnessConfig(fairness);
        const multipleTripsConfig = VaeConfigUtils._getMultipleTripsConfig(
            multipleTrips,
            baseTemplateConfig,
            updatedConfig,
        );
        const costConfig = VaeConfigUtils._getCostConfig(costBasedOptimization);
        const routeRestrictionsConfig = VaeConfigUtils._getRouteRestrictionsConfig(
            routeRestrictions,
        );
        const skillsConfig = VaeConfigUtils._getSkillsConfig(skills);
        const userRatingsConfig = VaeConfigUtils._getUserRatingsConfig(
            userRatings,
        );
        const zoningConfig = VaeConfigUtils._getZoningConfig(
            zoneRestrictions,
            zoneBasedRouting,
            runOnlyWAE,
        );
        const locationRevisitsConfig = VaeConfigUtils._getLocationRevisitsConfig(
            locationRevisits,
        );
        const tatConfig = VaeConfigUtils._getTatConfig(
            tat,
            baseTemplateConfig,
            updatedConfig,
        );
        const volumePriorityConfig = VaeConfigUtils._getVolumePriorityConfig(
            volumePriority,
        );
        const waeConfig = VaeConfigUtils._getWAEConfig(
            wae,
            timeSlotDitributionConfig,
            runOnlyWAE,
            fleetOptConfig,
            waeCapacityConstraints,
            baseTemplateConfig,
            updatedConfig,
        );
        return {
            ...config,
            ...tardinessAndSlotsConfig,
            ...fairnessConfig,
            ...candidateConfig,
            ...capacityConfig,
            ...fleetOptConfig,
            ...multipleTripsConfig,
            ...costConfig,
            ...routeRestrictionsConfig,
            ...skillsConfig,
            ...userRatingsConfig,
            ...zoningConfig,
            ...locationRevisitsConfig,
            ...tatConfig,
            ...volumePriorityConfig,
            ...waeConfig,
        };
    }

    static _getFleetOptConfig(
        fleetOptimization,
        multipleHomebases,
        multipleTrips,
        routeRestrictions,
        vehicleBreaks,
    ) {
        const { fleetOptimizationMetric } = fleetOptimization;

        const { enableHomebaseShuffle = false } = multipleHomebases;
        const { multipleTripsAllowed = false } = multipleTrips;
        const { enableRouteRestrictions = false } = routeRestrictions;
        const { vehicleBreaksScheme } = vehicleBreaks;

        const isVaeEngine =
            enableRouteRestrictions ||
            enableHomebaseShuffle ||
            vehicleBreaksScheme === 'TIME_RULE_BASED';

        const waeTemplate = {
            waeConfig: {
                enableFleetOpt: true,
                useAsInitialSolution: false,
                useGreedyRoutes: false,
                timeSlotDistribution: 'AUTOMATIC',
                connectAllEdges: true,
                costOptimizationConfig: {
                    enable: false,
                    volumeBreachFraction: 0,
                },
                postProcessClusters: false,
                updateFleetProperties: false,
            },
            forcedVehiclesConfig: {
                enable: false,
            },
        };

        const vaeTemplate = {
            totalTourWeight: 1.1,
            enableTourWeight: false,
            tourModelCosts: {},
            forcedVehiclesConfig: {
                enable: false,
                weight: -1,
            },
            routeRestrictionConfig: {
                enable: enableRouteRestrictions,
            },
            multiTripConfig: {
                enableAsTour: multipleTripsAllowed,
            },
        };

        if (
            fleetOptimizationMetric ===
            FleetOptimizationMetricType.USE_ALL_VEHICLES
        ) {
            vaeTemplate.forcedVehiclesConfig.enable = true;
            return vaeTemplate;
        }

        if (fleetOptimizationMetric === FleetOptimizationMetricType.NONE) {
            const template = {
                ...vaeTemplate,
                waeConfig: {
                    enableFleetOpt: false,
                },
            };

            return template;
        }

        if (!isVaeEngine) {
            return waeTemplate;
        }

        vaeTemplate.enableTourWeight = true;
        return vaeTemplate;
    }

    static _getCandidateConfig(
        multipleTrips,
        vehicleBreaks,
        multipleHomebases,
        baseTemplateConfig,
        updatedConfig,
    ) {
        const { multipleTripsAllowed = false } = multipleTrips;
        const {
            hasVehicleBreaks = false,
            maxBreaksPerTour,
            maxNightBreakTardiness,
            vehicleBreaksScheme,
        } = vehicleBreaks;
        const { enableHomebaseShuffle = false } = multipleHomebases;
        const candidateConfig = {
            enableUserShuffle: true,
            userShuffleFraction: 0.01,
            enableTourMerge: true,
            tourMergeFraction: 0.01,
            tourMergeScheme: 'DISPERSE',
            tourMergeSourceMode: 'FIXED',
            tourMergeTripWise: multipleTripsAllowed,
            tourBiasTripWise: multipleTripsAllowed,
            breaksHandlingConfig: {
                enable: hasVehicleBreaks,
                scheme: vehicleBreaksScheme,
                onlyPostProcess: false,
                maxNightBreakTardiness: VaeConfigUtils._getConfigValue(
                    [
                        {
                            key: 'candidateConfig.breaksHandlingConfig.enable',
                            value: hasVehicleBreaks,
                        },
                        {
                            key: 'candidateConfig.breaksHandlingConfig.scheme',
                            value: vehicleBreaksScheme,
                        },
                    ],
                    {
                        key:
                            'candidateConfig.breaksHandlingConfig.maxNightBreakTardiness',
                        value: maxNightBreakTardiness,
                    },
                    baseTemplateConfig,
                    updatedConfig,
                ),
                maxBreaksPerTour: VaeConfigUtils._getConfigValue(
                    [
                        {
                            key: 'candidateConfig.breaksHandlingConfig.enable',
                            value: hasVehicleBreaks,
                        },
                        {
                            key: 'candidateConfig.breaksHandlingConfig.scheme',
                            value: vehicleBreaksScheme,
                        },
                    ],
                    {
                        key:
                            'candidateConfig.breaksHandlingConfig.maxBreaksPerTour',
                        value: maxBreaksPerTour,
                    },
                    baseTemplateConfig,
                    updatedConfig,
                ),
                serviceRegulationSettings: {
                    serviceRules: [
                        {
                            priority: 3,
                            metric: 'DRIVING_TIME',
                            limitInMins: 3600,
                            breakDurationInMins: 2040,
                            breakType: 'OFF_DUTY_BREAK',
                        },
                        {
                            priority: 2,
                            metric: 'ON_DUTY_TIME',
                            limitInMins: 840,
                            breakDurationInMins: 600,
                            breakType: 'OFF_DUTY_BREAK',
                        },
                        {
                            priority: 2,
                            metric: 'DRIVING_TIME',
                            limitInMins: 660,
                            breakDurationInMins: 600,
                            breakType: 'OFF_DUTY_BREAK',
                        },
                        {
                            priority: 1,
                            metric: 'ON_DUTY_TIME',
                            limitInMins: 480,
                            breakDurationInMins: 30,
                            breakType: 'OFF_DUTY_BREAK',
                        },
                    ],
                },
            },
            enableHomebaseShuffle,
        };
        return { candidateConfig };
    }

    static _getTardinessAndSlots(
        tardinessAndSlots,
        baseTemplateConfig,
        updatedConfig,
    ) {
        const {
            tardinessSla,
            allowedTardiness,
            considerSlotEndForTardiness,
            treatNonCustomerVisitTardinessDifferently,
            nonCustomerVisitTardinessSla,
        } = tardinessAndSlots;
        const template = {
            enableTardiness: true,
            totalTardiness: tardinessSla || 1800,
            allowedTardiness: allowedTardiness || 1800,
            fitTransactionWithinSlot: considerSlotEndForTardiness,
            userVisitSlotStrictness: treatNonCustomerVisitTardinessDifferently
                ? 'STRICT'
                : 'NORMAL',
        };
        template.strictTardinessFactor2 = VaeConfigUtils._getConfigValue(
            [
                {
                    key: 'userVisitSlotStrictness',
                    value: get(template, 'userVisitSlotStrictness'),
                },
            ],
            {
                key: 'strictTardinessFactor2',
                value: tardinessSla / nonCustomerVisitTardinessSla,
            },
            baseTemplateConfig,
            updatedConfig,
        );
        return template;
    }

    static _getLocationRevisitsConfig(locationRevisits) {
        const {
            avoidCustomerOrPlaceMultipleVisit,
            avoidCustomerMultipleVehiclesVisit,
            avoidPlaceMultipleVehiclesVisit,
        } = locationRevisits;
        const template = {
            enableLocationCountMetric: avoidCustomerOrPlaceMultipleVisit,
            customerContactWeight: 2,
            customerContactSlotWiseWeight: 0.1,
            locationVisitWeight: 0.1,
            reduceVehiclesPerCustomer: avoidCustomerMultipleVehiclesVisit,
            reduceVehiclesPerCustomerWeight: 0.1,
            reduceVehiclesPerPlace: avoidPlaceMultipleVehiclesVisit,
            reduceVehiclesPerPlaceWeight: 4,
        };
        return template;
    }

    static _getCapacityConfig(
        capacityConstraints,
        baseTemplateConfig,
        updatedConfig,
    ) {
        const {
            canOverloadVehicles = false,
            overloadPercentage,
        } = capacityConstraints;
        const template = {
            volumeConfig: {
                autoIncreaseBuffer: 0.1,
                autoIncreaseFraction: false,
                measureType: 'FRACTION',
                mode: canOverloadVehicles ? 'FUZZY' : 'STRICT',
                weight: 0.15,
            },
        };
        template.volumeConfig.weight = VaeConfigUtils._getConfigValue(
            [
                {
                    key: 'volumeConfig.mode',
                    value: get(template, 'volumeConfig.mode'),
                },
            ],
            {
                key: 'volumeConfig.weight',
                value: overloadPercentage,
            },
            baseTemplateConfig,
            updatedConfig,
        );
        return template;
    }

    static _getFairnessConfig(fairness) {
        const { fairnessMetric } = fairness;
        if (fairnessMetric === FairnessMetricType.NONE) {
            return {
                ignoreOtherFairness: true,
                fairnessConfigs: {
                    wrappers: [],
                },
            };
        }
        const template = {
            ignoreOtherFairness: true,
            fairnessConfigs: {
                wrappers: [
                    {
                        enable: true,
                        weight:
                            fairnessMetric === FairnessMetricType.TASK
                                ? 100
                                : 1e8,
                        fairnessMode: 'SQUARED_DEVIATION',
                        applyWithin: 'NONE',
                        fairnessMetric,
                    },
                ],
            },
        };
        return template;
    }

    static _getMultipleTripsConfig(
        multipleTrips,
        baseTemplateConfig,
        updatedConfig,
    ) {
        const {
            multipleTripsAllowed = false,
            vehicleTripCountLimit,
        } = multipleTrips;
        const template = {
            multiTripConfig: {
                enableAsTour: multipleTripsAllowed,
                maxTripCount: VaeConfigUtils._getConfigValue(
                    [
                        {
                            key: 'multiTripConfig.enableAsTour',
                            value: multipleTripsAllowed,
                        },
                    ],
                    {
                        key: 'multiTripConfig.maxTripCount',
                        value: vehicleTripCountLimit,
                    },
                    baseTemplateConfig,
                    updatedConfig,
                ),
                useApplicableMetrics: false,
            },
        };
        return template;
    }

    static _getCostConfig(costConfig) {
        const { enableCostBasedOptimization = false } = costConfig;
        return {
            vehicleCostConfig: {
                enable: enableCostBasedOptimization,
            },
        };
    }

    static _getRouteRestrictionsConfig(routeRestrictions) {
        const { enableRouteRestrictions = false } = routeRestrictions;
        const template = {
            routeRestrictionConfig: {
                enable: enableRouteRestrictions,
                dataSource: 'GOOGLE_DISTANCE_MATRIX',
                nonCustomerDataSource: 'GOOGLE_DISTANCE_MATRIX',
                weight: 1,
                forceSkipTimeBased: false,
                systemPenalty: false,
                attributesWeightMap: {},
            },
        };

        return template;
    }

    static _getSkillsConfig(skills) {
        const { maximizeMatch = false } = skills;
        const template = {
            enableSkillMetric: maximizeMatch,
            skillMetricWeight: 5,
        };
        return template;
    }

    static _getUserRatingsConfig(userRatings) {
        const { enableUserRatingMetric = false } = userRatings;
        const template = {
            enableUserRatingMetric,
            userRatingWeight: 5,
        };

        return template;
    }

    static _getZoningConfig(zoneRestrictions, zoneBasedRouting, runOnlyWAE) {
        const { enableZoneRestrictions = false } = zoneRestrictions;
        const { enableZoneBasedRouting = false } = zoneBasedRouting;
        const template = {
            zoningConfig: {
                enable: runOnlyWAE
                    ? enableZoneBasedRouting
                    : enableZoneRestrictions,
            },
        };
        return template;
    }

    static _getTatConfig(tat, baseTemplateConfig, updatedConfig) {
        const {
            enableTatClubbing = false,
            sameCustomerTat,
            sameLocationTat,
            useVehicleLoadingTime = false,
        } = tat;
        const template = {
            tatConfig: {
                enableTatClubbing,
                customerTatClubbing: sameCustomerTat !== 0,
                locationTatClubbing: sameLocationTat !== 0,
                enableLineItemTat: false,
                sameCustomerTat: VaeConfigUtils._getConfigValue(
                    [
                        {
                            key: 'tatConfig.enableTatClubbing',
                            value: enableTatClubbing,
                        },
                    ],
                    {
                        key: 'tatConfig.sameCustomerTat',
                        value: sameCustomerTat,
                    },
                    baseTemplateConfig,
                    updatedConfig,
                ),
                sameLocationTat: VaeConfigUtils._getConfigValue(
                    [
                        {
                            key: 'tatConfig.enableTatClubbing',
                            value: enableTatClubbing,
                        },
                    ],
                    {
                        key: 'tatConfig.sameLocationTat',
                        value: sameLocationTat,
                    },
                    baseTemplateConfig,
                    updatedConfig,
                ),
                extraTat: 0,
                useVehicleLoadingTime,
            },
        };
        return template;
    }

    static _getVolumePriorityConfig(volumePriority) {
        const { prioritizeLargerVolumeTasks = false } = volumePriority;
        const template = {
            poolMetricConfig: {
                enable: prioritizeLargerVolumeTasks,
                metricType: prioritizeLargerVolumeTasks
                    ? 'TASK_VOLUME'
                    : 'TASK_COUNT',
                scaledMin: 1,
                scaledMax: 2,
            },
        };
        return template;
    }

    static _getWAEConfig(
        wae,
        timeSlotDitributionConfig,
        runOnlyWAE,
        fleetOptConfig,
        waeCapacityConstraints,
        baseTemplateConfig,
        updatedConfig,
    ) {
        const { timeSlotDistribution } = timeSlotDitributionConfig;
        const {
            capacityConstraintBreachAllowed,
            capacityConstraintBreachFraction,
        } = waeCapacityConstraints;
        const template = {
            iterations: runOnlyWAE ? 0 : 1000000,
            enableTourOptimization: !runOnlyWAE,
            iterationConfig2: {},
            waeConfig: {
                ...wae,
                ...fleetOptConfig.waeConfig,
                postProcessClusters: runOnlyWAE,
                useAsInitialSolution: runOnlyWAE,
                enableFleetOpt: true,
                timeSlotDistribution: runOnlyWAE
                    ? timeSlotDistribution
                    : 'AUTOMATIC',
                useGreedyRoutes: false,
                connectAllEdges: true,
                costOptimizationConfig: {
                    enable: false,
                    volumeBreachFraction: 0,
                },
                updateFleetProperties: capacityConstraintBreachAllowed,
            },
        };
        template.iterationConfig2 = template.iterationConfig2 || {};

        if (runOnlyWAE) {
            template.iterationConfig2.mode = 'FIXED';
            template.volumeConfig = {
                autoIncreaseBuffer: 0.1,
                autoIncreaseFraction: false,
                measureType: 'FRACTION',
                mode: capacityConstraintBreachAllowed ? 'FUZZY' : 'STRICT',
                weight: VaeConfigUtils._getConfigValue(
                    [
                        {
                            key: 'volumeConfig.mode',
                            value: capacityConstraintBreachAllowed
                                ? 'FUZZY'
                                : 'STRICT',
                        },
                    ],

                    {
                        key: 'volumeConfig.weight',
                        value: capacityConstraintBreachFraction,
                    },
                    baseTemplateConfig,
                    updatedConfig,
                ),
            };
        } else {
            template.iterationConfig2.mode = 'LINEAR_TASK';
        }
        return template;
    }

    static computeFormData(vaeConfig) {
        if (isEmpty(vaeConfig)) {
            return {};
        }
        const fleetOptimization = VaeConfigUtils._fleetOptData(vaeConfig);
        const tardinessAndSlots = VaeConfigUtils._tardinessAndSlotsData(
            vaeConfig,
        );
        const locationRevisits = VaeConfigUtils._locationRevisitsData(
            vaeConfig,
        );
        const fairness = VaeConfigUtils._fairnessData(vaeConfig);
        const multipleTrips = VaeConfigUtils._multiTripsData(vaeConfig);
        const costBasedOptimization = VaeConfigUtils._costBasedOptimizationData(
            vaeConfig,
        );
        const routeRestrictions = VaeConfigUtils._routeRestrictionsData(
            vaeConfig,
        );
        const skills = VaeConfigUtils._skillsData(vaeConfig);
        const userRatings = VaeConfigUtils._userRatingsData(vaeConfig);
        const zoneRestrictions = VaeConfigUtils._zoneRestrictionsData(
            vaeConfig,
        );
        const vehicleBreaks = VaeConfigUtils._vehicleBreaksData(vaeConfig);
        const tat = VaeConfigUtils._tatData(vaeConfig);
        const multipleHomebases = VaeConfigUtils._multipleHomebasesData(
            vaeConfig,
        );
        const volumePriority = VaeConfigUtils._volumePriorityData(vaeConfig);
        const timeSlotDitributionConfig = VaeConfigUtils._timeSlotDistributionData(
            vaeConfig,
        );
        const runOnlyWAE = VaeConfigUtils._runOnlyWAEData(vaeConfig);
        const capacityConstraints = VaeConfigUtils._capacityConstraintsData(
            vaeConfig,
            runOnlyWAE,
        );
        const waeCapacityConstraints = VaeConfigUtils._waeCapacityConstraintsData(
            vaeConfig,
        );
        const zoneBasedRouting = VaeConfigUtils._zoneBasedRoutingData(
            vaeConfig,
        );
        const wae = { ...vaeConfig.waeConfig };

        return {
            form: {
                fleetOptimization,
                tardinessAndSlots,
                locationRevisits,
                capacityConstraints,
                fairness,
                multipleTrips,
                costBasedOptimization,
                routeRestrictions,
                skills,
                userRatings,
                zoneRestrictions,
                vehicleBreaks,
                tat,
                multipleHomebases,
                volumePriority,
                wae,
                timeSlotDitributionConfig,
                runOnlyWAE,
                waeCapacityConstraints,
                zoneBasedRouting,
            },
        };
    }

    static getUseCase(vaeConfig) {
        const {
            overlapConfigs,
            tourCurveConfig,
            compactnessConfigs,
            enableIdleTime,
            enableIntraSlotTardiness,
        } = vaeConfig;
        if (
            overlapConfigs.overlaps.length === 0 &&
            !enableIdleTime &&
            !enableIntraSlotTardiness
        ) {
            return 'intercity';
        }

        if (vaeConfig.clusterAllVisits) {
            return 'services';
        }
        if (
            tourCurveConfig.enable &&
            compactnessConfigs.wrappers.some((wrapper) => {
                const { enable, mode } = wrapper;
                return enable && mode === 'HULL_AREA';
            })
        ) {
            return 'longhaul';
        }
        return 'spmd';
    }

    static _fleetOptData(vaeConfig) {
        const { forcedVehiclesConfig, waeConfig, enableTourWeight } = vaeConfig;
        const useAllVehicles = forcedVehiclesConfig.enable;
        const optimizeNumberOfVehicles =
            enableTourWeight || waeConfig.enableFleetOpt;
        if (useAllVehicles) {
            return {
                fleetOptimizationMetric:
                    FleetOptimizationMetricType.USE_ALL_VEHICLES,
            };
        } else if (optimizeNumberOfVehicles) {
            return {
                fleetOptimizationMetric:
                    FleetOptimizationMetricType.NUMBER_OF_VEHICLES,
            };
        } else {
            return {
                fleetOptimizationMetric: FleetOptimizationMetricType.NONE,
            };
        }
    }

    static _tardinessAndSlotsData(vaeConfig) {
        const {
            allowedTardiness,
            userVisitSlotStrictness,
            strictTardinessFactor2,
            totalTardiness: tardinessSla,
            fitTransactionWithinSlot: considerSlotEndForTardiness,
        } = vaeConfig;
        const treatNonCustomerVisitTardinessDifferently =
            userVisitSlotStrictness === 'STRICT';
        const nonCustomerVisitTardinessSla =
            tardinessSla / strictTardinessFactor2;

        return {
            tardinessSla,
            allowedTardiness,
            considerSlotEndForTardiness,
            treatNonCustomerVisitTardinessDifferently,
            nonCustomerVisitTardinessSla,
        };
    }

    static _locationRevisitsData(vaeConfig) {
        const {
            enableLocationCountMetric: avoidCustomerOrPlaceMultipleVisit,
            reduceVehiclesPerCustomer: avoidCustomerMultipleVehiclesVisit,
            reduceVehiclesPerPlace: avoidPlaceMultipleVehiclesVisit,
        } = vaeConfig;

        return {
            avoidCustomerOrPlaceMultipleVisit,
            avoidCustomerMultipleVehiclesVisit,
            avoidPlaceMultipleVehiclesVisit,
        };
    }

    static _capacityConstraintsData(vaeConfig, runOnlyWAE) {
        const {
            volumeConfig: { mode, weight },
        } = vaeConfig;

        return {
            canOverloadVehicles: mode === 'FUZZY' && !runOnlyWAE,
            overloadPercentage: weight,
        };
    }

    static _fairnessData(vaeConfig) {
        const fairnessWrappers = vaeConfig.fairnessConfigs.wrappers;
        const hasTaskFairness = fairnessWrappers.some((wrapper) => {
            const { enable, fairnessMetric } = wrapper;
            return enable && fairnessMetric === FairnessMetricType.TASK;
        });
        const hasDistanceFairness = fairnessWrappers.some((wrapper) => {
            const { enable, fairnessMetric } = wrapper;
            return enable && fairnessMetric === FairnessMetricType.DISTANCE;
        });
        if (hasTaskFairness) {
            return { fairnessMetric: FairnessMetricType.TASK };
        } else if (hasDistanceFairness) {
            return { fairnessMetric: FairnessMetricType.DISTANCE };
        } else {
            return { fairnessMetric: FairnessMetricType.NONE };
        }
    }

    static _multiTripsData(vaeConfig) {
        const {
            multiTripConfig: { enableAsTour, maxTripCount },
        } = vaeConfig;

        return {
            multipleTripsAllowed: enableAsTour,
            vehicleTripCountLimit: maxTripCount,
        };
    }

    static _costBasedOptimizationData(vaeConfig) {
        const {
            vehicleCostConfig: { enable },
        } = vaeConfig;
        return {
            enableCostBasedOptimization: enable,
        };
    }

    static _routeRestrictionsData(vaeConfig) {
        const {
            routeRestrictionConfig: { enable },
        } = vaeConfig;

        return { enableRouteRestrictions: enable };
    }

    static _skillsData(vaeConfig) {
        const { enableSkillMetric } = vaeConfig;
        return { maximizeMatch: enableSkillMetric };
    }

    static _userRatingsData(vaeConfig) {
        const { enableUserRatingMetric } = vaeConfig;
        return { enableUserRatingMetric };
    }

    static _zoneRestrictionsData(vaeConfig) {
        const {
            zoningConfig: { enable },
        } = vaeConfig;

        return { enableZoneRestrictions: enable };
    }

    static _vehicleBreaksData(vaeConfig) {
        const {
            candidateConfig: { breaksHandlingConfig },
        } = vaeConfig;
        const hasVehicleBreaks = breaksHandlingConfig.enable;
        const {
            maxBreaksPerTour,
            scheme,
            maxNightBreakTardiness,
        } = breaksHandlingConfig;

        return {
            hasVehicleBreaks,
            vehicleBreaksScheme: scheme,
            maxBreaksPerTour,
            maxNightBreakTardiness,
        };
    }

    static _tatData(vaeConfig) {
        const {
            tatConfig: {
                enableTatClubbing,
                sameCustomerTat,
                sameLocationTat,
                useVehicleLoadingTime,
            },
        } = vaeConfig;
        return {
            enableTatClubbing,
            sameCustomerTat,
            sameLocationTat,
            useVehicleLoadingTime,
        };
    }

    static _multipleHomebasesData(vaeConfig) {
        const {
            candidateConfig: { enableHomebaseShuffle },
        } = vaeConfig;

        return { enableHomebaseShuffle };
    }

    static _volumePriorityData(vaeConfig) {
        const {
            poolMetricConfig: { enable, metricType },
        } = vaeConfig;
        const prioritizeLargerVolumeTasks =
            enable && metricType === 'TASK_VOLUME';

        return { prioritizeLargerVolumeTasks };
    }

    static _runOnlyWAEData(vaeConfig) {
        const {
            iterations,
            iterationConfig2: { mode },
            waeConfig: {
                useAsInitialSolution,
                enableFleetOpt,
                postProcessClusters,
            },
        } = vaeConfig;
        const runOnlyWAE =
            iterations === 0 &&
            mode === 'FIXED' &&
            useAsInitialSolution &&
            enableFleetOpt &&
            postProcessClusters;

        return runOnlyWAE;
    }

    static _timeSlotDistributionData(vaeConfig) {
        const timeSlotDistribution =
            vaeConfig.waeConfig?.timeSlotDistribution === 'MULTIPLE'
                ? 'MULTIPLE'
                : 'AUTOMATIC';

        return {
            timeSlotDistribution,
        };
    }

    static _waeCapacityConstraintsData(vaeConfig) {
        const {
            waeConfig: { updateFleetProperties },
            volumeConfig: { mode, weight },
        } = vaeConfig;
        const capacityConstraintBreachAllowed =
            updateFleetProperties && mode === 'FUZZY';
        const capacityConstraintBreachFraction = weight;

        return {
            capacityConstraintBreachAllowed,
            capacityConstraintBreachFraction,
        };
    }

    static _zoneBasedRoutingData(vaeConfig) {
        const {
            zoningConfig: { enable },
        } = vaeConfig;
        return {
            enableZoneBasedRouting: enable,
        };
    }
}

export default VaeConfigUtils;
