//@flow

import * as React from 'react';
import { Divider as MuiDivider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        divider: {
            background: grey[200],
        },
    };
});

const Divider = (): React.Node => {
    const classes = useStyles();
    return <MuiDivider className={classes.divider} />;
};

export default Divider;
