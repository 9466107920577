//@flow

import * as React from 'react';
import { type TextWidgetProps } from 'components/core/widgets/text-field';

import { TextWidget } from 'components/core/widgets';
import DateTimeUtils from 'utils/DateTimeUtils';

const DateTimeWidget = (props: TextWidgetProps): React.Node => {
    const { value, onChange, ...restProps } = props;
    const localValue = value ? DateTimeUtils.utcToLocal(value) : value;

    const onDateTimeChange = (value: string) => {
        const utcDateTime = DateTimeUtils.localToUTC(value);
        onChange(utcDateTime);
    };
    return (
        <TextWidget
            {...restProps}
            type="datetime-local"
            value={localValue}
            onChange={onDateTimeChange}
        />
    );
};

export default DateTimeWidget;
