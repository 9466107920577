//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';

const fetchMessagesFormObject: FormSectionType = {
    id: 'fetchMessages',
    type: 'SECTION',
    subSections: [
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'namespace',
            title: 'Namespace',
            required: true,
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'queueName',
            title: 'Queue Name',
            required: true,
        },
    ],
};

export default fetchMessagesFormObject;
