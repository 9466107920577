//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import LabelBox, { type LabelBoxPropsTypes } from 'components/core/label';
import Select, { type SelectPropsTypes } from 'components/core/select';

type Props = {
    labelBoxProps: LabelBoxPropsTypes,
    selectProps: SelectPropsTypes,
};
const useStyles = makeStyles({
    select: {
        width: '180px',
        borderRadius: '0',
    },
});

const SelectWithBoxedLabel = (props: Props) => {
    const classes = useStyles();
    const { labelBoxProps, selectProps } = props;
    const updatedSelectProps = { ...selectProps, removeBorderRadius: true };
    return (
        <Box display="flex" flexDirection="row">
            <LabelBox {...labelBoxProps} />
            <Select {...updatedSelectProps} className={classes.select} />
        </Box>
    );
};

export { SelectWithBoxedLabel };
