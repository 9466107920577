//@flow

import * as React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TextField from 'components/core/input';
import Chip from 'components/core/chip';
import { type OptionsType, type OptionsListType } from 'components/core/select';

type SelectState = {
    inputValue: string,
};

type Props = {
    value: null | string | number | Array<string | number>,
    items: OptionsListType,
    clearable?: boolean,
    multiple?: boolean,
    onChange: (value?: string) => void,
    renderOption?: (option: OptionsType) => React.Node,
    filterOptions?: (
        options: OptionsListType,
        state: SelectState,
    ) => OptionsListType,
};

const SelectWithAutoComplete = (props: Props): React.Node => {
    const {
        value,
        multiple,
        clearable = false,
        items,
        renderOption,
        onChange,
        filterOptions,
    } = props;

    const idItemMapping = multiple
        ? items.reduce((idItemMapping, item) => {
              idItemMapping[item.id] = item;
              return idItemMapping;
          }, {})
        : {};
    const option = multiple
        ? value.map((id) => idItemMapping[id])
        : value === null
        ? null
        : items.find((item) => item.value === value);

    const onSelectChange = (event, updatedOption) => {
        if (multiple) {
            const updateValues = updatedOption.map((value) => value.value);
            onChange(updateValues);
            return;
        }
        const { value: updatedValue } = updatedOption;
        onChange(updatedValue);
    };

    return (
        <Autocomplete
            multiple={multiple}
            value={option}
            options={items}
            onChange={onSelectChange}
            disableClearable={!clearable}
            getOptionLabel={(option) => option.label}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        key={option.id}
                        label={option.label}
                    />
                ))
            }
            renderOption={renderOption}
            filterOptions={filterOptions}
            renderInput={(params) => (
                <TextField variant="outlined" {...params} />
            )}
        />
    );
};

export default SelectWithAutoComplete;
export { SelectWithAutoComplete };
