//@flow
import * as React from 'react';
import Checkbox from 'components/core/checkbox';
import { useRowSelect, type UseHooks } from 'react-table';

export const rowSelectHooks: Array<UseHooks> = [
    useRowSelect,
    (hooks) => {
        hooks.visibleColumns.push((columns) => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => {
                    const {
                        onChange: onChangeProp,
                        ...rest
                    } = getToggleAllRowsSelectedProps();
                    return (
                        <Checkbox
                            size="small"
                            onChange={(_, event) => onChangeProp(event)}
                            {...rest}
                        />
                    );
                },
                Cell: ({ row }) => {
                    const {
                        onChange: onChangeProp,
                        ...rest
                    } = row.getToggleRowSelectedProps();
                    return (
                        <Checkbox
                            size="small"
                            onChange={(_, event) => onChangeProp(event)}
                            {...rest}
                        />
                    );
                },
            },
            ...columns,
        ]);
    },
];
