// @flow
import './polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Root from 'containers/Root';
import configureStore, { history } from 'store/configureStore';
import { AuthProvider } from 'auth/AuthProvider';
import { QueryProvider } from 'query';
import theme from './theme';

const store = configureStore();
const app = (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryProvider>
            <AuthProvider>
                <Root store={store} history={history} />
            </AuthProvider>
        </QueryProvider>
    </ThemeProvider>
);

ReactDOM.render(app, document.getElementById('root'));
