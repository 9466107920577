// @flow
import * as React from 'react';
import { Provider } from 'react-redux';

import Routes from 'containers/routes/Routes';

type Props = {
    store: $FixMe,
    history: $FixMe,
};

const Root = (props: Props): React.Node => {
    const { store, history } = props;
    return (
        <Provider store={store}>
            <Routes history={history} />
        </Provider>
    );
};

export default Root;
