// @flow
import { useMutation, type MutationResult } from 'react-query';

import { lqsInstance } from 'middleware/axios';

type Variables = {
    namespace: string,
    queueName: string,
    messagesIds: Array<string>,
};

export default function useDeleteMessage(): MutationResult<Variables, void> {
    return useMutation<Variables, void>(
        ({ namespace, queueName, messagesIds }) => {
            return lqsInstance.delete(
                `namespace/${namespace}/dlq/${queueName}`,
                {
                    data: {
                        messagesIds,
                        deleteAllMessages: false,
                    },
                },
            );
        },
    );
}
