//@flow

import * as React from 'react';
import { useTableInstance } from './useTableInstance';
import BaseTable from './BaseTable';
import { type TableProps } from './types';

const Table = <T>(props: TableProps<T>): React.Node => {
    const { columns, data, idField, ...restProps } = props;
    const tableInstance = useTableInstance({ columns, data, idField });
    return (
        <BaseTable
            tableInstance={tableInstance}
            idField={idField}
            {...restProps}
        />
    );
};

export { Table };
export default Table;
