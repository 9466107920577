// @flow
import { useMutation, type MutationResult } from 'react-query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';

type Variables = {
    clientId: string,
    settings: $FixMe,
    reason: string,
};

export default function useUpdateClientSettings(): MutationResult<
    Variables,
    void,
> {
    return useMutation<Variables, void>(({ clientId, reason, settings }) => {
        const pathLists = [
            {
                path: 'localeSettings',
                value: settings,
            },
        ];
        return ClientSettingsUtils.fetchAndUpdateSettings(
            clientId,
            pathLists,
            reason,
        );
    });
}
