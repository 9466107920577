// @flow
import { useMutation, type MutationResult } from 'react-query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';
import { type ClientSettingsType } from 'models/apps/client';

type Variables = {
    clientId: string,
    settings: ClientSettingsType,
    reason: string,
};

type Result = ClientSettingsType;

export default function useUpdateClientSettings(): MutationResult<
    Variables,
    Result,
> {
    return useMutation<Variables, void>(({ clientId, reason, settings }) => {
        return ClientSettingsUtils.updateSettings(clientId, settings, reason);
    });
}
