//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';

import Loader from 'components/core/loader';
import { useClientId, useEntitySettings } from 'hooks/client';
import { ApiStatusEnum } from 'enums/API';
import { H3 } from 'components/core/typography';
import ErrorContainer from 'containers/ErrorContainer';
import { ManageCustomFieldsForm } from './ManageCustomFieldsForm';

export const ManageCustomFields = (): React.Node => {
    const clientId = useClientId();
    const settingsQuery = useEntitySettings(clientId);
    if (settingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box p={1}>
                <H3> Manage custom fields </H3>
            </Box>
            {settingsQuery.status === ApiStatusEnum.Error ? (
                <ErrorContainer message={settingsQuery.error.message} />
            ) : (
                <ManageCustomFieldsForm
                    clientId={clientId}
                    data={settingsQuery.data}
                />
            )}
        </Box>
    );
};
