// @flow
import { useMutation, type MutationResultPair } from 'react-query';

import { instance } from 'middleware/axios';
import { type DashSettings } from 'models/apps/client';

type Variables = {
    clientId: string,
    settings: DashSettings,
};

type Result = DashSettings;

export default function useUpdateDashSettings(): MutationResultPair<
    Variables,
    Result,
> {
    return useMutation<Variables, void>(({ clientId, settings }) => {
        return instance.post(`client/${clientId}/settings`, settings);
    });
}
