//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { AuthProviderType } from 'enums/AuthProviderType';

const passwordAuthSettingsFormObject: FormSectionType = {
    id: 'passwordAuthSettings',
    title: 'Password Auth Settings',
    type: 'SECTION',
    subSections: [
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.SWITCH,
            id: 'enabled',
            title: 'Enabled?',
            description:
                'If enabled, client-level settings are in effect. Else, the default for the stack is used.',
        },
        {
            id: 'provider',
            title: 'Provider',
            fieldType: FormFieldTypeEnum.SELECT,
            type: 'FIELD',
            required: true,
            options: {
                itemsType: FormPropertyTypeEnum.STRING,
                items: [
                    {
                        id: AuthProviderType.IAM,
                        label: AuthProviderType.IAM,
                        value: AuthProviderType.IAM,
                    },
                    {
                        id: AuthProviderType.AUTH0,
                        label: AuthProviderType.AUTH0,
                        value: AuthProviderType.AUTH0,
                    },
                ],
            },
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'connectionId',
            title: 'Connection Id',
            required: true,
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            id: 'connectionName',
            title: 'Connection Name',
            required: true,
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.SWITCH,
            id: 'isBruteForceProtectionEnabled',
            title: 'Is Brute Force Protection Enabled?',
        },
    ],
};

export default passwordAuthSettingsFormObject;
