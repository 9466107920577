//@flow
type FileInfo = {
    dataURL: string,
    name: string,
    size: number,
    type: string,
};

class FileUtils {
    static extractBase64String(dataURL: string): string {
        return dataURL.replace(/^.*base64,/, '');
    }

    static processFiles(files: Array<File>): Promise<Array<FileInfo>> {
        return Promise.all([].map.call(files, FileUtils.processFile));
    }

    static processFile(file: File): Promise<FileInfo> {
        const { name, size, type } = file;
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onerror = reject;
            reader.onload = (event) => {
                resolve({
                    dataURL: FileUtils.addNameToDataURL(
                        event.target?.result,
                        name,
                    ),
                    name,
                    size,
                    type,
                });
            };
            reader.readAsDataURL(file);
        });
    }

    static addNameToDataURL(dataURL: string, name: string): string {
        return dataURL.replace(
            ';base64',
            `;name=${encodeURIComponent(name)};base64`,
        );
    }

    static dataURLtoBlob(dataURL: string): Blob {
        // convert base64 to raw binary data held in a string
        const byteString = atob(dataURL.split(',')[1]);

        // separate out the mime component
        const [mimeString] = dataURL.split(',')[0].split(':')[1].split(';');

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }
}

export default FileUtils;
