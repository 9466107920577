// @flow
import { useQuery, type QueryResult } from 'react-query';

import { instance } from 'middleware/axios';
import { type DashSettings } from 'models/apps/client';

export default function useDashSettings(
    clientId: string,
): QueryResult<DashSettings> {
    return useQuery({
        queryKey: ['clients', clientId, 'dashSettings'],
        queryFn: () => {
            return instance
                .get(`/client/${clientId}/settings`)
                .then((response) => {
                    return response.data;
                });
        },
    });
}
