//@flow
import * as React from 'react';
import Box from '@material-ui/core/Box';

import Loader from 'components/core/loader';
import { useClientSettings, useClientId } from 'hooks/client';
import { ApiStatusEnum } from 'enums/API';
import ErrorContainer from 'containers/ErrorContainer';
import ClientChecklistsForm from './ClientChecklistsForm';

const ClientChecklists = (): React.Node => {
    const clientId = useClientId();
    const settingsQuery = useClientSettings(clientId);
    if (settingsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            //Hack to allow min height as full height of container
            minHeight="70vh"
        >
            {settingsQuery.status === ApiStatusEnum.Error ? (
                <ErrorContainer message={settingsQuery.error.message} />
            ) : (
                <ClientChecklistsForm
                    clientId={clientId}
                    settings={settingsQuery.data}
                />
            )}
        </Box>
    );
};

export default ClientChecklists;
