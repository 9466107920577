//@flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTabs, type TabsProps } from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

type TabItemTypes = {
    id: string,
    label: string,
};

type TabItemsTypes = Array<TabItemTypes>;

type TabsPropsTypes = {
    ...TabsProps,
    selectedTabIndex?: number,
    tabs: TabItemsTypes,
};

const useStyles = makeStyles((theme) => {
    const {
        palette: {
            primary: { light },
            grey,
        },
        typography: { body2 },
    } = theme;
    return {
        tabsContainer: {
            borderBottom: `1px solid ${grey[200]}`,
        },
        tabContainer: {
            ...body2,
            textTransform: 'none',
            minWidth: '72px',
            margin: '0px 40px',
            '&:hover': {
                color: light,
            },
        },
    };
});

const Tabs = (props: TabsPropsTypes) => {
    const {
        selectedTabIndex,
        tabs,
        indicatorColor = 'primary',
        textColor = 'primary',
        onChange,
    } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(selectedTabIndex);

    const handleChange = (_, tabIndex) => {
        setValue(tabIndex);
        onChange?.(tabIndex);
    };
    return (
        <MuiTabs
            className={classes.tabsContainer}
            value={value}
            indicatorColor={indicatorColor}
            textColor={textColor}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
        >
            {tabs.map((tab) => {
                const { id, label } = tab;
                return (
                    <Tab
                        className={classes.tabContainer}
                        disableRipple
                        key={id}
                        label={label}
                    />
                );
            })}
        </MuiTabs>
    );
};

export default Tabs;
