//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import JSONEditor from 'components/core/json-editor';
import Form from 'components/core/form';
import VaeConfigFormObject from './VaeConfigFormObject';
import { JsonEditorModeEnum } from 'enums/JsonEditorMode';
import { useToast } from 'containers/toast/Toast';
import JSONUtils from 'utils/JSONUtils';
import VaeConfigUtils from 'utils/VaeConfigUtils';
import { useLatest } from 'hooks/common';

type Value = { configuration: Object, useCase: string };
type Props = {
    value: Value,
    onChange: (value: Value) => void,
    onSubmit: () => void,
    defaultConfiguration: Object,
    defaultUsecase: string,
};

const VaeConfigForm: React.AbstractComponent<
    Props,
    { submit: () => void },
> = React.forwardRef((props, formRef) => {
    const {
        value,
        onChange,
        onSubmit,
        defaultConfiguration,
        defaultUsecase,
    } = props;
    const { useCase, configuration } = value;
    const baseTemplateConfigRef = useLatest(defaultConfiguration);
    const [updatedConfig, setUpdatedConfig] = React.useState({});
    const formData = React.useMemo(
        () => ({
            ...VaeConfigUtils.computeFormData(configuration),
            useCase,
        }),
        [configuration, useCase],
    );

    const { showToast } = useToast();

    const onFormDataChange = (updatedFormData) => {
        const { useCase: updatedUseCase } = updatedFormData;
        if (updatedUseCase !== useCase) {
            const updatedConfiguration = VaeConfigUtils.resetConfig(
                updatedUseCase,
                defaultConfiguration,
                defaultUsecase,
            );
            baseTemplateConfigRef.current = updatedConfiguration;
            onChange({
                configuration: updatedConfiguration,
                useCase: updatedUseCase,
            });
            return;
        }

        const updatedConfiguration = VaeConfigUtils.updateVAEConfig(
            updatedFormData,
            configuration,
            baseTemplateConfigRef.current,
            updatedConfig,
        );
        setUpdatedConfig(updatedConfiguration);
        onChange({
            configuration: updatedConfiguration,
            useCase: updatedUseCase,
        });
    };

    const setConfiguration = (updatedConfiguration) => {
        onChange({
            useCase,
            configuration: updatedConfiguration,
        });
    };

    const onChangeText = (textConfigValue) => {
        const isValid = JSONUtils.isValidJSON(textConfigValue);
        if (isValid) {
            const jsonValue = JSON.parse(textConfigValue);
            setConfiguration(jsonValue);
        } else {
            showToast('Invalid Config set in Text editor');
        }
    };

    return (
        <Box width="100%" height="100%">
            <Form
                section={VaeConfigFormObject}
                ref={formRef}
                onSubmit={onSubmit}
                onChange={onFormDataChange}
                value={formData}
            />
            <Box height="500px">
                <JSONEditor
                    mode={JsonEditorModeEnum.TREE}
                    modes={[JsonEditorModeEnum.TREE, JsonEditorModeEnum.TEXT]}
                    value={configuration}
                    onChangeJSON={(config) => setConfiguration(config)}
                    onChangeText={onChangeText}
                />
            </Box>
        </Box>
    );
});

export default VaeConfigForm;
