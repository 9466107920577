//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import Divider from 'components/core/divider';
import { H3 } from 'components/core/typography';
import useFetchMessages from './useFetchMessages';
import { ApiStatusEnum } from 'enums/API';
import QueueMessagesTable from './QueueMessagesTable';
import invariant from 'utils/invariant';
import FetchMessagesForm from './FetchMessagesForm';

const FetchAndDeleteMessages = (): React.Node => {
    const fetchMessagesMutation = useFetchMessages();
    const [value, setValue] = React.useState(null);

    const fetchMessages = () => {
        invariant(value, 'Expected queue name and namespace to be present');
        fetchMessagesMutation.mutate({
            queueName: value.queueName,
            namespace: value.namespace,
        });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            //Hack to allow min height as full height of container
            minHeight="77vh"
        >
            <FetchMessagesForm
                onSubmit={fetchMessages}
                onChange={setValue}
                value={value}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    width="50%"
                    alignItems="center"
                >
                    <Box width="100%" p={1}>
                        <H3> Fetch And Delete Messages </H3>
                        <Divider />
                    </Box>
                </Box>
            </FetchMessagesForm>
            {fetchMessagesMutation.status === ApiStatusEnum.Success && (
                <Box width="100%" py={1}>
                    <QueueMessagesTable
                        data={fetchMessagesMutation.data}
                        onSuccess={fetchMessages}
                        value={value}
                    />
                </Box>
            )}
        </Box>
    );
};

export default FetchAndDeleteMessages;
