// @flow
import * as React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import queryClient from './queryClient';

type Props = {
    children: React.Node,
};
const QueryProvider = ({ children }: Props): React.Node => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};

export default QueryProvider;
