//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { TaskProfileEnum } from 'enums/TaskProfileEnum';
import { VolumeUnitEnum } from 'enums/VolumeUnitEnum';

const taskProfileFormObject: FormSectionType = {
    id: 'taskProfile',
    title: 'Task Profile',
    type: 'SECTION',
    subSections: [
        {
            type: 'SECTION',
            id: 'parserSettings',
            subSections: [
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.SELECT,
                    title: 'Use case',
                    description: 'The usecase for batch parsing',
                    id: 'useCase',
                    options: {
                        items: [
                            {
                                id: 'PICKUP_DELIVERY',
                                label: 'Pickup Delivery',
                                value: TaskProfileEnum.PICKUP_DELIVERY,
                            },
                            {
                                id: 'DELIVERY_FROM_HOMEBASE',
                                label: 'Delivery From Homebase',
                                value: TaskProfileEnum.DELIVERY_FROM_HOMEBASE,
                            },
                            {
                                id: 'SERVICE',
                                label: 'Service',
                                value: TaskProfileEnum.SERVICE,
                            },
                        ],
                        itemsType: FormPropertyTypeEnum.STRING,
                    },
                    required: true,
                },
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.SELECT,
                    title: 'Volume Unit',
                    description: 'Volume Unit for create Batch',
                    id: 'volumeUnit',
                    options: {
                        items: [
                            {
                                id: 'ITEM_COUNT',
                                label: 'Item Count',
                                value: VolumeUnitEnum.ITEM_COUNT,
                            },
                            {
                                id: 'TASK_COUNT',
                                label: 'Task Count',
                                value: VolumeUnitEnum.TASK_COUNT,
                            },
                            {
                                id: 'METERS_CUBIC',
                                label: 'Meters Cubic',
                                value: VolumeUnitEnum.METERS_CUBIC,
                            },
                            {
                                id: 'LBH_METERS',
                                label: 'LBH Meters',
                                value: VolumeUnitEnum.LBH_METERS,
                            },
                        ],
                        itemsType: FormPropertyTypeEnum.STRING,
                    },
                    required: true,
                },
                {
                    type: 'ARRAY',
                    id: 'additionalHeaders',
                    title: 'Additional Headers',
                    description:
                        'Additional fields that client may want to store along with the task',
                    items: {
                        type: 'FIELD',
                        id: 'additionalHeader',
                        fieldType: FormFieldTypeEnum.TEXT_FIELD,
                        title: 'Additional Header',
                        helpText:
                            'Provide any additional columns you want to pass in the batch upload',
                    },
                },
            ],
        },
    ],
};

export default taskProfileFormObject;
