//@flow

import * as React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
    default as MuiButton,
    type ButtonProps,
} from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => {
    const {
        palette: { common, grey },
    } = theme;
    return {
        themedButton: {
            borderRadius: '2px',
        },
        greyButton: {
            background: grey[800],
            color: common.white,
            '&:hover': {
                backgroundColor: grey[700],
            },
        },
    };
});

type SupportedColorsType = 'grey';
type ButtonPropsType = {
    ...ButtonProps,
    type?: string,
    variantColor: SupportedColorsType,
};

const Button = (props: ButtonPropsType) => {
    const {
        className,
        size,
        variant = 'contained',
        color,
        variantColor,
        disabled,
        startIcon,
        endIcon,
        onClick,
        children,
        fullWidth,
        type,
    } = props;
    const classes = useStyles();
    const variantColorClassName = `${variantColor}Button`;
    return (
        <MuiButton
            className={classnames(className, classes.themedButton, {
                [classes[variantColorClassName]]: variantColor,
            })}
            color={color}
            size={size}
            variant={variant}
            fullWidth={fullWidth}
            disabled={disabled}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            type={type}
        >
            {children}
        </MuiButton>
    );
};

export default Button;
