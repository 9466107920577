//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import { ApiButton } from 'components/core/button';
import addProviderFormObject from './AddProviderFormObject';
import useAddProvider from './useAddProvider';
import Form from 'components/core/form';
import FileUtils from 'utils/FileUtils';

const AddProvider = (): React.Node => {
    const formRef = React.useRef();
    const useAddProviderMutation = useAddProvider();

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const onSubmit = (data) => {
        const { formData } = data;
        const {
            assets: {
                smallLogoContent,
                largeLogoContent,
                faviconContent,
                termsAndCondtionsDocumentUrl,
            },
            ...rest
        } = formData;
        const provider = {
            ...rest,
            assets: {
                smallLogoContent: FileUtils.extractBase64String(
                    smallLogoContent,
                ),
                largeLogoContent: FileUtils.extractBase64String(
                    largeLogoContent,
                ),
                faviconContent: FileUtils.extractBase64String(faviconContent),
                termsAndCondtionsDocumentUrl,
            },
        };
        useAddProviderMutation.mutate({ provider });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
        >
            <Box width="50%">
                <Form
                    section={addProviderFormObject}
                    ref={formRef}
                    onSubmit={onSubmit}
                />
                <ApiButton
                    apiStatus={useAddProviderMutation.status}
                    successMessage={'Provider added/updated successfully'}
                    errorMessage={useAddProviderMutation.error?.message}
                    onClick={triggerClick}
                />
            </Box>
        </Box>
    );
};

export default AddProvider;
