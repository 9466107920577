//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import { ApiButton } from 'components/core/button';
import Form from 'components/core/form';
import TeamSelect from 'components/common/TeamSelect';
import { H3, Body1 } from 'components/core/typography';
import Loader from 'components/core/loader';
import { useTeams } from 'hooks/team';
import { useClientId } from 'hooks/client';

import { ApiStatusEnum } from 'enums/API';

import teamBrandingFormObjectBuilder from './TeamBrandingFormObject';
import { useFileUpload, useBrandingFormValidation } from 'hooks/common';
import { getStatusAndErrorFields } from 'utils/BrandingUtils';

const TeamBranding = (): React.Node => {
    const clientId = useClientId();
    const teamsQuery = useTeams(clientId);
    const [selectedTeamId, setSelectedTeamId] = React.useState(null);
    const [status, setStatus] = React.useState(ApiStatusEnum.Idle);

    const fieldsWithError = React.useRef([]);
    const fileUploadMutation = useFileUpload();

    const onSubmit = (data) => {
        const { formData } = data;
        const { logo, favicon, vehicleIcon } = formData;
        fieldsWithError.current = [];

        if (!selectedTeamId) {
            return;
        }

        if (logo || favicon || vehicleIcon) {
            setStatus(ApiStatusEnum.Loading);
        }

        const payload = {
            logo,
            favicon,
            'vehicle-icon': vehicleIcon,
        };

        fileUploadMutation.mutateAsync(
            { clientId, teamId: selectedTeamId, payload },
            {
                onSuccess: (resp: Array<any>) => {
                    const { status, errors } = getStatusAndErrorFields(resp);
                    fieldsWithError.current = errors;
                    setStatus(status);
                },
            },
        );
    };

    const [
        extraErrors,
        onFaviconLoad,
        onVehicleIconLoad,
    ] = useBrandingFormValidation();
    const teamBrandingFormObject = React.useMemo(
        () =>
            teamBrandingFormObjectBuilder({ onFaviconLoad, onVehicleIconLoad }),
        [onFaviconLoad, onVehicleIconLoad],
    );

    if (teamsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    const { current: fieldsFaileToUpdate } = fieldsWithError;
    const isSubmitDisabled = !selectedTeamId;

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="65%"
        >
            <H3 align="center">Update Teams Branding</H3>
            {teamsQuery.status === ApiStatusEnum.Error ? (
                <Box pb={1} width="100%">
                    <Alert severity="error">{teamsQuery.error.message}</Alert>
                </Box>
            ) : teamsQuery.data.length === 0 ? (
                <Body1> No Teams Found</Body1>
            ) : (
                <>
                    <Box px={1} width="100%">
                        <TeamSelect
                            teams={teamsQuery.data}
                            value={selectedTeamId}
                            onChange={setSelectedTeamId}
                        />
                    </Box>

                    <Form
                        section={teamBrandingFormObject}
                        onSubmit={onSubmit}
                        extraErrors={extraErrors}
                    >
                        <ApiButton
                            disabled={isSubmitDisabled}
                            apiStatus={status}
                            successMessage="Teams branding changed successfully"
                            errorMessage={`Error: Failed to update ${fieldsFaileToUpdate.join(
                                ', ',
                            )}`}
                        />
                    </Form>
                </>
            )}
        </Box>
    );
};

export default TeamBranding;
