// @flow
import { useMutation, type MutationResult } from 'react-query';

import { apiInstance } from 'middleware/axios';
import { type UISettingsModelType } from 'models/apps/businessUnit';

type Variables = {
    clientId: string,
    businessUnitId: string,
    settings: UISettingsModelType,
    reason: string,
};

type Result = {
    payload: string,
};

export default function useUpdateBUSettings(): MutationResult<
    Variables,
    Result,
> {
    return useMutation<Variables, Result>(
        ({ clientId, businessUnitId, reason, settings }) => {
            const payload = JSON.stringify(settings);
            return apiInstance.post(
                `client/${clientId}/businessUnit/${businessUnitId}/settings`,
                { payload, reason },
            );
        },
    );
}
