//@flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    default as MuiTextField,
    type TextFieldProps,
} from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        inputField: {
            borderRadius: '2px',
            '&:hover': {
                borderColor: grey[500],
            },
        },
        notchedOutline: {
            '&:hover': {
                border: 'none',
            },
        },
    };
});

const Input = (props: TextFieldProps) => {
    const {
        size = 'small',
        type,
        variant = 'outlined',
        fullWidth,
        value,
        defaultValue,
        margin = 'none',
        multiline,
        placeholder,
        rows,
        onChange,
        inputProps,
        InputProps,
        InputLabelProps,
    } = props;
    const classes = useStyles();
    const handleChange = (event) => {
        const { value: updatedValue } = event.target;
        onChange?.(updatedValue);
    };

    return (
        <MuiTextField
            value={value}
            defaultValue={defaultValue}
            multiline={multiline}
            placeholder={placeholder}
            rows={rows}
            fullWidth={fullWidth}
            margin={margin}
            size={size}
            type={type}
            variant={variant}
            onChange={handleChange}
            inputProps={inputProps}
            InputProps={{
                ...InputProps,
                className: InputProps?.className || classes.inputField,
                classes: InputProps?.classes || {
                    notchedOutline: classes.notchedOutline,
                },
            }}
            InputLabelProps={InputLabelProps}
        />
    );
};

export default Input;
export { Input };
