//@flow

import { useParams } from 'react-router-dom';
import invariant from 'utils/invariant';

const useClientId = (): string => {
    const { clientId } = useParams();
    invariant(clientId, 'Expected clientId to be present');
    return clientId;
};

export default useClientId;
