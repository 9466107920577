//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import PaginationSizeChooser from './PaginationSizeChooser';
import PageChooser from './PageChooser';
import GoToPage from './GoToPage';
import { type PaginationProps } from '../types';

const startPage = 1;

const useStyles = makeStyles({
    PaginationWrapper: {
        width: '100%',
        padding: '0% 5% 0% 5%',
    },
});

const Pagination = <T>({ tableInstance }: PaginationProps<T>): React.Node => {
    const {
        pageCount,
        canPreviousPage,
        canNextPage,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
    } = tableInstance;
    const [currentPage, setCurrentPage] = React.useState<number>(pageIndex + 1);
    const classes = useStyles();

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            className={classes.PaginationWrapper}
        >
            <Grid container>
                <Grid item xs={2}>
                    <PaginationSizeChooser
                        pageSize={pageSize}
                        onChange={setPageSize}
                    />
                </Grid>
                <Grid item xs={8}>
                    <PageChooser
                        currentPage={currentPage}
                        pageCount={pageCount}
                        setCurrentPage={setCurrentPage}
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                    />
                </Grid>
                <Grid item xs={2}>
                    <GoToPage
                        currentPage={currentPage}
                        startPage={startPage}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export { Pagination };
export default Pagination;
