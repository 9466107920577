//@flow
export const ServicesForPostBodyEnum = Object.freeze({
    TAXY: 'TAXY',
    OMS: 'OMS',
    PLATFORM: 'PLATFORM',
});

export type ServicesForPostBodyEnumType = $Values<
    typeof ServicesForPostBodyEnum,
>;
