//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { Body1 } from 'components/core/typography';

const sizes = {
    small: {
        width: '72px',
    },
};

export type LabelBoxPropsTypes = {
    label: string,
};

const useStyles = makeStyles((theme) => {
    const {
        palette: { grey },
    } = theme;
    return {
        LabelBox: {
            width: (props) => sizes[props.size || 'small'].width,
            backgroundColor: grey[100],
            border: `1px solid ${grey[200]}`,
            color: grey[700],
        },
    };
});

const Label = (props: LabelBoxPropsTypes): React.Node => {
    const { label } = props;
    const classes = useStyles(props);
    return (
        <Box
            className={classes.LabelBox}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            p={1}
        >
            <Body1>{label}</Body1>
        </Box>
    );
};

export default Label;
