//@flow

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper, makeStyles } from '@material-ui/core';
import { H3 } from 'components/core/typography';
import Button from 'components/core/button';
import vaeConfigsRegistry from 'resources/vaeConfigRegistry.json';
import { useToast } from 'containers/toast/Toast';
import { ApiStatusEnum } from 'enums/API';
import { useUpdateVaeConfig } from 'hooks/client';
import VaeConfigForm from './VaeConfigForm';

const { baseConfiguration } = vaeConfigsRegistry;
const useStyles = makeStyles({
    fixedBottomBar: {
        position: 'fixed',
        top: 'auto',
        bottom: '0px',
    },
    actionButtons: {
        gap: '8px',
    },
});

type Props = {
    clientId: string,
    onNextClick: () => void,
    finish: () => void,
};

const VaeConfig = (props: Props): React.Node => {
    const { onNextClick } = props;
    const classes = useStyles();
    const { clientId } = useParams();
    const formRef = React.useRef();
    const [defaultConfiguration, setDefaultConfiguration] = React.useState(
        JSON.parse(baseConfiguration),
    );

    const { showToast } = useToast();

    const [value, onChange] = React.useState({
        configuration: JSON.parse(baseConfiguration),
        useCase: 'spmd',
    });

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const updateVaeConfigMutation = useUpdateVaeConfig();
    const onSubmit = () => {
        const { configuration } = value;
        updateVaeConfigMutation.mutate(
            {
                clientId,
                vaeConfig: configuration,
                reason:
                    'Setting up base VAE configuration from internal dashboard',
            },
            {
                onSuccess: () => {
                    setDefaultConfiguration(value.configuration);
                    onNextClick();
                },
                onError: (error) => {
                    showToast(error.message);
                },
            },
        );
    };

    return (
        <Box width="100%" height="100%" pb={2}>
            <H3 align="center">VAE Config</H3>
            <Box px={10}>
                <VaeConfigForm
                    ref={formRef}
                    value={value}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    defaultConfiguration={defaultConfiguration}
                    defaultUsecase={'spmd'}
                />
            </Box>
            <Box className={classes.fixedBottomBar} width="100%">
                <Paper variant="outlined">
                    <Box
                        mx={10}
                        display="flex"
                        flexDirection="row-reverse"
                        className={classes.actionButtons}
                        py={1}
                    >
                        <Button
                            type="submit"
                            color="primary"
                            onClick={triggerClick}
                            disabled={
                                updateVaeConfigMutation.status ===
                                ApiStatusEnum.Loading
                            }
                        >
                            SET VAE CONFIGURATION
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default VaeConfig;
