//@flow

import * as React from 'react';
import { Box, Paper, makeStyles } from '@material-ui/core';

import Form from 'components/core/form';
import Button from 'components/core/button';
import { ApiStatusEnum } from 'enums/API';
import { useToast } from 'containers/toast/Toast';

import useAddDashSettings from './useAddDashSettings';
import dashSettingsFormObject from './DashSettingsFormObject';

type Props = {
    clientId: string,
    onNextClick: () => void,
    finish: () => void,
};

const useStyles = makeStyles({
    fixedBottomBar: {
        position: 'fixed',
        top: 'auto',
        bottom: '0px',
    },
    actionButtons: {
        gap: '8px',
    },
});

const DashSettings = (props: Props): React.Node => {
    const { clientId, onNextClick } = props;
    const classes = useStyles();
    const formRef = React.useRef();
    const { showToast } = useToast();

    const addDashSettingsMutation = useAddDashSettings();

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const onSubmit = (data) => {
        const { formData } = data;
        addDashSettingsMutation.mutate(
            { clientId, settings: formData, overwrite: true },
            {
                onSuccess: onNextClick,
                onError: (error) => {
                    showToast(error.message);
                },
            },
        );
    };

    return (
        <Box
            width="100%"
            height="100%"
            py={2}
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
        >
            <Box px={10} width="100%">
                <Form
                    section={dashSettingsFormObject}
                    ref={formRef}
                    onSubmit={onSubmit}
                />
            </Box>
            <Box className={classes.fixedBottomBar} width="100%">
                <Paper variant="outlined">
                    <Box
                        mx={10}
                        display="flex"
                        flexDirection="row-reverse"
                        className={classes.actionButtons}
                        py={1}
                    >
                        <Button
                            type="submit"
                            size="small"
                            color="primary"
                            onClick={triggerClick}
                            disabled={
                                addDashSettingsMutation.status ===
                                ApiStatusEnum.Loading
                            }
                        >
                            UPDATE DASH SETTINGS
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default DashSettings;
