//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { ErrorIcon } from 'components/core/icons';
import { Body2 } from 'components/core/typography';

type Props = {
    message: string,
};

const useStyles = makeStyles({
    errorMessage: {
        lineHeight: 'normal',
    },
    errorMessageWrap: {
        gap: '4px',
    },
});

const ErrorMessage = (props: Props): React.Node => {
    const { message } = props;
    const classes = useStyles();
    return (
        <Box
            className={classes.errorMessageWrap}
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
        >
            <ErrorIcon />
            <Body2 className={classes.errorMessage} color="textSecondary">
                {message}
            </Body2>
        </Box>
    );
};

export default ErrorMessage;
