//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { FieldDependencyTypeEnum } from 'enums/FieldDependencyType';
import { FairnessMetricType } from 'enums/FairnessMetricType';
import { FleetOptimizationMetricType } from 'enums/FleetOptimizationMetricType';
import { TimeSlotDistributionEnum } from 'enums/TimeSlotDistributionType';

const VaeConfigFormObject: FormSectionType = {
    id: 'vaeConfig',
    type: 'SECTION',
    subSections: [
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.SELECT,
            title: 'Select Usecase',
            id: 'useCase',
            options: {
                items: [
                    {
                        id: 'SPMD',
                        label: 'SPMD',
                        value: 'spmd',
                    },
                    {
                        id: 'MPSD',
                        label: 'MPSD',
                        value: 'mpsd',
                    },
                    {
                        id: 'MPMD',
                        label: 'MPMD',
                        value: 'mpmd',
                    },
                    {
                        id: 'intercity',
                        label: 'Intercity',
                        value: 'intercity',
                    },
                    {
                        id: 'longhaul',
                        label: 'Long Haul',
                        value: 'longhaul',
                    },
                    {
                        id: 'services',
                        label: 'Services',
                        value: 'services',
                    },
                ],
                itemsType: FormPropertyTypeEnum.STRING,
            },
            required: true,
            uiOptions: {
                width: '25%',
            },
        },
        {
            type: 'SECTION',
            id: 'form',
            title: '',
            subSections: [
                {
                    type: 'FIELD',
                    id: 'runOnlyWAE',
                    title: 'Run WAE alone (no VAE)?',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    initialValue: false,
                    helpText: 'If wae is run, all other configs are ignored',
                    uiOptions: {
                        width: '25%',
                    },
                },
                {
                    type: 'ROW',
                    id: 'row1',
                    fields: [
                        {
                            type: 'COLUMN',
                            id: 'column1',
                            fields: [
                                {
                                    type: 'SECTION',
                                    id: 'timeSlotDitributionConfig',
                                    title: 'Time Windows',
                                    subSections: [
                                        {
                                            type: 'FIELD',
                                            id: 'timeSlotDistribution',
                                            title: '',
                                            options: {
                                                items: [
                                                    {
                                                        id:
                                                            TimeSlotDistributionEnum.AUTOMATIC,
                                                        label:
                                                            'Single Time Window Across Orders',
                                                        value:
                                                            TimeSlotDistributionEnum.AUTOMATIC,
                                                    },
                                                    {
                                                        id:
                                                            TimeSlotDistributionEnum.MULTIPLE,
                                                        label:
                                                            'Multiple Time Window Across Orders',
                                                        value:
                                                            TimeSlotDistributionEnum.MULTIPLE,
                                                    },
                                                ],
                                                itemsType:
                                                    FormPropertyTypeEnum.STRING,
                                            },
                                            fieldType:
                                                FormFieldTypeEnum.RADIO_BUTTONS,
                                        },
                                    ],
                                },
                                {
                                    type: 'SECTION',
                                    id: 'zoneBasedRouting',
                                    title: 'Zone Based Routing',
                                    subSections: [
                                        {
                                            type: 'FIELD',
                                            id: 'enableZoneBasedRouting',
                                            title: 'Enable Zone Based Routing?',
                                            fieldType: FormFieldTypeEnum.SWITCH,
                                            initialValue: false,
                                            helpText:
                                                'If zones are not created for this team',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'COLUMN',
                            id: 'column2',
                            fields: [
                                {
                                    type: 'SECTION',
                                    id: 'waeCapacityConstraints',
                                    title: 'Capacity Constraints',
                                    subSections: [
                                        {
                                            type: 'FIELD',
                                            id:
                                                'capacityConstraintBreachAllowed',
                                            title:
                                                'Is it okay to breach capacity constraints of vehicles?',
                                            fieldType: FormFieldTypeEnum.SWITCH,
                                            initialValue: false,
                                        },
                                        {
                                            type: 'FIELD',
                                            id:
                                                'capacityConstraintBreachFraction',
                                            title: 'By How Much %age',
                                            fieldType:
                                                FormFieldTypeEnum.NUMBER_FIELD,
                                            initialValue: 0.15,
                                            dependency: {
                                                dependeeFieldId:
                                                    'capacityConstraintBreachAllowed',
                                                dependeeFieldValue: true,
                                                dependencyType:
                                                    FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    dependency: {
                        dependeeFieldId: 'runOnlyWAE',
                        dependeeFieldValue: [true],
                        dependencyType:
                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                    },
                },
                {
                    type: 'ROW',
                    id: 'row2',
                    fields: [
                        {
                            type: 'ROW',
                            id: 'innerRow',
                            fields: [
                                {
                                    type: 'COLUMN',
                                    id: 'column1',
                                    fields: [
                                        {
                                            type: 'SECTION',
                                            id: 'routeRestrictions',
                                            title: 'Route Restrictions',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'enableRouteRestrictions',
                                                    title:
                                                        'Are route restrictions present?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'skills',
                                            title: 'Skills',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id: 'maximizeMatch',
                                                    title:
                                                        'Is it preferred to maximize the matches between vehicle skills and the skills of the tasks it caters to? (Applicable with optional or multiple skills.)',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'capacityConstraints',
                                            title: 'Capacity Constraints',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id: 'canOverloadVehicles',
                                                    title:
                                                        'Are vehicles allowed to be overloaded beyond their volume capacity constraints?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                                {
                                                    type: 'FIELD',
                                                    id: 'overloadPercentage',
                                                    initialValue: 0.15,
                                                    title:
                                                        'Percentage of volume overload allowed.',
                                                    fieldType:
                                                        FormFieldTypeEnum.NUMBER_FIELD,
                                                    dependency: {
                                                        dependeeFieldId:
                                                            'canOverloadVehicles',
                                                        dependeeFieldValue: true,
                                                        dependencyType:
                                                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'zoneRestrictions',
                                            title: 'Zone Restrictions',
                                            subSections: [
                                                {
                                                    id:
                                                        'enableZoneRestrictions',
                                                    title:
                                                        'Are there zoning restrictions set up in the backend and it is desirable to use them?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    type: 'FIELD',
                                                    initialValue: false,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'userRatings',
                                            title: 'User Ratings',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'enableUserRatingMetric',
                                                    title:
                                                        'Are some riders more preferable over others, especially for high priority tasks?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'multipleHomebases',
                                            title: 'Multiple Homebases',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id: 'enableHomebaseShuffle',
                                                    title:
                                                        'Do you want to optimize the choice of homebase with multiple homebases options present for at least one task?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    type: 'COLUMN',
                                    id: 'column2',
                                    fields: [
                                        {
                                            type: 'SECTION',
                                            id: 'fleetOptimization',
                                            title: 'Fleet Optimization',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'fleetOptimizationMetric',
                                                    title: '',
                                                    options: {
                                                        items: [
                                                            {
                                                                id:
                                                                    FleetOptimizationMetricType.NONE,
                                                                label: 'None',
                                                                value:
                                                                    FleetOptimizationMetricType.NONE,
                                                            },
                                                            {
                                                                id:
                                                                    FleetOptimizationMetricType.USE_ALL_VEHICLES,
                                                                label:
                                                                    'Is it desirable to use all vehicles?',
                                                                value:
                                                                    FleetOptimizationMetricType.USE_ALL_VEHICLES,
                                                            },
                                                            {
                                                                id:
                                                                    FleetOptimizationMetricType.NUMBER_OF_VEHICLES,
                                                                label:
                                                                    'Is it desirable to optimize the number of vehicles used?',
                                                                value:
                                                                    FleetOptimizationMetricType.NUMBER_OF_VEHICLES,
                                                            },
                                                        ],
                                                        itemsType:
                                                            FormPropertyTypeEnum.STRING,
                                                    },
                                                    fieldType:
                                                        FormFieldTypeEnum.RADIO_BUTTONS,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'tardinessAndSlots',
                                            title: 'Tardiness and Slots',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id: 'tardinessSla',
                                                    title:
                                                        'How long can a delay in seconds be allowed for attending to a task, (a.k.a. tardiness SLA)? (If it is not possible to cater to this requirement the task may get unallocated.)',
                                                    fieldType:
                                                        FormFieldTypeEnum.NUMBER_FIELD,
                                                    initialValue: 1800,
                                                },
                                                {
                                                    type: 'FIELD',
                                                    id: 'allowedTardiness',
                                                    title:
                                                        'When multiple slots are present for a single task, how much delay can be allowed for an earlier slot before deciding to move on to a later slot?',
                                                    fieldType:
                                                        FormFieldTypeEnum.NUMBER_FIELD,
                                                    initialValue: 1800,
                                                },
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'considerSlotEndForTardiness',
                                                    title:
                                                        'Should the task be considered tardy if not completed within the slot end time (rather than if not started before the slot end time)?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'treatNonCustomerVisitTardinessDifferently',
                                                    title:
                                                        'Should tardiness related to vehicle visits (breaks, return to homebase, etc.) be treated differently to customer visit tardiness?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'nonCustomerVisitTardinessSla',
                                                    title:
                                                        'How long a delay is acceptable for vehicle related visits (such as breaks, start and end points)?',
                                                    fieldType:
                                                        FormFieldTypeEnum.NUMBER_FIELD,
                                                    initialValue: 1800,
                                                    dependency: {
                                                        dependeeFieldId:
                                                            'treatNonCustomerVisitTardinessDifferently',
                                                        dependeeFieldValue: true,
                                                        dependencyType:
                                                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                                    },
                                                    required: true,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'fairness',
                                            title: 'Fairness',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id: 'fairnessMetric',
                                                    title: '',
                                                    options: {
                                                        items: [
                                                            {
                                                                id:
                                                                    FairnessMetricType.NONE,
                                                                label: 'None',
                                                                value:
                                                                    FairnessMetricType.NONE,
                                                            },
                                                            {
                                                                id:
                                                                    FairnessMetricType.TASK,
                                                                label:
                                                                    'Is fairness in task count across tours a concern?',
                                                                value:
                                                                    FairnessMetricType.TASK,
                                                            },
                                                            {
                                                                id:
                                                                    FairnessMetricType.DISTANCE,
                                                                label:
                                                                    'Is fairness in distance travelled across tours a concern?',
                                                                value:
                                                                    FairnessMetricType.DISTANCE,
                                                            },
                                                        ],
                                                        itemsType:
                                                            FormPropertyTypeEnum.STRING,
                                                    },
                                                    fieldType:
                                                        FormFieldTypeEnum.RADIO_BUTTONS,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'volumePriority',
                                            title: 'Volume Priority',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'prioritizeLargerVolumeTasks',
                                                    title:
                                                        'Do you want higher volume tasks to be preferred in allocation',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'costBasedOptimization',
                                            title: 'Cost Based Optimization',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'enableCostBasedOptimization',
                                                    title:
                                                        'Enable Cost Based Optimization?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    type: 'COLUMN',
                                    id: 'column3',
                                    fields: [
                                        {
                                            type: 'SECTION',
                                            id: 'locationRevisits',
                                            title: 'Location Revisits',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'avoidCustomerOrPlaceMultipleVisit',
                                                    title:
                                                        'Is any vehicle reentering the same location ("place") multiple times a serious concern? A "place" could be a gated community, apartment, gated market area.',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'avoidCustomerMultipleVehiclesVisit',
                                                    title:
                                                        'Is more than one vehicle visiting a customer a serious concern?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                                {
                                                    type: 'FIELD',
                                                    id:
                                                        'avoidPlaceMultipleVehiclesVisit',
                                                    title:
                                                        'Is more than one vehicle visiting the same location ("place") a serious concern? A "place" could be a gated community, apartment, gated market area.',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'multipleTrips',
                                            title: 'Multiple Trips',
                                            subSections: [
                                                {
                                                    type: 'FIELD',
                                                    id: 'multipleTripsAllowed',
                                                    title:
                                                        'Allow Multiple Trips?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    initialValue: false,
                                                },
                                                {
                                                    type: 'FIELD',
                                                    id: 'vehicleTripCountLimit',
                                                    title:
                                                        'Upper limit of number of trip counts per tour.',
                                                    fieldType:
                                                        FormFieldTypeEnum.NUMBER_FIELD,
                                                    initialValue: 3,
                                                    dependency: {
                                                        dependeeFieldId:
                                                            'multipleTripsAllowed',
                                                        dependeeFieldValue: true,
                                                        dependencyType:
                                                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'tat',
                                            title: 'Transaction Time Config',
                                            subSections: [
                                                {
                                                    id: 'enableTatClubbing',
                                                    title:
                                                        'Should TAT be added differently for orders in certain situations (such as orders for the same customer)?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    type: 'FIELD',
                                                    initialValue: false,
                                                },
                                                {
                                                    id: 'sameCustomerTat',
                                                    title:
                                                        'TAT for every task following the first task, for a consecutive group of tasks for the same customer.',
                                                    fieldType:
                                                        FormFieldTypeEnum.INTEGER_FIELD,
                                                    type: 'FIELD',
                                                    initialValue: 60,
                                                    dependency: {
                                                        dependeeFieldId:
                                                            'enableTatClubbing',
                                                        dependeeFieldValue: true,
                                                        dependencyType:
                                                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                                    },
                                                },
                                                {
                                                    id: 'sameLocationTat',
                                                    title:
                                                        'TAT for every task following the first task, for a consecutive group of tasks at the same location.',
                                                    fieldType:
                                                        FormFieldTypeEnum.INTEGER_FIELD,
                                                    type: 'FIELD',
                                                    initialValue: 60,

                                                    dependency: {
                                                        dependeeFieldId:
                                                            'enableTatClubbing',
                                                        dependeeFieldValue: true,
                                                        dependencyType:
                                                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                                    },
                                                },
                                                {
                                                    id: 'useVehicleLoadingTime',
                                                    title:
                                                        'Use vehicle loading and unloading times in the vehicle model, if present.',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    type: 'FIELD',
                                                    initialValue: true,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'SECTION',
                                            id: 'vehicleBreaks',
                                            title: 'Vehicle Breaks',
                                            subSections: [
                                                {
                                                    id: 'hasVehicleBreaks',
                                                    title:
                                                        'Are vehicle breaks present?',
                                                    fieldType:
                                                        FormFieldTypeEnum.SWITCH,
                                                    type: 'FIELD',
                                                    initialValue: false,
                                                },
                                                {
                                                    id: 'vehicleBreaksScheme',
                                                    title:
                                                        'Type of vehicle breaks',
                                                    options: {
                                                        items: [
                                                            {
                                                                id: 'timeSlot',
                                                                label:
                                                                    'Time slot based breaks',
                                                                value:
                                                                    'TIME_SLOT',
                                                            },
                                                            {
                                                                id: 'hosBased',
                                                                label:
                                                                    'HOS Based Breaks',
                                                                value:
                                                                    'TIME_RULE_BASED',
                                                            },
                                                        ],
                                                        itemsType:
                                                            FormPropertyTypeEnum.STRING,
                                                    },
                                                    fieldType:
                                                        FormFieldTypeEnum.SELECT,
                                                    type: 'FIELD',
                                                    initialValue: false,
                                                    dependency: {
                                                        dependeeFieldId:
                                                            'hasVehicleBreaks',
                                                        dependeeFieldValue: true,
                                                        dependencyType:
                                                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                                    },
                                                },
                                                {
                                                    id: 'maxBreaksPerTour',
                                                    title:
                                                        'Largest number of breaks allowed per tour',
                                                    fieldType:
                                                        FormFieldTypeEnum.NUMBER_FIELD,
                                                    type: 'FIELD',
                                                    dependency: {
                                                        dependeeFieldId:
                                                            'vehicleBreaksScheme',
                                                        dependeeFieldValue:
                                                            'TIME_SLOT',
                                                        dependencyType:
                                                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                                    },
                                                },
                                                {
                                                    id:
                                                        'maxNightBreakTardiness',
                                                    title:
                                                        'Largest delay allowed for night breaks (in seconds)',
                                                    fieldType:
                                                        FormFieldTypeEnum.NUMBER_FIELD,
                                                    type: 'FIELD',
                                                    initialValue: 60,
                                                    dependency: {
                                                        dependeeFieldId:
                                                            'vehicleBreaksScheme',
                                                        dependeeFieldValue:
                                                            'TIME_SLOT',
                                                        dependencyType:
                                                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    dependency: {
                        dependeeFieldId: 'runOnlyWAE',
                        dependeeFieldValue: [false],
                        dependencyType:
                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                    },
                },
            ],
            dependency: {
                dependeeFieldId: 'useCase',
                dependeeFieldValue: [
                    'spmd',
                    'mpsd',
                    'intercity',
                    'services',
                    'longhaul',
                ],
                dependencyType:
                    FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
            },
        },
    ],
};

export default VaeConfigFormObject;
