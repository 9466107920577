// @flow
import { useMutation, type MutationResult } from 'react-query';

import { instance } from 'middleware/axios';

type Variables = {
    clientId: string,
    overwrite?: boolean,
    settings: $FixMe,
};

export default function useAddDashSettings(): MutationResult<Variables, void> {
    return useMutation<Variables, void>(
        ({ clientId, overwrite = true, settings }) => {
            return instance.put(
                `client/${clientId}/settings?overwrite=${overwrite.toString()}`,
                settings,
            );
        },
    );
}
