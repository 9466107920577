//@flow

export const FleetOptimizationMetricType = Object.freeze({
    NONE: 'NONE',
    USE_ALL_VEHICLES: 'USE_ALL_VEHICLES',
    NUMBER_OF_VEHICLES: 'NUMBER_OF_VEHICLES',
});

export type FleetOptimizationMetricEnumType = $Values<
    typeof FleetOptimizationMetricType,
>;
