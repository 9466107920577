//@flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { history } from 'store/configureStore';
import Tabs from 'components/core/tabs';
import { tabs, getTabIndexFromId } from './Tabs';
import ClientAppItemsRoutes from 'containers/client/routes/ClientAppItemsRoutes';

const ClientTabsContainer = (props) => {
    const {
        match: {
            params: { clientId, appId },
        },
    } = props;

    const tabIndex = getTabIndexFromId(appId);
    if (tabIndex === -1) {
        return <Redirect to="/no-match" />;
    }
    const tab = tabs[tabIndex];

    const onChange = (tabIndex) => {
        const { id: appId } = tabs[tabIndex];
        return history.replace(`/client/${clientId}/app/${appId}`);
    };

    return (
        <>
            <Tabs selectedTabIndex={tabIndex} tabs={tabs} onChange={onChange} />
            <ClientAppItemsRoutes tab={tab} {...props} />
        </>
    );
};

export default ClientTabsContainer;
