//@flow
import * as React from 'react';
import Box from '@material-ui/core/Box';

import { ApiButton } from 'components/core/button';
import Form from 'components/core/form';
import { createGenericClientFormObject } from './CreateClientFormObject';
import { history } from 'store/configureStore';

import { useCreateOrderIqClient } from 'hooks/client';

const AddOrderIqClientContainer = (): React.Node => {
    const orderIqCreateClientMutation = useCreateOrderIqClient();

    const formRef = React.useRef();

    const onSubmit = (data) => {
        const { clientId, ...client } = data.formData;
        orderIqCreateClientMutation.mutate(
            {
                clientId,
                client,
                reason: 'Creating Order Iq Client from internal dashboard',
            },
            {
                onSuccess: () => {
                    history.push(`client/${clientId}/configure`);
                },
            },
        );
    };

    return (
        <Box width="400px" p={2}>
            <Form
                ref={formRef}
                section={createGenericClientFormObject}
                onSubmit={onSubmit}
            />
            <ApiButton
                apiStatus={orderIqCreateClientMutation.status}
                successMessage="Order Iq Client Created Successfully"
                errorMessage={orderIqCreateClientMutation.error?.message}
                onClick={() => {
                    formRef.current?.submit();
                }}
            />
        </Box>
    );
};

export default AddOrderIqClientContainer;
