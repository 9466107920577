//@flow

import * as React from 'react';
import { Snackbar } from '@material-ui/core';
import { CloseIcon } from 'components/core/icons';

export type AnchorOriginType = {
    vertical: 'top' | 'bottom',
    horizontal: 'left' | 'center' | 'right',
};

type Props = {
    open: boolean,
    message: string,
    autoHideDuration?: number,
    anchorOrigin?: AnchorOriginType,
    actions?: React.Node,
    onClose: (event: SyntheticEvent<>) => void,
};

const Toast = (props: Props): React.Node => {
    const {
        open,
        message,
        autoHideDuration,
        anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
        actions,
        onClose,
    } = props;

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            message={message}
            action={
                <>
                    {actions}
                    <CloseIcon onClick={onClose} />
                </>
            }
        />
    );
};

export default Toast;
