//@flow

import * as React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import TextField from 'components/core/input';
import { Body2 } from 'components/core/typography';

type Props = {
    pageCount: number,
    startPage: number,
    currentPage: number,
    setCurrentPage: (number) => void,
    gotoPage: (updater: ((pageIndex: number) => number) | number) => void,
};

const GoToPage = (props: Props): React.Node => {
    const {
        startPage,
        pageCount,
        currentPage,
        setCurrentPage,
        gotoPage,
    } = props;
    const [draftPageNum, setDraftPageNum] = React.useState<number>(startPage);

    const onPageNumberInputChange = (value: string) => {
        setDraftPageNum(Number(value));
    };

    const onPageNumberChange = () => {
        const draftPageIndex = draftPageNum - 1;
        gotoPage(draftPageIndex);
        setCurrentPage(draftPageNum);
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height="100%"
        >
            <Body2> Go to page:</Body2>
            <Box p={1} width="90px">
                <TextField
                    fullWidth
                    margin="dense"
                    type="number"
                    defaultValue={startPage}
                    inputProps={{
                        min: startPage,
                        max: pageCount,
                    }}
                    onChange={onPageNumberInputChange}
                />
            </Box>
            <IconButton
                size="small"
                color="secondary"
                aria-label="navigate-next"
                onClick={onPageNumberChange}
                disabled={draftPageNum === currentPage}
            >
                <NavigateNextIcon />
            </IconButton>
        </Box>
    );
};

export default GoToPage;
