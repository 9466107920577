// @flow
import { useMutation, type MutationResult } from 'react-query';

import ClientSettingsUtils from 'utils/ClientSettingsUtils';

type Variables = {
    clientId: string,
    vaeConfig: $FixMe,
    reason: string,
};

export default function useUpdateVaeConfig(): MutationResult<Variables, void> {
    return useMutation<Variables, void>(({ clientId, reason, vaeConfig }) => {
        const pathLists = [
            {
                path: 'vaeConfiguration',
                value: vaeConfig,
            },
        ];
        return ClientSettingsUtils.fetchAndUpdateSettings(
            clientId,
            pathLists,
            reason,
        );
    });
}
