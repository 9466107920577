//@flow

import * as React from 'react';
import {
    useTable,
    useFilters,
    usePagination,
    type TableInstance,
    type Columns,
    type UseHooks,
} from 'react-table';
import filterTypes from 'components/core/table/TableFilterTypes';
import { rowSelectHooks } from './rowSelectHooks';

const defaultColumn = {
    Filter: <React.Fragment />,
};

const defaultPageSize = 15;

export const useTableInstance = <T>(
    {
        columns,
        data,
        idField,
        hiddenColumns = [],
        rowSelection = false,
    }: {
        columns: Columns<T>,
        data: Array<T>,
        idField: string,
        hiddenColumns?: Array<string>,
        rowSelection?: boolean,
    },
    ...plugins: Array<UseHooks>
): TableInstance<T> => {
    const rowSelectionHooks = rowSelection ? rowSelectHooks : [];
    const tableInstance = useTable<T>(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: {
                pageSize: defaultPageSize,
                hiddenColumns,
            },
            getRowId: (row) => row[idField],
        },
        useFilters,
        usePagination,
        ...rowSelectionHooks,
        ...plugins,
    );

    return tableInstance;
};
