//@flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { default as MuiList } from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

import { Body1 } from 'components/core/typography';

export type ListItemType = {
    id: string,
    value: string,
    checked?: boolean,
};

export type ListItemsType = Array<ListItemType>;

type ListPropsType = {
    items: ListItemsType,
    onClick: (id: string, checked?: boolean) => void,
};

const useStyles = makeStyles({
    paper: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        overflowY: 'scroll',
    },
});

const List = (props: ListPropsType) => {
    const { items, onClick } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <MuiList dense component="div" role="list" disablePadding>
                {items.map((item) => {
                    const { id, value, checked } = item;
                    return (
                        <ListItem
                            key={id}
                            role="listitem"
                            button
                            onClick={() => onClick(id, checked)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <Body1> {value} </Body1>
                        </ListItem>
                    );
                })}
                <ListItem />
            </MuiList>
        </Paper>
    );
};

export default List;
export { List };
