// @flow

import matchSorter from 'match-sorter';
import { type FilterTypes, type FilterType } from 'react-table';

const fuzzyTextFilterType: FilterType<any> = (rows, id, filterValue) => {
    const headers = [(row) => row.values[id]];
    // Match filter value in specific headers i.e keys in all rows
    return matchSorter(rows, filterValue, { keys: headers });
};

// To auto remove the filter if the string is empty
fuzzyTextFilterType.autoRemove = (val) => !val;

const TableFilterTypes: FilterTypes<any> = {
    fuzzyText: fuzzyTextFilterType,
};

export default TableFilterTypes;
