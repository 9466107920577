//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';

import Switch from 'components/core/switch';
import { type SchemaType } from 'models/Schema';
import { Infotip } from 'components/core/tooltip';

type Props = {
    label: string,
    value: boolean,
    schema: SchemaType,
    onChange: (value: any) => void,
};

const SwitchWidget = (props: Props): React.Node => {
    const {
        label,
        value,
        schema: { description },
        onChange,
    } = props;
    const checked = value || false;
    const toggleChecked = () => {
        onChange(!checked);
    };

    return (
        <Box alignItems="center" display="flex" spacing={1}>
            <Switch label={label} checked={checked} onChange={toggleChecked} />
            {description && (
                <Infotip title={description} customFontSize="14px" />
            )}
        </Box>
    );
};

export default SwitchWidget;
