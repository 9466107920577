//@flow

import * as React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import { type ButtonProps } from '@material-ui/core/Button';

import Button from './Button';

type EditButtonPropsTypes = {
    ...ButtonProps,
    fontSize?: 'inherit' | 'default' | 'small' | 'large',
    customFontSize?: 'string',
};

const EditButton = (props: EditButtonPropsTypes) => {
    const {
        fontSize = 'small',
        color = 'primary',
        customFontSize,
        ...restButtonProps
    } = props;
    return (
        <Button {...restButtonProps} variant="text" color={color}>
            <EditIcon
                // Apply font size different from allowed font size variables i.e default, small etc.
                style={{ fontSize: customFontSize }}
                fontSize={fontSize}
                color={color}
            />
            <Box px={1}>Edit</Box>
        </Button>
    );
};

export { EditButton };
