// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Toolbar, IconButton } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';

import Logo from 'images/locus_logo.png';
import WriteAccessIcon from 'images/write_access_icon.png';
import { history } from 'store/configureStore';
import Oncalls from 'components/oncalls/Oncalls';
import { useToast } from 'containers/toast/Toast';
import UserIconDropdown from './UserIconDropdown';

import { useRequestClientWriteAccess } from 'hooks/client';

const useStyles = makeStyles({
    leftMenu: {
        flexGrow: 1,
    },
    headerWrap: {
        height: '56px',
    },
    rightMenu: {
        gap: '16px',
    },
});

const AppHeader = (): React.Node => {
    const match = useRouteMatch('/client/:clientId');
    const clientId = match?.params.clientId;
    const classes = useStyles();
    const { showToast } = useToast();
    const requestWriteAccessMutation = useRequestClientWriteAccess();

    const onRequestWriteAccess = () => {
        requestWriteAccessMutation.mutate(
            {
                clientId,
                duration: 2,
            },
            {
                onSuccess: () => {
                    showToast('Write Access Provided for 2 min');
                },
                onError: (err) => {
                    showToast(err.message);
                },
            },
        );
    };

    const redirectToHomePage = () => {
        history.push('/');
    };

    return (
        <AppBar position="static" elevation={0} className={classes.headerWrap}>
            <Toolbar variant="dense">
                <Box
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    alignItems="center"
                >
                    <div className={classes.leftMenu}>
                        <IconButton
                            aria-label="logo"
                            color="inherit"
                            onClick={redirectToHomePage}
                        >
                            <img alt="Locus Logo" src={Logo} height="32" />
                        </IconButton>
                    </div>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-end"
                        className={classes.rightMenu}
                    >
                        <Oncalls />
                        <UserIconDropdown />
                    </Box>
                    {clientId && (
                        <IconButton
                            aria-label="write-access"
                            color="inherit"
                            onClick={onRequestWriteAccess}
                        >
                            <img
                                alt="Write Access"
                                src={WriteAccessIcon}
                                height="20"
                            />
                        </IconButton>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};
export default AppHeader;
