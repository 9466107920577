// @flow
import findIndex from 'lodash/findIndex';
import {
    ClientSettings,
    DashSettings,
    PasswordAuthSettings,
    MastersState,
    AddProvider,
    LinkProvider,
    ClientBranding,
    PostBodyLogger,
    ConfigureProduct,
} from 'containers/app/client';
import { ReseedMessages, FetchAndDeleteMessages } from 'containers/app/lqs';
import VaeConfig from 'containers/app/configuration/vae-config/VaeConfig';
import { TeamVaeConfig } from 'containers/app/configuration/vae-config/TeamVaeConfig';
import { ClientChecklists } from 'containers/app/lotr';
import { TeamSettings, TeamBranding } from 'containers/app/team';
import IdGeneratorContainer from 'containers/app/client/id-generator/IdGeneratorContainer';
import { BUSettings } from 'containers/app/businessUnit';
import { ManageCustomFields } from 'containers/app/entities';

const tabs = [
    {
        id: 'client',
        label: 'Client',
        items: [
            {
                id: 'masters-state',
                label: 'Enable / Disable Masters',
                component: MastersState,
            },
            {
                id: 'client-settings',
                label: 'Client Settings',
                component: ClientSettings,
            },
            {
                id: 'dash-settings',
                label: 'Dash Settings',
                component: DashSettings,
            },
            {
                id: 'password-auth-settings',
                label: 'Password Auth Settings',
                component: PasswordAuthSettings,
            },
            {
                id: 'id-generator',
                label: 'ID generators',
                component: IdGeneratorContainer,
            },
            {
                id: 'add-provider',
                label: 'Add Provider',
                component: AddProvider,
            },
            {
                id: 'link-provider',
                label: 'Link Provider',
                component: LinkProvider,
            },
            {
                id: 'branding',
                label: 'Branding',
                component: ClientBranding,
            },
            {
                id: 'configureProduct',
                label: 'Configure Product',
                component: ConfigureProduct,
            },
            {
                id: 'post-body-logger',
                label: 'Post Body Logger',
                component: PostBodyLogger,
            },
        ],
    },
    {
        id: 'lqs',
        label: 'Locus Queue Services',
        items: [
            {
                id: 'reseed-messages',
                label: 'Reseed Messages',
                component: ReseedMessages,
            },
            {
                id: 'delete-messages',
                label: 'Delete DLQ Messages',
                component: FetchAndDeleteMessages,
            },
        ],
    },
    {
        id: 'vaeConfig',
        label: 'VAE Config',
        items: [
            {
                id: 'vae-config',
                label: 'Update VAE Config',
                component: VaeConfig,
            },
            {
                id: 'team-vae-config',
                label: 'Update Team VAE Config',
                component: TeamVaeConfig,
            },
        ],
    },
    {
        id: 'lotr',
        label: 'LOTR',
        items: [
            {
                id: 'client-checklists',
                label: 'Client Checklists',
                component: ClientChecklists,
            },
        ],
    },
    {
        id: 'businessUnit',
        label: 'Business Unit',
        items: [
            {
                id: 'bu-settings',
                label: 'Business Unit Settings',
                component: BUSettings,
            },
        ],
    },
    {
        id: 'team',
        label: 'Team',
        items: [
            {
                id: 'team-settings',
                label: 'Team Settings',
                component: TeamSettings,
            },
            {
                id: 'branding',
                label: 'Branding',
                component: TeamBranding,
            },
        ],
    },
    {
        id: 'entities',
        label: 'Entities',
        items: [
            {
                id: 'manage-custom-fields',
                label: 'Manage Custom Fields',
                component: ManageCustomFields,
            },
        ],
    },
];

const getTabIndexFromId = (tabId) => {
    return findIndex(tabs, ['id', tabId]);
};

const getTabFromId = (tabId) => {
    const tabIndex = getTabIndexFromId(tabId);
    return tabs[tabIndex];
};

const getItem = (tabId, itemId) => {
    const { items } = getTabFromId(tabId);
    const itemIndex = findIndex(items, ['id', itemId]);
    return items[itemIndex];
};

export default tabs;
export { tabs, getTabIndexFromId, getTabFromId, getItem };
