//@flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { BaseTable, Pagination } from 'components/core/table';
import { useTableInstance } from './useTableInstance';
import {
    type TableProps,
    type BaseTableProps,
    type PaginationProps,
} from './types';

const useStyles = makeStyles({
    paginatedTableWrap: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
});

type Props<T> = {
    ...TableProps<T>,
    renderTable?: (props: BaseTableProps<T>) => React.Node,
    renderPagination?: (props: PaginationProps<T>) => React.Node,
};

export const PaginatedTable = <T>(props: Props<T>): React.Node => {
    const {
        columns,
        data,
        filters,
        idField,
        renderTable,
        renderPagination,
        ...rest
    } = props;
    const classes = useStyles();
    const tableInstance = useTableInstance({ columns, data, idField });
    const childrenProps = { tableInstance, idField, filters, ...rest };
    return (
        <Box className={classes.paginatedTableWrap}>
            {renderTable ? (
                renderTable(childrenProps)
            ) : (
                <BaseTable {...childrenProps} />
            )}
            {renderPagination ? (
                renderPagination({ tableInstance })
            ) : (
                <Pagination tableInstance={tableInstance} />
            )}
        </Box>
    );
};
