//@flow

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { type ChipProps } from '@material-ui/core/Chip';
import MaterialUIChipInput from 'material-ui-chip-input';

import Chip from 'components/core/chip';

type ChipInputPropsTypes = {
    value: Array<string>,
    chipRenderer: (props: ChipProps, key: string) => React.Node,
    variant: 'standard' | 'outlined' | 'filled',
    onAdd: (addedValue: string) => void,
    onDelete: (deletedValue: string, deletedValueIndex: number) => void,
};

const useStyles = makeStyles({
    chipContainer: {
        borderRadius: '2px',
    },
    root: {
        width: '100%',
    },
    inputRoot: {
        flex: 1,
        flexWrap: 'wrap',
        padding: '8px',
        float: 'left',
    },
    input: {
        flex: 1,
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    chipInputField: {
        borderRadius: '2px',
        '& input': {
            padding: '0px 2px',
        },
    },
});

const defaultChipRenderer = (props, key) => {
    const { text, handleClick, handleDelete } = props;
    return (
        <Chip
            key={key}
            label={text}
            onClick={handleClick}
            onDelete={handleDelete}
        />
    );
};

const ChipInput = (props: ChipInputPropsTypes) => {
    const {
        value,
        variant = 'outlined',
        chipRenderer = defaultChipRenderer,
        onAdd,
        onDelete,
    } = props;
    const classes = useStyles();
    return (
        <MaterialUIChipInput
            value={value}
            variant={variant}
            onAdd={onAdd}
            onDelete={onDelete}
            chipRenderer={chipRenderer}
            classes={{
                root: classes.root,
                chip: classes.chipContainer,
            }}
            InputProps={{
                className: classes.chipInputField,
                classes: {
                    root: classes.inputRoot,
                    input: classes.input,
                },
            }}
        />
    );
};

export default ChipInput;
export { ChipInput };
