//@flow
import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { FieldDependencyTypeEnum } from 'enums/FieldDependencyType';
import { numberSubSectionFormObject } from './NumberSubSectionFormObject';
import { fixedSubSectionFormObject } from './FixedSubSectionFormObject';
import { dateTimeSubSectionFormObject } from './DateTimeSubSectionFormObject';

const idGeneratorFormObject: FormSectionType = {
    id: 'idGenerator',
    type: 'SECTION',
    subSections: [
        {
            id: 'tourIdStatus',
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.SELECT,
            title: 'Tour ID Status',
            required: true,
            options: {
                itemsType: FormPropertyTypeEnum.STRING,
                items: [
                    {
                        id: 'ACTIVE',
                        label: 'Active',
                        value: 'ACTIVE',
                    },
                    {
                        id: 'INACTIVE',
                        label: 'Inactive',
                        value: 'INACTIVE',
                    },
                ],
            },
        },
        {
            title: 'Tour ID Sub Section',
            id: 'tourIdPatternArray',
            type: 'ARRAY',
            minItems: 1,
            dependency: {
                dependeeFieldId: 'tourIdStatus',
                dependeeFieldValue: 'ACTIVE',
                dependencyType:
                    FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
            },
            items: {
                id: 'tourIdSubSectionItems',
                type: 'SECTION',
                subSections: [
                    {
                        id: 'patternType',
                        type: 'FIELD',
                        fieldType: FormFieldTypeEnum.SELECT,
                        title: 'ID Type',
                        required: true,
                        options: {
                            itemsType: FormPropertyTypeEnum.STRING,
                            items: [
                                {
                                    id: 'FIXED',
                                    label: 'Fixed',
                                    value: 'FIXED',
                                },
                                {
                                    id: 'NUMBER',
                                    label: 'Number',
                                    value: 'NUMBER',
                                },
                                {
                                    id: 'DATE_TIME',
                                    label: 'Date/Time',
                                    value: 'DATE_TIME',
                                },
                            ],
                        },
                    },
                    {
                        dependency: {
                            dependeeFieldId: 'patternType',
                            dependeeFieldValue: 'NUMBER',
                            dependencyType:
                                FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                        },
                        ...numberSubSectionFormObject,
                    },
                    {
                        dependency: {
                            dependeeFieldId: 'patternType',
                            dependeeFieldValue: 'FIXED',
                            dependencyType:
                                FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                        },
                        ...fixedSubSectionFormObject,
                    },
                    {
                        dependency: {
                            dependeeFieldId: 'patternType',
                            dependeeFieldValue: 'DATE_TIME',
                            dependencyType:
                                FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                        },
                        ...dateTimeSubSectionFormObject,
                    },
                ],
            },
        },
    ],
};

export default idGeneratorFormObject;
