//@flow
import * as React from 'react';
import { Box, Link, AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { countBy } from 'lodash';

import { history } from 'store/configureStore';

import { PaginatedTable, BaseTable, Pagination } from 'components/core/table';
import Loader from 'components/core/loader';
import { H3, Body1 } from 'components/core/typography';
import StringUtils from 'utils/StringUtils';
import ErrorContainer from 'containers/ErrorContainer';
import AddClientWrapperContainer from 'containers/add-client/AddClientWrapperContainer';
import { allClientColumns } from './AllClientsColumns';

import useClients from 'hooks/client/useClients';
import { ApiStatusEnum } from 'enums/API';

const useStyles = makeStyles({
    allClientsHeadingWrap: {
        width: '90%',
    },
    allClientsTableWrap: {
        width: '100%',
        height: '100%',
    },
    stageCountMappingWrap: {
        whiteSpace: 'pre-wrap',
    },
    baseTableWrap: {
        marginLeft: '5%',
        marginRight: '5%',
        height: '70vh',
    },
});

const AllClientsContainer = (): React.Node => {
    const classes = useStyles();
    const clientsQuery = useClients();
    const [tableFilters, setTableFilters] = React.useState([]);

    const clientStageCountMapping = React.useMemo(() => {
        return countBy(clientsQuery.data || [], (client) => client.stage);
    }, [clientsQuery.data]);

    const stages = Object.keys(clientStageCountMapping);

    const onRowClick = (clientId: string) => {
        history.push(`/client/${clientId}`);
    };

    const setTableFilter = (id: string, filterValue: string) => {
        const stageFilterIndex = tableFilters.findIndex(
            (filter) => filter.id === id,
        );
        const updatedStageFilter = { id, value: filterValue };
        if (stageFilterIndex === -1) {
            setTableFilters(tableFilters.concat(updatedStageFilter));
        } else {
            const updatedTableFilters = tableFilters.map((filter) => {
                return filter.id === id ? updatedStageFilter : filter;
            });
            setTableFilters(updatedTableFilters);
        }
    };

    if (clientsQuery.status === ApiStatusEnum.Loading) {
        return <Loader />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            {clientsQuery.status === ApiStatusEnum.Error && (
                <ErrorContainer message={clientsQuery.error.message} />
            )}

            {clientsQuery.status === ApiStatusEnum.Success && (
                <>
                    <Box className={classes.allClientsHeadingWrap} pt={1}>
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="row" pt={1}>
                                <Box flexGrow="1">
                                    <H3>All Clients</H3>
                                </Box>
                                <AddClientWrapperContainer />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            {stages.map((stage, index) => {
                                const shouldShowSeperator =
                                    index !== stages.length - 1;
                                const startLowerCaseStage = StringUtils.toTitleCase(
                                    stage,
                                );
                                return (
                                    <Box
                                        display="inline-flex"
                                        key={stage}
                                        pr={1}
                                        className={
                                            classes.stageCountMappingWrap
                                        }
                                    >
                                        <Body1
                                            color="secondary"
                                            component="span"
                                        >
                                            {clientStageCountMapping[stage]}
                                            {' - '}
                                        </Body1>
                                        <Link
                                            component="button"
                                            color="primary"
                                            onClick={() =>
                                                setTableFilter('stage', stage)
                                            }
                                        >
                                            {startLowerCaseStage}
                                        </Link>
                                        {shouldShowSeperator && (
                                            <Box px={1}>
                                                <Body1
                                                    color="secondary"
                                                    component="span"
                                                >
                                                    {' | '}
                                                </Body1>
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    <Box className={classes.allClientsTableWrap}>
                        <PaginatedTable
                            idField="clientId"
                            onRowClick={onRowClick}
                            columns={allClientColumns}
                            data={clientsQuery.data}
                            filters={tableFilters}
                            renderPagination={(props) => (
                                <AppBar position="relative" color="default">
                                    <Toolbar disableGutters variant="dense">
                                        <Pagination {...props} />
                                    </Toolbar>
                                </AppBar>
                            )}
                            renderTable={(props) => {
                                return (
                                    <Box
                                        className={classes.baseTableWrap}
                                        my={3}
                                    >
                                        <BaseTable {...props} />
                                    </Box>
                                );
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AllClientsContainer;
