//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { ChecklistStatusType } from 'enums/ChecklistStatusType';
import { ChecklistFormatType } from 'enums/ChecklistFormatType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { FieldDependencyTypeEnum } from 'enums/FieldDependencyType';

const checklistItems: FormSectionType = {
    type: 'ARRAY',
    id: 'items',
    title: 'Checklist Items',
    items: {
        type: 'SECTION',
        id: 'item',
        title: 'Item',
        subSections: [
            {
                type: 'FIELD',
                id: 'key',
                title: 'Key',
                description: 'An identifier for this checklist item',
                fieldType: FormFieldTypeEnum.TEXT_FIELD,
                required: true,
            },
            {
                type: 'FIELD',
                id: 'item',
                title: 'Display Name',
                description:
                    'String representation of the checklist item, to be displayed on the app',
                fieldType: FormFieldTypeEnum.TEXT_FIELD,
                required: true,
            },
            {
                type: 'FIELD',
                id: 'format',
                title: 'Format',
                fieldType: FormFieldTypeEnum.SELECT,
                options: {
                    itemsType: FormPropertyTypeEnum.STRING,
                    items: [
                        {
                            id: ChecklistFormatType.BOOLEAN,
                            label: 'Boolean',
                            value: ChecklistFormatType.BOOLEAN,
                        },
                        {
                            id: ChecklistFormatType.SINGLE_CHOICE,
                            label: 'Select (Single Choice)',
                            value: ChecklistFormatType.SINGLE_CHOICE,
                        },
                        {
                            id: ChecklistFormatType.TEXT_FIELD,
                            label: 'Text Field',
                            value: ChecklistFormatType.TEXT_FIELD,
                        },
                        {
                            id: ChecklistFormatType.PIN,
                            label: 'PIN',
                            value: ChecklistFormatType.PIN,
                        },
                        {
                            id: ChecklistFormatType.SIGNATURE,
                            label: 'Signature',
                            value: ChecklistFormatType.SIGNATURE,
                        },
                        {
                            id: ChecklistFormatType.PHOTO,
                            label: 'Photo',
                            value: ChecklistFormatType.PHOTO,
                        },
                        {
                            id: ChecklistFormatType.RATING,
                            label: 'Rating',
                            value: ChecklistFormatType.RATING,
                        },
                        {
                            id: ChecklistFormatType.URL,
                            label: 'URL',
                            value: ChecklistFormatType.URL,
                        },
                    ],
                },
                required: true,
            },
            {
                type: 'ARRAY',
                id: 'possibleValues',
                title: 'Possible Values',
                description:
                    'Possible values for the item, depending on the format field. For a SINGLE_CHOICE, values will be strings to display. For PIN, it should be the actual code. For URL, it should be the URL to display and link to. For BOOLEAN, TEXT_FIELD, SIGNATURE and PHOTO, value is not required.',
                items: {
                    type: 'FIELD',
                    id: 'possibleValue',
                    title: 'Possible Value',
                    fieldType: FormFieldTypeEnum.TEXT_FIELD,
                    required: true,
                },
            },
            {
                type: 'FIELD',
                fieldType: FormFieldTypeEnum.SWITCH,
                id: 'optional',
                title: 'Optional',
                description: 'Boolean to denote if checklist is optional',
                initialValue: false,
            },
            {
                type: 'SECTION',
                id: 'additionalOptions',
                title: 'Additional Options',
                description:
                    'Any additional options to customize the behavior of the checklist item.\nFor TEXT_FIELD, the key "inputType" can have values "DEFAULT", "PHONE", "NUMBER", "DECIMAL", "EMAIL_ADDRESS" or "PASSWORD" that determines the type of keyboard that appears.The key "regex" can be given a regular expression as the value that will be used to validate the TEXT_FIELD contents. The key "errorMessage" sets a custom error message if the text does not conform to the given regex.',
                subSections: [
                    {
                        type: 'FIELD',
                        id: 'additionalProperties',
                        title: 'Additional Properties',
                        fieldType: FormFieldTypeEnum.TEXT_FIELD,
                    },
                ],
            },
        ],
        required: true,
    },
    minItems: 1,
};

const clientChecklistsFormObject: FormSectionType = {
    id: 'clientChecklists',
    title: 'Client Checklists',
    type: 'SECTION',
    subSections: [
        {
            type: 'SECTION',
            id: 'checklists',
            title: 'Default Checklists',
            subSections: [
                {
                    type: 'ARRAY',
                    id: 'defaultChecklists',
                    title: '',
                    items: {
                        id: 'defaultChecklist',
                        title: 'Checklist',
                        type: 'SECTION',
                        subSections: [
                            {
                                type: 'FIELD',
                                fieldType: FormFieldTypeEnum.SELECT,
                                title: 'Select Status',
                                id: 'status',
                                options: {
                                    items: [
                                        {
                                            id: ChecklistStatusType.STARTED,
                                            label: 'Started',
                                            value: ChecklistStatusType.STARTED,
                                        },
                                        {
                                            id: ChecklistStatusType.ARRIVED,
                                            label: 'Arrived',
                                            value: ChecklistStatusType.ARRIVED,
                                        },
                                        {
                                            id: ChecklistStatusType.TRANSACTING,
                                            label: 'Transacting',
                                            value:
                                                ChecklistStatusType.TRANSACTING,
                                        },
                                        {
                                            id: ChecklistStatusType.COMPLETED,
                                            label: 'Completed',
                                            value:
                                                ChecklistStatusType.COMPLETED,
                                        },
                                        {
                                            id: ChecklistStatusType.CANCELLED,
                                            label: 'Cancelled',
                                            value:
                                                ChecklistStatusType.CANCELLED,
                                        },
                                    ],
                                    itemsType: FormPropertyTypeEnum.STRING,
                                },
                                required: true,
                            },
                            {
                                type: 'ARRAY',
                                id: 'ids',
                                title: 'Visit Ids',
                                items: {
                                    id: 'id',
                                    title: 'Visit Id',
                                    type: 'FIELD',
                                    fieldType: FormFieldTypeEnum.TEXT_FIELD,
                                },
                            },
                            {
                                ...checklistItems,
                            },
                        ],
                    },
                },
            ],
        },
        {
            type: 'SECTION',
            id: 'transactionSettings',
            title: 'Partial Pickup/Drop Checklists',
            subSections: [
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    id: 'canTransactPartialAtHomebase',
                    title: 'Can transact partially at homebase?',
                },
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    id: 'canPickupPartial',
                    title: 'Can do a partial pickup ?',
                },
                {
                    type: 'SECTION',
                    id: 'defaultPickupChecklist',
                    title: 'Partial Pickup Checklists',
                    subSections: [{ ...checklistItems }],
                    dependency: {
                        dependeeFieldId: 'canPickupPartial',
                        dependeeFieldValue: true,
                        dependencyType:
                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                    },
                },
                {
                    type: 'FIELD',
                    fieldType: FormFieldTypeEnum.SWITCH,
                    id: 'canDropPartial',
                    title: 'Can do a partial drop ?',
                },
                {
                    type: 'SECTION',
                    id: 'defaultDropChecklist',
                    title: 'Partial Drop Checklists',
                    subSections: [{ ...checklistItems }],
                    dependency: {
                        dependeeFieldId: 'canDropPartial',
                        dependeeFieldValue: true,
                        dependencyType:
                            FieldDependencyTypeEnum.SPECIFIC_VALUE_DEPENDENCY,
                    },
                },
            ],
        },
        {
            type: 'FIELD',
            id: 'reason',
            title: 'Reason',
            uiOptions: {
                placeholder:
                    'Please provide the exact reason for this change. This will be used for dashboard audit purposes',
            },
            description:
                'Please provide the exact reason for this change. This will be used for dashboard audit purposes',
            fieldType: FormFieldTypeEnum.TEXT_AREA,
            required: true,
        },
    ],
};

export default clientChecklistsFormObject;
