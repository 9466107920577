// @flow
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ClientAppItemsContainer from 'containers/client/ClientAppItemsContainer';

type Props = $FixMe;

const ClientAppItemsRoutes = (props: Props): React.Node => {
    const {
        tab,
        match: { path },
        tab: {
            items: [{ id: defaultItemId }],
        },
    } = props;

    const renderFn = (props) => (
        <ClientAppItemsContainer tab={tab} {...props} />
    );
    return (
        <Switch>
            <Route path={`${path}/item/:itemId`} render={renderFn} />
            <Redirect to={`${path}/item/${defaultItemId}`} render={renderFn} />
        </Switch>
    );
};

export default ClientAppItemsRoutes;
