// @flow
import { useMutation, type MutationResult } from 'react-query';

import TeamSettingsUtils from 'utils/TeamSettingsUtils';

type Variables = {
    clientId: string,
    teamId: string,
    vaeConfig: $FixMe,
    reason: string,
    diffAttributeList?: Array<string>,
};

export default function useUpdateTeamVaeConfig(): MutationResult<
    Variables,
    void,
> {
    return useMutation<Variables, void>(
        ({ clientId, teamId, reason, vaeConfig, diffAttributeList }) => {
            const pathLists = [
                {
                    path: 'vaeConfiguration',
                    value: vaeConfig,
                },
            ];
            return TeamSettingsUtils.fetchAndUpdateSettings(
                clientId,
                teamId,
                pathLists,
                reason,
                diffAttributeList,
            );
        },
    );
}
