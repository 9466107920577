//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';
import { ProductsEnum } from 'enums/Products';

const configureProductFormObject = (): FormSectionType => ({
    id: 'configureProductFormObject',
    title: 'Configure Product',
    type: 'SECTION',
    subSections: [
        {
            id: 'productToConfigure',
            title: 'Select Product',
            fieldType: FormFieldTypeEnum.SELECT,
            type: 'FIELD',
            required: true,
            options: {
                itemsType: FormPropertyTypeEnum.STRING,
                items: [
                    {
                        id: ProductsEnum.OrderIq,
                        label: 'Order Iq',
                        value: ProductsEnum.OrderIq,
                    },
                ],
            },
        },
    ],
});

export default configureProductFormObject;
