//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { FormPropertyTypeEnum } from 'enums/FormPropertyType';

const dashSettingsFormObject: FormSectionType = {
    id: 'dashSettings',
    title: 'Dash Settings',
    type: 'SECTION',
    subSections: [
        {
            type: 'ROW',
            id: 'dashSettingsRow',
            fields: [
                {
                    type: 'COLUMN',
                    id: 'firstColumn',
                    fields: [
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.TEXT_FIELD,
                            id: 'activeTaskProfileId',
                            title: 'Active TaskProfile Id',
                            description:
                                'Id of the task profile active for the client',
                            required: true,
                            helpText:
                                'Active Task Profile Id for various usecases - `homebasedelivery` for SPMD Usecase, `pickupdelivery` for MPMD usecase and `services` for services usecase',
                        },
                        {
                            type: 'SECTION',
                            id: 'geocodingPreferences',
                            title: 'Geocoding Preferences',
                            subSections: [
                                {
                                    id: 'precision',
                                    title: 'Precision',
                                    description:
                                        'Geocoding preference for a client in terms of Geocoding Confidence',
                                    fieldType: FormFieldTypeEnum.SELECT,
                                    type: 'FIELD',
                                    required: true,
                                    options: {
                                        itemsType: FormPropertyTypeEnum.STRING,
                                        items: [
                                            {
                                                id: 'HIGH',
                                                label: 'High',
                                                value: 'HIGH',
                                            },
                                            {
                                                id: 'MEDIUM',
                                                label: 'Medium',
                                                value: 'MEDIUM',
                                            },
                                            {
                                                id: 'LOW',
                                                label: 'Low',
                                                value: 'LOW',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                        {
                            type: 'SECTION',
                            id: 'mapControls',
                            title: 'Map Controls',
                            subSections: [
                                {
                                    type: 'FIELD',
                                    fieldType: FormFieldTypeEnum.SWITCH,
                                    id: 'showMarkers',
                                    title: 'Show Markers',
                                    initialValue: false,
                                    description:
                                        'Boolean to denote whether to show markers',
                                },
                                {
                                    type: 'FIELD',
                                    fieldType: FormFieldTypeEnum.SELECT,
                                    id: 'markerType',
                                    required: true,
                                    title: 'Marker Type',
                                    description: 'Marker type to be shown',
                                    options: {
                                        itemsType: FormPropertyTypeEnum.STRING,
                                        items: [
                                            {
                                                id: 'NUMBERED',
                                                label: 'Numbered',
                                                value: 'NUMBERED',
                                            },
                                            {
                                                id: 'DOT',
                                                label: 'Dot',
                                                value: 'DOT',
                                            },
                                        ],
                                    },
                                },
                                {
                                    type: 'FIELD',
                                    fieldType: FormFieldTypeEnum.SWITCH,
                                    id: 'drivingRoute',
                                    title: 'Driving Route',
                                    description:
                                        'Boolean to denote whether to show driving route',
                                    initialValue: false,
                                },
                                {
                                    type: 'FIELD',
                                    fieldType: FormFieldTypeEnum.SWITCH,
                                    id: 'aerialRoute',
                                    title: 'Aerial Route',
                                    initialValue: false,
                                    description:
                                        'Boolean to denote whether to show aerial route',
                                },
                                {
                                    type: 'FIELD',
                                    fieldType: FormFieldTypeEnum.SWITCH,
                                    id: 'clusters',
                                    title: 'Clusters',
                                    description:
                                        'Boolean to denote whether to show clusters',
                                    initialValue: false,
                                },
                                {
                                    type: 'FIELD',
                                    fieldType: FormFieldTypeEnum.SWITCH,
                                    id: 'polygon',
                                    title: 'Polygon',
                                    description:
                                        'Boolean to denote whether to show polygon',
                                    initialValue: false,
                                },
                            ],
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'enableAsyncTaskExport',
                            title: 'Enable Async Task Export',
                            description:
                                'Boolean to enable usage of backend export. If false, the export will done on frontend',
                            initialValue: false,
                        },
                    ],
                },
                {
                    type: 'COLUMN',
                    id: 'secondColumn',
                    fields: [
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'enableTaskIdGeneration',
                            title: 'Enable Task Id Generation',
                            description:
                                'Boolean to denote if Task Id needs to be generated',
                            initialValue: false,
                        },
                        {
                            type: 'ARRAY',
                            id: 'taskIdGenerationFields',
                            title: 'Task Id Generation Fields',
                            items: {
                                id: 'taskIdGenerationField',
                                title: 'Task Id Generation Field',
                                type: 'FIELD',
                                fieldType: FormFieldTypeEnum.TEXT_FIELD,
                            },
                        },
                        {
                            type: 'ARRAY',
                            id: 'tourFilters',
                            title: 'Tour Filters',
                            description:
                                'List of tour properties on which filtering is allowed',
                            items: {
                                type: 'SECTION',
                                id: 'tourFilter',
                                title: 'Tour Filter',
                                subSections: [
                                    {
                                        type: 'FIELD',
                                        id: 'id',
                                        title: 'Id',
                                        fieldType: FormFieldTypeEnum.SELECT,
                                        options: {
                                            itemsType:
                                                FormPropertyTypeEnum.STRING,
                                            items: [
                                                {
                                                    id: 'SKILLS',
                                                    label: 'Skills',
                                                    value: 'SKILLS',
                                                },
                                                {
                                                    id: 'VEHICLE_TYPE',
                                                    label: 'Vehicle Type',
                                                    value: 'VEHICLE_TYPE',
                                                },
                                                {
                                                    id: 'TAGS',
                                                    label: 'Tags',
                                                    value: 'TAGS',
                                                },
                                                {
                                                    id: 'TOUR_WEEK',
                                                    label: 'Tour Week',
                                                    value: 'TOUR_WEEK',
                                                },
                                                {
                                                    id: 'TOUR_DAY',
                                                    label: 'Tour Day',
                                                    value: 'TOUR_DAY',
                                                },
                                                {
                                                    id: 'RIDER_SKILLS',
                                                    label: 'Rider Skills',
                                                    value: 'RIDER_SKILLS',
                                                },
                                                {
                                                    id: 'ZONES',
                                                    label: 'Zones',
                                                    value: 'ZONES',
                                                },
                                            ],
                                        },
                                        required: true,
                                    },
                                    {
                                        type: 'FIELD',
                                        id: 'name',
                                        title: 'Name',
                                        fieldType: FormFieldTypeEnum.TEXT_FIELD,
                                        required: true,
                                    },
                                ],
                            },
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'enableCreateTask',
                            title: 'Enable Create Task',
                            description: 'Boolean to denote task creation',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'enableTaskReschedule',
                            title: 'Enable Task Reschedule',
                            description: 'Boolean to denote task reschedule',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'showOfflineUsersForAssignment',
                            title: 'Show Offline Users For Assignment',
                            description:
                                'Boolean to denote whether offline users should be shown for reassignment of tasks',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'allowGeocodingEditForNonDraft',
                            title: 'Allow Geocoding Edit For Non Draft',
                            description:
                                'Boolean to denote whether to allow geocoding edit for non-draft batches',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'allowGeocodingExport',
                            title: 'Allow Geocoding Export',
                            description:
                                'Boolean to denote whether to allow geocoding export',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'allowCustomBatchUpload',
                            title: 'Allow Custom Batch Upload',
                            description:
                                'Boolean to denote whether to allow custom batch sheet upload',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'populateInputRecordsFromTaskGraph',
                            title: 'Populate Input Records From Task Graph',
                            description:
                                'Boolean to denote whether to populate input records from task graph or uploaded sheet. if true, will populate the records from task graph otherwise from uploaded sheet',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'customExport',
                            title: 'Custom Export',
                            description:
                                'Boolean to denote whether to use api to get the configured custom plan export or to use existing plan export on front end. If true, front end will use exportPlan api to get the configured plan export',
                            initialValue: false,
                        },
                    ],
                },
                {
                    type: 'COLUMN',
                    id: 'thirdColumn',
                    fields: [
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'editTourMetadata',
                            title: 'Edit Tour Metadata',
                            description:
                                'Boolean to denote whether editing tour metadata is allowed',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'allowEditingPayment',
                            title: 'Allow Editing Payment',
                            description:
                                'Boolean to denote whether editing payment collected is allowed',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'enableCustomReports',
                            title: 'Enable Custom Reports',
                            description:
                                'Boolean to denote whether to show custom reports section',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'allowHomebaseOptions',
                            title: 'Allow Homebase Options',
                            description:
                                'Boolean to denote whether to allow homebase options',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'includeUnallocationReasons',
                            title: 'Include Unallocation Reasons',
                            description:
                                'Boolean to control whether unallocation reasons are included in plan',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'canUpdateChecklists',
                            title: 'Can Update Checklists',
                            description:
                                'Boolean to denote whether to update task checklist',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'populateZonesInSuggestedUsers',
                            title: 'Populate Zones In Suggested Users',
                            description:
                                'Boolean to control whether zones should be populated in users',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'allowEditingTaskDetails',
                            title: 'Allow Editing Task Details',
                            description:
                                'Boolean to control whether static task details should be editable',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'allowBulkEditingTaskDetails',
                            title: 'Allow Bulk Editing Task Details',
                            description:
                                'Boolean to control whether bulk editing of task fields on task details screen is allowed',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'addTagsToTour',
                            title: 'Add Tags To Tour',
                            description:
                                'Boolean to denote whether adding tags to tours is allowed',
                            initialValue: false,
                        },
                        {
                            type: 'FIELD',
                            fieldType: FormFieldTypeEnum.SWITCH,
                            id: 'showCratingInfo',
                            title: 'Show Crating Info',
                            description:
                                'Boolean to control whether crating details should be displayed',
                            initialValue: false,
                        },
                    ],
                },
            ],
        },
    ],
};

export default dashSettingsFormObject;
