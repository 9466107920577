//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { useUpdateTeamVaeConfig } from 'hooks/team';
import VaeConfigUtils from 'utils/VaeConfigUtils';
import { Body1 } from 'components/core/typography';
import { ApiButton } from 'components/core/button';
import TextField from 'components/core/input';
import VaeConfigForm from 'containers/configure-client/vae-config/VaeConfigForm';

type Props = {
    configuration: $FixMe,
    teamId: string,
    clientId: string,
};
export const VaeConfigBase = (props: Props): React.Node => {
    const { configuration, teamId, clientId } = props;
    const formRef = React.useRef();
    const [reason, setReason] = React.useState('');
    const [formData, setFormData] = React.useState({
        configuration,
        useCase: VaeConfigUtils.getUseCase(configuration),
    });
    const [defaultConfiguration, setDefaultConfiguration] = React.useState(
        configuration,
    );
    const [defaultUsecase, setDefaultUsecase] = React.useState(
        VaeConfigUtils.getUseCase(configuration),
    );
    const updateTeamVaeConfigMutation = useUpdateTeamVaeConfig();

    React.useEffect(() => {
        setFormData({
            configuration,
            useCase: VaeConfigUtils.getUseCase(configuration),
        });
    }, [configuration]);

    const triggerClick = () => {
        formRef.current?.submit();
    };

    const onSubmit = () => {
        const { configuration } = formData;
        updateTeamVaeConfigMutation.mutate({
            clientId,
            teamId,
            vaeConfig: configuration,
            reason,
            diffAttributeList: ['vaeConfiguration'],
        });
        setDefaultConfiguration(formData.configuration);
        setDefaultUsecase(formData.useCase);
    };

    return (
        <>
            <VaeConfigForm
                ref={formRef}
                value={formData}
                onChange={setFormData}
                onSubmit={onSubmit}
                defaultConfiguration={defaultConfiguration}
                defaultUsecase={defaultUsecase}
            />
            <Box py={1} width="100%">
                <Body1>Reason</Body1>
                <TextField
                    multiline
                    fullWidth
                    value={reason}
                    rows="4"
                    placeholder="Please provide the exact reason for this change. This will be used for dashboard audit purposes"
                    onChange={setReason}
                />
            </Box>
            <ApiButton
                apiStatus={updateTeamVaeConfigMutation.status}
                successMessage="VAE Config updated successfully"
                errorMessage={updateTeamVaeConfigMutation.error?.message}
                onClick={triggerClick}
                disabled={isEmpty(reason)}
            />
        </>
    );
};
