// @flow
import * as React from 'react';

import { ApiButton } from 'components/core/button';
import fetchMessagesFormObject from './FetchMessagesFormObject';
import useFetchMessages from './useFetchMessages';
import Form from 'components/core/form';

export type Value = {
    queueName: string,
    namespace: string,
};

type Props = {
    value: ?Value,
    onChange: (value: Value) => void,
    onSubmit: () => void,
};

const FetchMessagesForm = ({
    value,
    onChange,
    onSubmit,
}: Props): React.Node => {
    const formRef = React.useRef();
    const fetchMessagesMutation = useFetchMessages();

    const triggerClick = () => {
        formRef.current?.submit();
    };
    return (
        <>
            <Form
                section={fetchMessagesFormObject}
                ref={formRef}
                onSubmit={onSubmit}
                onChange={onChange}
                value={value}
            />
            <ApiButton
                apiStatus={fetchMessagesMutation.status}
                successMessage={`Queue messages fetched`}
                errorMessage={fetchMessagesMutation.error?.message}
                onClick={triggerClick}
            />
        </>
    );
};

export default FetchMessagesForm;
