// @flow
import { useQuery, type QueryResult } from 'react-query';

import { apiInstance } from 'middleware/axios';
import { type ClientType } from 'models/apps/client/Client';

export default function useClient(clientId: string): QueryResult<ClientType> {
    return useQuery({
        queryKey: ['clients', clientId],
        queryFn: () => {
            return apiInstance.get(`/client/${clientId}`).then((response) => {
                return response.data;
            });
        },
    });
}
