// @flow
import { useQuery, type QueryResult } from 'react-query';
import { omsInstance, apiInstance, platformInstance } from 'middleware/axios';
import {
    type ServicesForPostBodyEnumType,
    ServicesForPostBodyEnum,
} from 'enums/PostBodyLogger';

export const serviceAxiosMap: { [key: ServicesForPostBodyEnumType]: any } = {
    [ServicesForPostBodyEnum.TAXY]: apiInstance,
    [ServicesForPostBodyEnum.OMS]: omsInstance,
    [ServicesForPostBodyEnum.PLATFORM]: platformInstance,
};

export default function useFetchMethods({
    clientId,
    service,
}: {
    clientId: string,
    service: ServicesForPostBodyEnumType,
}): QueryResult<any> {
    return useQuery({
        queryKey: [
            'clients',
            clientId,
            'service',
            service,
            'post-body-logger-metadata',
        ],
        queryFn: () => {
            const instance = serviceAxiosMap[service];
            if (instance) {
                return instance
                    .get(`/client/${clientId}/post-body-logger-metadata`)
                    .then((response) => {
                        return response.data;
                    });
            } else
                throw new Error(
                    `Invalid service type in PostBody Logger: ${service}`,
                );
        },
    });
}
