// @flow
import axios from 'axios';

import config from 'config';
import ErrorUtils from 'utils/ErrorUtils';

const {
    dashboardEndPoint,
    internalApiEndPoint,
    lqsEndpoint,
    platformEndPoint,
    omsEndPoint,
} = config;
export const instance = axios.create({
    baseURL: dashboardEndPoint,
});

export const internalApiInstance = axios.create({
    baseURL: internalApiEndPoint,
});

const apiEndPoint = `${internalApiEndPoint}/v1`;
export const apiInstance = axios.create({
    baseURL: apiEndPoint,
});

export const lqsInstance = axios.create({
    baseURL: lqsEndpoint,
});

export const platformInstance = axios.create({
    baseURL: platformEndPoint,
});

export const omsInstance = axios.create({
    baseURL: omsEndPoint,
});

const allInstances = [
    instance,
    internalApiInstance,
    apiInstance,
    lqsInstance,
    platformInstance,
    omsInstance,
];

export const setAuthHeader = (token) => {
    allInstances.map(
        (instance) => (instance.defaults.headers.common.Authorization = token),
    );
};

allInstances.map((instance) =>
    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            const errorMessage = ErrorUtils.extractErrorMessage(error);
            throw new Error(errorMessage);
        },
    ),
);
