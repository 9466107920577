//@flow

export const WorkflowTypeEnum = Object.freeze({
    ON_DEMAND: 'ON_DEMAND',
    SCHEDULED: 'SCHEDULED',
    SCHEDULED_EXECUTION: 'SCHEDULED_EXECUTION',
    SCHEDULED_EXECUTION_ON_DEMAND: 'SCHEDULED_EXECUTION_ON_DEMAND',
});

export type WorkflowTypeEnumType = $Values<typeof WorkflowTypeEnum>;
