// @flow
import { useMutation, type MutationResult } from 'react-query';

import { apiInstance } from 'middleware/axios';
import FileUtils from 'utils/FileUtils';
import PromiseUtils from 'utils/PromiseUtils';

type Variables = {
    clientId: string,
    teamId?: string,
    payload: { [key: string]: string },
};

export default function useFileUpload(): MutationResult<Variables, null> {
    return useMutation<Variables, any>(({ clientId, teamId, payload }) => {
        const promises = {};
        Object.keys(payload).forEach((item) => {
            if (payload[item]) {
                const formDataToUpload = new window.FormData();
                formDataToUpload.append(
                    'file',
                    FileUtils.dataURLtoBlob(payload[item]),
                );

                const apiUrl = teamId
                    ? `client/${clientId}/team/${teamId}/${item}`
                    : `client/${clientId}/${item}`;
                promises[item] = apiInstance.post(apiUrl, formDataToUpload);
            }
        });
        return PromiseUtils.processAllSettled(promises);
    });
}
