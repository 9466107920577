//@flow
import { diff } from 'deep-object-diff';
import { isUndefined } from 'lodash';
class JSONUtils {
    static isValidJSON(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    static findJSONDiff(origObj: Object, newObj: Object) {
        const modifiedKeys = Object.keys(diff(origObj, newObj));
        return modifiedKeys.reduce((updatedObj, modifiedKey) => {
            if (!isUndefined(newObj[modifiedKey])) {
                return { ...updatedObj, [modifiedKey]: newObj[modifiedKey] };
            }
            return updatedObj;
        }, {});
    }
}

export default JSONUtils;
