// @flow

export const TimeSlotDistributionEnum = Object.freeze({
    AUTOMATIC: 'AUTOMATIC',
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE',
});

export type TimeSlotDistributionEnumType = $Values<
    typeof TimeSlotDistributionEnum,
>;
