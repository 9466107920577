//@flow

import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';
import { BrandingImageSize } from 'constants/common/branding';

type ParamsType = {
    onFaviconLoad: (height: number, width: number) => void,
    onVehicleIconLoad: (height: number, width: number) => void,
};

const teamBrandingFormObject = ({
    onFaviconLoad,
    onVehicleIconLoad,
}: ParamsType): FormSectionType => ({
    id: 'teamBrandingFormObject',
    type: 'SECTION',
    subSections: [
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.FILE,
            id: 'logo',
            title: 'Team Logo',
            description: `Image format: png, Preferred size is ${BrandingImageSize.logo.width} × ${BrandingImageSize.logo.height} px`,
            uiOptions: {
                previewHeight: `${BrandingImageSize.logo.height}px`,
                previewWidth: `${BrandingImageSize.logo.width}px`,
                accept: 'image/png',
            },
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.FILE,
            id: 'favicon',
            title: 'Favicon',
            description: `Image format: png, Preferred size is ${BrandingImageSize.favicon.width} × ${BrandingImageSize.favicon.height} px`,
            uiOptions: {
                previewHeight: `${BrandingImageSize.favicon.height}px`,
                previewWidth: `${BrandingImageSize.favicon.width}px`,
                accept: 'image/png',
                onImageLoad: (e) =>
                    onFaviconLoad(
                        e.target.naturalWidth,
                        e.target.naturalHeight,
                    ),
            },
        },
        {
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.FILE,
            id: 'vehicleIcon',
            title: 'Team Vehicle Icon',
            description: `Image format: png, Preferred size is ${BrandingImageSize.vehicleIcon.width} × ${BrandingImageSize.vehicleIcon.height} px`,
            uiOptions: {
                previewHeight: `${BrandingImageSize.vehicleIcon.height}px`,
                previewWidth: `${BrandingImageSize.vehicleIcon.width}px`,
                accept: 'image/png',
                onImageLoad: (e) =>
                    onVehicleIconLoad(
                        e.target.naturalWidth,
                        e.target.naturalHeight,
                    ),
            },
        },
    ],
});

export default teamBrandingFormObject;
