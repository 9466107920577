//@flow
import { type FormSectionType } from 'models/FormSection';
import { FormFieldTypeEnum } from 'enums/FormFieldType';

export const fixedSubSectionFormObject: FormSectionType = {
    id: 'FIXED',
    type: 'SECTION',
    subSections: [
        {
            id: 'pattern',
            type: 'FIELD',
            fieldType: FormFieldTypeEnum.TEXT_FIELD,
            title: 'Pattern',
            required: true,
        },
    ],
};

export default fixedSubSectionFormObject;
